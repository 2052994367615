import { createIntl, useSelector, useStore, useTranslation } from 'hooks'
import { RouterContext } from 'providers'
import { useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  hideGlobalLoader,
  selectLoader,
  selectOrganization,
  setPage,
  showGlobalLoader,
} from 'state'
import { pageData } from 'state/Page/data'
import { IPageId } from 'state/type'

import { UseSetPageOptions } from './type'

const UseSetPage = ({
  pageId,
  customParameters,
  featureFlag = '',
  isLoading = false,
}: UseSetPageOptions): void => {
  const dispatch = useDispatch()
  const intlPath = pageData[pageId].intlPath
  const intl = createIntl(intlPath)

  const translation = useTranslation(intl)
  const store = useStore()
  const loader = useSelector(selectLoader)
  const organization = useSelector(selectOrganization)
  const { urlSearchParameters } = useContext(RouterContext)

  const [isPageSettled, setIsPageSettled] = useState(false)
  const [currentPageId, setCurrentPageId] = useState<IPageId | undefined>(
    undefined
  )

  useEffect(() => {
    if (isLoading && !loader.globalLoaderDisplay) {
      dispatch(
        showGlobalLoader({
          text: '',
        })
      )
    }

    if (!isLoading && store.isLoaded && loader.globalLoaderDisplay) {
      dispatch(hideGlobalLoader())
    }
  }, [store.isLoaded, dispatch, loader, isLoading])

  // Reload setPage for pages sharing the same component
  useEffect(() => {
    if (currentPageId !== pageId) {
      setIsPageSettled(false)
    }
  }, [pageId, currentPageId])

  useEffect(() => {
    if (
      !isLoading &&
      organization.idOld !== undefined &&
      pageId &&
      translation &&
      !isPageSettled
    ) {
      // supportLabel
      const supportLabel =
        pageData[pageId].supportLabel ?? customParameters?.supportLabel
      // Tabs
      const tabs = customParameters?.tabs
      // backLinkHref
      const backLinkHref =
        customParameters?.backLinkHref ?? urlSearchParameters?.redirect

      // subtitle
      const subtitle = customParameters?.subtitle
      // title
      const title = customParameters?.title ?? translation.translate('title')

      setCurrentPageId(pageId)

      dispatch(
        setPage({
          menuItemActiveId: '', // TO REMOVE WHEN MENUOLD HAS BEEN DELETED
          menuSubItemActiveId: '', // TO REMOVE WHEN MENUOLD HAS BEEN DELETED
          supportLabel,
          backLinkHref,
          title: !isLoading && title, // use loading when title contains variable from store
          tabs,
          subtitle,
          pageId,
        })
      )
      setIsPageSettled(true)
    }
  }, [
    dispatch,
    isLoading,
    customParameters,
    organization,
    pageId,
    translation,
    isPageSettled,
    featureFlag,
    urlSearchParameters,
  ])

  useEffect(
    () => () => {
      setIsPageSettled(false)
    },
    []
  )
}

export default UseSetPage
