/* eslint-enable */
import { IStoreOrganization, IStorePerson } from 'state/type'
import { EProcessEnv } from 'types'
import { formatLocale, getLocaleShortcode, getNavigatorLanguage } from 'utils'
import { v1 as uuidv1 } from 'uuid'

import { ECaseType } from '../Intl/type'
import { ESelectorType } from './type'

export enum EBrand {
  ASSOCONNECT = 'assoconnect',
  ATOUCOM = 'atoucom',
  BUYPACKER = 'buypacker',
  PLATEFORME_CE = 'plateforme_ce',
  PLATEFORME_COS = 'plateforme_cos',
  SIMPLY_CE = 'simply_ce',
  SPRINGLY = 'springly',
}

type BrandNames = {
  [key in EBrand]: string
}

type SiteUrls = {
  [key in EBrand]: string
}

const DEV_URLS: Partial<SiteUrls> = {
  [EBrand.ASSOCONNECT]: 'assoconnect-dev.com',
  [EBrand.SPRINGLY]: 'springly-dev.org',
}

const SITES_URLS: SiteUrls = {
  [EBrand.ASSOCONNECT]: 'assoconnect.com',
  [EBrand.ATOUCOM]: 'sites-atoucom.fr',
  [EBrand.BUYPACKER]: 'buypacker.com',
  [EBrand.PLATEFORME_CE]: 'plateforme-ce.com',
  [EBrand.PLATEFORME_COS]: 'plateforme-cos.com',
  [EBrand.SIMPLY_CE]: 'simply-ce.com',
  [EBrand.SPRINGLY]: 'springly.org',
}

const BRANDS_NAMES: BrandNames = {
  [EBrand.ASSOCONNECT]: 'AssoConnect',
  [EBrand.ATOUCOM]: 'Atoucom',
  [EBrand.BUYPACKER]: 'Buypacker',
  [EBrand.PLATEFORME_CE]: 'Plateforme CE',
  [EBrand.PLATEFORME_COS]: 'Plateforme COS',
  [EBrand.SIMPLY_CE]: 'Simply CE',
  [EBrand.SPRINGLY]: 'Springly',
}

let sleepDebounce = -1
export const sleep = (timeout = 200): Promise<unknown> => {
  window.clearTimeout(sleepDebounce)
  return new Promise(
    (resolve) => (sleepDebounce = window.setTimeout(resolve, timeout))
  )
}

export const retrying = (
  callback: () => boolean,
  max = 5,
  timeout = 200
): void => {
  const firstTry = callback()
  if (!firstTry) {
    let counter = 0
    const interval = setInterval(() => {
      counter++
      const intervalTry = callback()
      if (intervalTry || counter >= max) {
        clearInterval(interval)
      }
    }, timeout)
  }
}

export const uniqId = (prefix?: string): string =>
  `${prefix ? prefix + '-' : ''}${uuidv1()}`

export const getBrandByDomainName = (): EBrand => {
  const sitesUrls = Object.entries(
    process.env.NODE_ENV === EProcessEnv.DEVELOPMENT ? DEV_URLS : SITES_URLS
  )
  for (const [brand, site] of sitesUrls) {
    // Get the dev version of the site domain
    const domain = site.substring(0, site.lastIndexOf('.'))
    const extension = site.substring(
      site.lastIndexOf('.'),
      site.length - site.lastIndexOf('.')
    )
    const siteDev = `${domain}-dev${extension}`

    // Check if the current domain matches prod or dev domains for this brand
    if (
      window.location.hostname.includes(site) ||
      window.location.hostname.includes(siteDev)
    ) {
      return brand as EBrand
    }
  }

  return EBrand.SPRINGLY
}

export const getBrandDisplayName = (brand: EBrand): string =>
  BRANDS_NAMES[brand]

export const getBrandFromUrl = (brandUrl: EBrand | null): EBrand => {
  if (brandUrl && BRANDS_NAMES[brandUrl]) {
    return brandUrl
  }
  return getBrandByDomainName()
}

export const getHelpCenterInfos = (
  organizationOrLocal: IStoreOrganization | string
): { locale: string; index: string; url: string } => {
  const infos = { locale: '', index: '', url: '' }
  // Unknown organization
  let locale
  let brand
  if (typeof organizationOrLocal === 'string') {
    locale = organizationOrLocal
  }
  // Known organization
  else {
    locale = organizationOrLocal.nonprofit.locale
    brand = organizationOrLocal.brand
  }
  // Locale
  if (
    locale === 'en_US' ||
    locale === 'pt_BR' ||
    locale === 'zh_CN' ||
    locale === 'zh_TW'
  ) {
    infos.locale = formatLocale(locale, ECaseType.KEBAB).toLocaleLowerCase()
  } else {
    infos.locale = getLocaleShortcode(locale)
  }
  // Index and URL
  if ((brand && brand !== 'springly') || (!brand && locale === 'fr_FR')) {
    infos.index = 'zendesk_ifeelgroup_articles'
    infos.url = 'https://help.assoconnect.com/hc/fr/'
  } else {
    infos.index = 'zendesk_springly_articles'
    infos.url = 'https://help.springly.org/hc/en-us/'
  }
  return infos
}

export const getHelpCenterTicketForm = (
  organization?: IStoreOrganization,
  person?: IStorePerson
): string => {
  let url = ''
  // Organization locale
  if (organization) {
    url += getHelpCenterInfos(organization).url
  }
  // Browser locale
  else {
    url += getHelpCenterInfos(getNavigatorLanguage()).url
  }
  // Form url
  url += 'requests/new'
  const vars = []
  // User info
  if (person) {
    vars.push(
      `tf_anonymous_requester_email=${encodeURIComponent(person.email)}`
    )
  }
  // Source
  vars.push(`tf_360020730340=${encodeURIComponent(window.location.href)}`)
  // Subscription
  if (organization) {
    vars.push(
      `tf_1900001682274=${organization.subscription?.type.toLowerCase()}`
    )
  }

  if (vars.length) {
    url += '?' + vars.join('&')
  }
  return url
}

export const getHelpCenterUrl = (
  articleIds: { [key: string]: string },
  organization: IStoreOrganization
): string =>
  getHelpCenterInfos(organization).url + articleIds[organization.brand]

export const iriToId = (iri: string): string => {
  const iriSplits = iri.split('/')
  return iriSplits[iriSplits.length - 1]
}

export const getCountriesByDependencies = (country: string): string => {
  const dependencies: { [key: string]: Array<string> } = {
    DK: ['FO', 'GL'],
    FR: [
      'BL',
      'GF',
      'GP',
      'MF',
      'MQ',
      'NC',
      'PF',
      'PM',
      'RE',
      'TF',
      'YT',
      'WF',
    ],
    GB: ['GG', 'IM', 'JE'],
  }

  const mainCountry = Object.keys(dependencies).find((e) =>
    dependencies[e].includes(country)
  )
  return mainCountry ? mainCountry : country
}

export const hexToRGBa = (hex: string, alpha?: number): string => {
  const aRgbHex = hex.replace('#', '').match(/.{1,2}/g)
  if (!aRgbHex) {
    throw new Error(`color is invalid`)
  }
  const aRgb = [
    parseInt(aRgbHex[0], 16),
    parseInt(aRgbHex[1], 16),
    parseInt(aRgbHex[2], 16),
  ]
  return `rgba(${aRgb[0]}, ${aRgb[1]}, ${aRgb[2]}, ${alpha})`
}

export const getBrandName = (brand: EBrand): string =>
  BRANDS_NAMES[brand] || brand

export const getDomainName = (): string => {
  const hostname = window.location.hostname
  const parts = hostname.split('.')

  // Special case for staging https://assoconnect.atlassian.net/browse/AN-8513
  if (process.env.REACT_APP_SENTRY_ENV === 'staging') {
    return `${parts[0]}.${parts[1]}.${parts[2]}`
  } else {
    return `${parts[1]}.${parts[2]}`
  }
}

export const getDomElement = (
  selector: string,
  type: ESelectorType
): Element | null => {
  if (!selector) {
    return null
  }
  const cleanedSelector = selector.replace('#', '').replace('.', '')

  switch (type) {
    case ESelectorType.CLASSNAME:
      return document.querySelector('.' + cleanedSelector)
    case ESelectorType.ID:
      return document.getElementById(cleanedSelector)
    default:
      return null
  }
}

// WILL BE MOVED IN THE STORYBOOK ONCE WE CAN FETCH THE ORGANIZATION COLOR FROM AN ENDPOINT
export const generateRandomHexColor = () =>
  '#' +
  Math.floor(Math.random() * 16777215)
    .toString(16)
    .padStart(6, '0')

export const copyTextToClipboard = (text: string) =>
  navigator.clipboard.writeText(text)
