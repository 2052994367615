import { Button } from 'components'
import { darken } from 'polished'
import styled from 'styled-components'

export const HelpButton = styled(
  ({ backgroundColor, hasHover, size, hasLink, isSample, ...rest }) => (
    <Button {...rest} />
  )
)`
  background: none !important;
  border: 0 !important;
  line-height: 1;
  ${({ isSample }) => isSample && 'cursor: default !important'};

  &:hover > div {
    ${({ backgroundColor, hasHover, theme }) =>
      hasHover && `background: ${darken(0.05, theme[backgroundColor])};`};
  }
`
