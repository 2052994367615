import {
  Dropdown as ReactDropdownContainer,
  DropdownToggle as ReactDropdownToggle,
} from 'reactstrap'
import styled from 'styled-components'

export const ReactDropdown = styled(({ isDisabled, ...props }) => (
  <ReactDropdownContainer disabled={isDisabled} {...props} />
))`
  svg,
  span {
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')} !important;
  }
`

export const Toggle = styled(({ isRound, ...props }) => (
  <ReactDropdownToggle {...props} />
))`
  cursor: pointer;
  text-transform: uppercase;
  ${({ isRound, ...props }) =>
    isRound &&
    `
    width: 32px;
    height: 32px;
    padding: 0;
    border: 1px solid ${props.theme.lightGrey};
  `}
`
