import classNames from 'classnames'
import { Display, Icon } from 'components'
import { FC, useState } from 'react'
import { Dropdown, DropdownMenu } from 'reactstrap'
import { EColor } from 'types'
import { getShortenedText } from 'utils'

import * as Style from './style'
import * as Type from './type'

const Breadcrumb: FC<Type.IBreadcrumb> = ({ data, handleClick }) => {
  const [isOpenMobile, setIsOpenMobile] = useState(false)
  const [isOpenDesktop, setIsOpenDesktop] = useState(false)

  if (!data) {
    return <></>
  }

  const getBreadcrumbSeparator = () => (
    <div className="px-3 d-flex align-items-center">
      <Icon icon={['far', 'chevron-right']} color={EColor.BLACK} />
    </div>
  )

  const getIncrement = (index: number) => {
    const placeholder = '--'
    return (
      <>
        {index > 1 && (
          <Style.Increment className="mr-2 d-none d-md-inline">
            {placeholder.repeat(index - 1)}
          </Style.Increment>
        )}
        {index > 0 && (
          <Style.Increment className="mr-2 d-inline d-md-none">
            {placeholder.repeat(index)}
          </Style.Increment>
        )}
      </>
    )
  }

  const getCrumbsSelect = (items: Array<Type.ICrumb>, isMobile: boolean) => (
    <>
      <Dropdown
        isOpen={isMobile ? isOpenMobile : isOpenDesktop}
        toggle={() =>
          isMobile
            ? setIsOpenMobile(!isOpenMobile)
            : setIsOpenDesktop(!isOpenDesktop)
        }
      >
        <Style.Ellipsis />
        <DropdownMenu>
          {items.map((item, key) => (
            <Style.BreadcrumbSelectItem
              className={classNames(key === 0 && 'd-block d-md-none')}
              key={item.id}
              onClick={() => handleClick(item.id)}
            >
              {getIncrement(key)}
              {getShortenedText(item.name, 30)}
            </Style.BreadcrumbSelectItem>
          ))}
        </DropdownMenu>
      </Dropdown>
      {getBreadcrumbSeparator()}
    </>
  )

  const getShortenedBreadCrumb = (items: Array<Type.ICrumb>, limit: number) => (
    <>
      {getCrumb(items[0], 'first')}
      {getCrumbsSelect(items.slice(0, -1), limit === 1)}
      {getCrumb(items[items.length - 1], 'last')}
    </>
  )

  const getPosition = (index: number, max: number) => {
    if (index + 1 === max) {
      return 'last'
    }

    return index === 0 ? 'first' : undefined
  }

  const getCrumbName = (name: string, position: 'first' | 'last' | undefined) =>
    position !== 'first' ? name : getShortenedText(name, 30)

  const getCrumb = (
    crumb: Type.ICrumb,
    position: 'first' | 'last' | undefined
  ) => (
    <div
      className={classNames(
        position === 'first' ? 'd-none d-md-flex' : 'd-flex'
      )}
    >
      <div className="d-flex">
        <Style.BreadcrumbItem
          position={position}
          onClick={() => {
            position !== 'last' && handleClick(crumb.id)
          }}
        >
          <Display
            type="h3"
            color={EColor.BLACK}
            className={classNames(
              'mb-0',
              !crumb.active && 'font-weight-normal'
            )}
          >
            {getCrumbName(crumb.name, position)}
          </Display>
        </Style.BreadcrumbItem>
      </div>
      {position !== 'last' && getBreadcrumbSeparator()}
    </div>
  )

  if (data.length === 1) {
    return (
      <Display type="h3" color={EColor.BLACK} className="mb-0">
        {data[0].name}
      </Display>
    )
  }

  const getCrumbs = (limit: number) =>
    data.length > limit
      ? getShortenedBreadCrumb(data, limit)
      : data.map((item, key) => {
          const position = getPosition(key, data.length)
          return <div key={item.id}>{getCrumb(item, position)}</div>
        })

  return (
    <>
      <div className="flex-row align-items-center d-flex d-md-none">
        {getCrumbs(1)}
      </div>
      <div className="flex-row align-items-center d-none d-md-flex">
        {getCrumbs(2)}
      </div>
    </>
  )
}

export default Breadcrumb
