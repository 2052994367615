import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as Type from 'api/type'
import { IStoreSupportMessage, ISupportMessagePayload } from 'state/type'
import { capitalize, getLocaleShortcode } from 'utils'
import { ELocale } from 'utils/Intl/type'

export const supportMessageInitialState: IStoreSupportMessage = {
  message: null,
}

const getMessageLocale = (locale: ELocale): string =>
  `message${capitalize(getLocaleShortcode(locale))}`

const getSupportMessage = (
  supportMessage: ISupportMessagePayload
): IStoreSupportMessage => {
  const { locale, ...messages } = supportMessage
  const localeKey = getMessageLocale(locale) as Type.ILocaleMessage
  return {
    message: messages[localeKey] ?? null,
  }
}

const supportSlice = createSlice({
  name: 'support',
  initialState: supportMessageInitialState,
  reducers: {
    setSupportMessage: (state, action: PayloadAction<ISupportMessagePayload>) =>
      getSupportMessage(action.payload),
  },
})

export const { setSupportMessage } = supportSlice.actions
export default supportSlice.reducer
