export const assoconnectSignUpWhitelistedCountries = [
  'FR',
  'BE',
  'US',
  'CH',
  'LU',
  'CA',
]
export const springlySignUpCountries = ['US', 'CA']
export const overseasFranceList = [
  'BL', // Saint-Barthélemy
  'GF', // Guyane (française)
  'GP', // Guadeloupe
  'MF', // Saint-Martin
  'MQ', // Martinique
  'PM', // Saint-Pierre-et-Miquelon
  'RE', // La Réunion
  'TF', // Terres australes françaises
  'YT', // Mayotte
]
