import { Row as ReactstrapRow } from 'reactstrap'
import styled from 'styled-components'

export const Row = styled(({ showColsSeparation, ...rest }) => (
  <ReactstrapRow {...rest} />
))`
  & > * {
    border-top: ${({ showColsSeparation, theme }) =>
      showColsSeparation ? `1px solid ${theme.grey}` : 'none'};
    padding-top: ${({ showColsSeparation }) =>
      showColsSeparation ? '1rem' : 0};
    padding-bottom: ${({ showColsSeparation }) =>
      showColsSeparation ? '1rem' : 0};

    @media (min-width: ${({ theme }) => theme['gridBreakpoints-lg']}) {
      border-top: none;
      border-left: ${({ showColsSeparation, theme }) =>
        showColsSeparation ? `1px solid ${theme.grey}` : 'none'};
      padding-top: 0;
      padding-bottom: 0;
    }

    &:first-child {
      ${({ showColsSeparation }) => showColsSeparation && 'border: none'};
    }
  }
`
