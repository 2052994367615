import {
  AbsoluteDateRange,
  Background,
  Button,
  Col,
  Input,
  Row,
  Select,
} from 'components'
import { CLEAR_FLAG } from 'components/Form/AbsoluteDate/AbsoluteDateRange'
import { IResetForm, ISetFieldValue, ISubmitForm } from 'components/Form/type'
import { FormikValues, useFormikContext } from 'formik'
import { createIntl, useTranslation } from 'hooks'
import debounce from 'lodash.debounce'
import { FC, ReactNode, useEffect, useMemo, useState } from 'react'
import { Collapse } from 'reactstrap'
import { EColor } from 'types'

import * as Style from './style'
import * as Type from './type'

const getMainFilter = (filters: Type.IResultsFilter) => {
  const mainSearch = filters.filter((filter) => filter.type === 'main-search')
  if (mainSearch.length) {
    return mainSearch[0]
  }
  throw new Error('Missing main filter')
}

const getCommonFilterProps = (filter: Type.IFilter) => ({
  name: filter.name,
  ...(filter.label && { label: filter.label }),
  ...(filter.placeholder && { placeholder: filter.placeholder }),
})

export const clearFilters = (
  filtersWithoutDefault: Type.IResultsFilter,
  resetForm: IResetForm,
  setFieldValue: ISetFieldValue,
  submitForm: ISubmitForm
): void => {
  resetForm()
  for (const filter of filtersWithoutDefault) {
    if (filter.type === 'date-range') {
      setFieldValue(filter.name, CLEAR_FLAG)
    } else {
      setFieldValue(filter.name, '')
    }
  }
  // Prevent formik errors by waiting for the values to reset before submitting the form
  setTimeout(async () => {
    await submitForm()
  }, 0)
}

const ResultsFilters: FC<Type.IResultsFilters> = ({
  isCollapseOpen,
  filters,
  filtersWithoutDefault,
  formikRef,
  resultsType,
}) => {
  const intl = createIntl('components_results_filters')
  const translation = useTranslation(intl)
  const { resetForm, setFieldValue, submitForm } =
    useFormikContext<FormikValues>()

  const [isOpen, setIsOpen] = useState(isCollapseOpen)
  const mainFilter = getMainFilter(filters)

  const debouncedEventHandler = useMemo(
    () => debounce(() => submitForm(), 300),
    [submitForm]
  )

  useEffect(() => {
    formikRef?.current?.resetForm()
    for (const dateRangeFilter of filters.filter(
      (filter) => filter.type === 'date-range'
    )) {
      setFieldValue(dateRangeFilter.name, CLEAR_FLAG, false)
    }
    setIsOpen(false)
  }, [filters, formikRef, resultsType, setFieldValue])

  const getMainSearchFilter = (filter: Type.IFilter): ReactNode => (
    <Input
      {...getCommonFilterProps(filter)}
      prepend={{ icon: ['fas', 'search'] }}
      onChange={() => debouncedEventHandler()}
      onClear={() => submitForm()}
      className="mb-0"
      isClearable={true}
    />
  )

  const getSelectFilter = (filter: Type.ISelectFilter): ReactNode => (
    <Select
      {...getCommonFilterProps(filter)}
      options={filter.options}
      onChangeCallback={() => submitForm()}
      isClearable={true}
      isMulti={!!filter.isMulti}
    />
  )

  const getDateRangeFilter = (filter: Type.IFilter): ReactNode => (
    <AbsoluteDateRange
      names={{
        base: `${filter.name}`,
        start: `${filter.name}_start`,
        end: `${filter.name}_end`,
      }}
      label={filter.label || ''}
      placeholder={filter.placeholder || ''}
      onChange={() => submitForm()}
    />
  )

  const getHiddenFilter = (filter: Type.IFilter): ReactNode => (
    <Input type="hidden" name={filter.name} value={filter.value as string} />
  )

  const getFilter = (filter: Type.IFilter): ReactNode => {
    switch (filter.type) {
      case 'main-search':
        return getMainSearchFilter(filter)
      case 'select':
        return getSelectFilter(filter as Type.ISelectFilter)
      case 'date-range':
        return getDateRangeFilter(filter)
      case 'hidden':
        return getHiddenFilter(filter)
      default:
        return <></>
    }
  }

  return (
    <Background>
      {mainFilter && (
        <>
          <Row className="align-items-end">
            <Col lg={8}>{getFilter(mainFilter)}</Col>
            <Col lg={2} className="text-center">
              <Style.MoreFiltersButton
                onClick={() => {
                  setIsOpen(!isOpen)
                }}
                color={EColor.BLUE}
                outline={true}
                className="mt-3 mt-lg-0"
              >
                {translation.translate(
                  `moreFilters.${!isOpen ? 'open' : 'close'}`
                )}
              </Style.MoreFiltersButton>
            </Col>
            <Col
              lg={2}
              className="text-center text-lg-left d-flex justify-content-center align-items-center"
            >
              <Button
                color={EColor.TRANSPARENT}
                onClick={async () => {
                  clearFilters(
                    filtersWithoutDefault,
                    resetForm,
                    setFieldValue,
                    submitForm
                  )
                }}
              >
                <u>{translation.translate('clearFilters')}</u>
              </Button>
            </Col>
          </Row>
          <Collapse isOpen={isOpen} className="pt-4">
            <div className="border-top mt-1 pt-4">
              <Row>
                {filters
                  .filter((filter) => filter.type.indexOf('main-') < 0)
                  .map((filter, key) => (
                    <Col lg={6} key={key}>
                      {getFilter(filter)}
                    </Col>
                  ))}
              </Row>
            </div>
          </Collapse>
        </>
      )}
    </Background>
  )
}

export default ResultsFilters
