import {
  ISpecificationStatus,
  IStoreOrganization,
  IStorePerson,
} from 'state/type'

export enum ESpecification {
  ACCOUNTING_SETUP = 'ACCOUNTING_SETUP',
  ACCOUNTING_HAS_ONGOING_PERIOD = 'ACCOUNTING_HAS_ONGOING_PERIOD',
  COLLECT = 'COLLECT',
  SUBSCRIPTION = 'SUBSCRIPTION',
  WALLET_VERIFIED = 'WALLET_VERIFIED',
  WALLET_CREATED = 'WALLET_CREATED',
  EMAILING_HAS_CAMPAIGNS = 'EMAILING_HAS_CAMPAIGNS',
}

export interface ISpecifications {
  ACCOUNTING_SETUP: undefined | ISpecificationStatus
  COLLECT: undefined | ISpecificationStatus
  SUBSCRIPTION: undefined | ISpecificationStatus
  WALLET_VERIFIED: undefined | ISpecificationStatus
}

export enum ERefusalReason {
  APP_DISABLED = 'APP_DISABLED',
  OTHER = 'OTHER',
  PSP_WALLET_BLOCKED = 'PSP_WALLET_BLOCKED',
  PSP_WALLET_SUSPENDED = 'PSP_WALLET_SUSPENDED',
  SETTING = 'SETTING',
  UNAUTHORIZED = 'UNAUTHORIZED',
  ACCOUNTING_PERIOD = 'ACCOUNTING_PERIOD',
  EMAILING_HAS_NO_CAMPAIGNS = 'EMAILING_HAS_NO_CAMPAIGNS',
}

export type ISpecificationValidity = undefined | boolean

export interface IUseSpecifications {
  getSpecifications: (
    specificationsList: Array<ESpecification>,
    organization: IStoreOrganization,
    person: IStorePerson
  ) => Promise<{ [key: string]: ISpecificationStatus }>
}
