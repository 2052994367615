import { FormikValues } from 'formik'
import { ReactElement } from 'react'
import { ESize } from 'types'

export type ITypeSelected = {
  confirm: string
  delete: string
}

export interface IModalConfirm {
  isOpen: boolean
  confirmIsDisabled?: boolean
  buttonsText?: {
    confirm: string
    cancel: string
  }
  callbackConfirm: (values?: FormikValues) => Promise<void>
  callbackCancel: (values?: FormikValues) => Promise<void>
  size?: ESize
  title?: string
  description: string | ReactElement
  type: EModalType
  isProcessing?: boolean
}

export enum EModalType {
  CONFIRM = 'confirm',
  DELETE = 'delete',
}
