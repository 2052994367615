import classNames from 'classnames'
import {
  Button,
  FormGroup,
  FormHelper,
  Helper,
  Icon,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
} from 'components'
import {
  Field,
  FieldProps,
  FormikValues,
  getIn,
  useFormikContext,
} from 'formik'
import { createIntl, useTranslation } from 'hooks'
import { ChangeEvent, FC, useRef } from 'react'
import { Input as ReactStrapInput } from 'reactstrap'
import { EColor, ESize } from 'types'
import { uniqId } from 'utils'

import * as Type from './type'

const Input: FC<Type.IInput> = ({
  append,
  className,
  disabled = false,
  fieldName, // Temporary solution before splitting Input and ControlledInput
  handleChange,
  helpText,
  isClearable = false,
  isInvalid = false,
  label,
  maxLength = 255,
  minLength = 0,
  name,
  onBlur,
  onChange,
  onClear,
  prepend,
  required = false,
  type = 'text',
  validate,
  toolTip,
  ...rest
}) => {
  const intl = createIntl('components_input')
  const translation = useTranslation(intl)
  const refUniqueId = useRef(uniqId('input'))
  const { setFieldTouched, setFieldValue, values } =
    useFormikContext<FormikValues>()

  const validateInput = ():
    | undefined
    | string
    | Promise<undefined | string> => {
    // Required : keep !value to avoid missing value error display

    const value = values[String(name || fieldName)]

    if (required && !value) {
      return translation.translate('validate.required')
    }
    // Max length
    if (maxLength && value?.length > maxLength) {
      return translation.translate('validate.maxLength', {
        limit: maxLength,
      })
    }
    // Min length
    if (minLength && value?.length < minLength) {
      return translation.translate('validate.minLength', {
        limit: minLength,
      })
    }
    // Child component validation
    if (validate) {
      return validate(value)
    }
  }

  const handleClear = () => {
    name && setFieldValue(name, '')
    onClear && onClear()
  }

  return (
    <Field
      name={name}
      validate={validateInput}
      children={({
        field,
        form: { errors, touched, handleChange: handleFieldChange, values },
      }: FieldProps) => {
        const invalid =
          isInvalid || (name && getIn(errors, name) && getIn(touched, name))
        const reactStrapInput = (
          <ReactStrapInput
            {...field}
            id={refUniqueId.current}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              handleChange
                ? handleChange(event.target.value)
                : handleFieldChange(event)
              onChange && onChange(event)
            }}
            onBlur={(event) => {
              if (name) {
                if (typeof values[name] === 'string') {
                  const trimmedValue = values[name].trim()
                  setFieldValue(name, trimmedValue)
                  event.target.value = trimmedValue
                }
                setFieldTouched(name, true)
              }
              onBlur && onBlur(event)
            }}
            name={name}
            type={type}
            disabled={disabled}
            className={classNames(
              { 'is-invalid': invalid },
              type === 'textarea' && 'py-2 px-3'
            )}
            maxLength={maxLength}
            {...rest}
          />
        )

        if (type === 'hidden') {
          return reactStrapInput
        }

        return (
          <FormGroup className={className}>
            {label && (
              <Label isRequired={required} for={refUniqueId.current}>
                {label}
              </Label>
            )}
            {toolTip && (
              <Helper
                tooltip={{
                  text: toolTip.text,
                  position: toolTip.position,
                }}
                button={{
                  size: ESize.SM,
                  backgroundColor: EColor.DARK_GREY,
                  textColor: EColor.WHITE,
                }}
              />
            )}
            <InputGroup
              additionalElements={{ prepend, append }}
              className={classNames({ 'is-invalid': invalid })}
              disabled={disabled}
            >
              {prepend && (
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    {prepend.children ?? (
                      <span>
                        {prepend?.icon && (
                          <Icon
                            icon={prepend.icon}
                            color={prepend?.color || EColor.GREY}
                          />
                        )}
                        {prepend?.text}
                      </span>
                    )}
                  </InputGroupText>
                </InputGroupAddon>
              )}
              {reactStrapInput}
              {isClearable && (
                <Button
                  color={EColor.TRANSPARENT}
                  className={classNames(
                    'btn-clear',
                    !append && 'btn-clear-last'
                  )}
                  onClick={handleClear}
                >
                  <Icon icon={['far', 'times']} />
                </Button>
              )}
              {append && (
                <InputGroupAddon addonType="append">
                  {append && (
                    <InputGroupText>
                      {append.children ?? (
                        <span>
                          {append.icon && (
                            <Icon
                              icon={append.icon}
                              color={append?.color || EColor.GREY}
                            />
                          )}
                          {append?.text}
                        </span>
                      )}
                    </InputGroupText>
                  )}
                </InputGroupAddon>
              )}
            </InputGroup>
            <FormHelper
              fieldName={name || fieldName || ''}
              helpText={helpText}
            />
          </FormGroup>
        )
      }}
    />
  )
}

export default Input
