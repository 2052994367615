export const addressFieldNames = [
  'administrativeArea1',
  'administrativeArea2',
  'city',
  'country',
  'postal',
  'street1',
  'street2',
  'latitude',
  'longitude',
]
