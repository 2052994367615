import styled, { css } from 'styled-components'

const helpButtonSm = (withText: boolean) => css`
  width: ${withText ? '2rem' : '1.13rem'};
  height: ${withText ? '1.4rem' : '1.13rem'};

  font-size: 8.5px;
  font-weight: 500;
  border-radius: 45% 10%;
`

const helpButtonMd = (withText: boolean) => css`
  width: ${withText ? '3rem' : '1.13rem'};
  height: ${withText ? '1.7rem' : '1.13rem'};

  font-size: 11px;
  font-weight: 500;
  border-radius: 50% 10%;
`

const helpButtonLg = (withText: boolean) => css`
  width: ${withText ? '4rem' : '2.286rem'};
  height: 2.286rem;
  border-radius: ${withText ? '40% 10%' : ' 50% 10%'};
`

export const HelperIconWrapper = styled(
  ({ backgroundColor, size, content, withText, hasHover, ...rest }) => (
    <div {...rest} />
  )
)`
  ${({ size, withText }) => size === 'md' && helpButtonMd(withText)};
  ${({ size, withText }) => size === 'lg' && helpButtonLg(withText)};
  ${({ size, withText }) => size === 'sm' && helpButtonSm(withText)};
  display: inline-flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme, backgroundColor }) => theme[backgroundColor]};

  box-shadow: 0 1px 3px 0 ${({ theme }) => theme.grey};

  color: ${({ theme }) => theme.white} !important;
`
