import { Background, Label as DefaultLabel } from 'components'
import { Checkbox as StyledCheckbox } from 'components/Form/Checkbox/style'
import styled, { css } from 'styled-components'

export const CheckedStyle = css`
  border: 1px solid ${(props) => props.theme.grey};
`

export const CheckboxCard = styled((props) => <Background {...props} />)`
  cursor: pointer;
  border: 1px solid transparent;
  ${({ checked }) => checked && CheckedStyle};
`

export const Label = styled((props) => <DefaultLabel {...props} />)`
  cursor: pointer;
`

export const Checkbox = styled((props) => <StyledCheckbox {...props} />)`
  pointer-events: none;
`
