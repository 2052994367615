import { Col, Title } from 'components'
import { Alert as ReactAlert } from 'reactstrap'
import styled from 'styled-components'

export const Alert = styled(({ outline, ...props }) => (
  <ReactAlert {...props} />
))`
  box-shadow: 0 1px 3px 0 ${(props) => props.theme.lightGrey};
  background-color: ${({ color, outline, theme }) =>
    outline ? 'transparent' : theme[color]};
  color: ${({ outline, ...props }) =>
    outline ? props.theme.black : props.theme.white};
  padding: 0.9rem;
  border-color: ${({ theme, color }) => theme[color]};

  p:last-of-type {
    margin-bottom: 0 !important;
  }

  .close {
    z-index: 1;
    opacity: 1;
    color: ${({ outline, ...props }) =>
      outline ? props.theme.black : props.theme.white};
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.75rem;
    padding: 0.5rem 0.75rem;
  }
`

export const IconWrapper = styled(({ color, outline, ...props }) => (
  <div {...props} />
))`
  justify-content: center;
  align-items: center;
  display: flex;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: ${({ color, outline, ...props }) =>
    outline ? props.theme[color] : props.theme.white};
`

export const AlertTitle = styled(({ children, ...props }) => (
  <Title type="h6" {...props}>
    {children}
  </Title>
))`
  line-height: 22px;
`
export const ContentCol = styled((props) => <Col {...props} />)`
  line-height: 22px;
`
