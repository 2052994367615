import { Helper as HelperComponent, Icon as IconComponent } from 'components'
import styled from 'styled-components'

export const Thead = styled.thead`
  position: relative;
  width: 100%;
  color: ${(props) => props.theme.grey};
  box-shadow: 0 4px 3px -3px ${(props) => props.theme.lightGrey};
  border-radius: 5px;
  background: ${(props) => props.theme.white};
  z-index: 2;

  & > tr > th {
    border: 0;
  }

  @media (max-width: ${(props) => props.theme['gridBreakpoints-lg']}) {
    display: none;
  }
`

export const Th = styled(({ size, ...props }) => <th {...props} />)`
  width: ${({ size }) => (typeof size === 'number' ? `${size}px` : size)};
  text-align: ${({ align }) => align || 'left'};
  border-right: 1px solid grey;

  .content {
    display: flex;
    align-items: center;
    padding-right: ${({ align }) => (align === 'end' ? '0px' : '5px')};
    justify-content: ${({ align }) => align || 'start'};
  }

  .text {
    display: inline;
    float: left;
  }
  .sortby {
    display: flex;
    flex-direction: column;
  }
  .icon {
    margin-left: 7px;
    cursor: pointer;
  }
`

export const Icon = styled(({ ...props }) => <IconComponent {...props} />)`
  font-size: 9px;
  margin: 1px;

  &:hover {
    color: ${(props) => props.theme.darkGrey};
  }
`
export const Helper = styled(({ ...props }) => <HelperComponent {...props} />)`
  padding-left: 3px;
`
