import { Helper } from 'components'
import { createIntl, useSelector, useTranslation } from 'hooks'
import { FC } from 'react'
import { useDispatch } from 'react-redux'
import { selectPerson, setSidePanel } from 'state'
import { EColor } from 'types'

const HeaderNavigation: FC = () => {
  const dispatch = useDispatch()
  const intl = createIntl('components_header')
  const translation = useTranslation(intl)
  const person = useSelector(selectPerson)

  const handleClick = () => {
    dispatch(
      setSidePanel({
        actions: {},
        options: {},
        isOpen: true,
        template: 'HelpPanel',
        title: translation.translate(`sidePanel.title`, {
          firstname: person.firstName,
        }),
      })
    )
  }

  return (
    <Helper
      withText={true}
      onClick={handleClick}
      button={{
        size: 'lg',
        backgroundColor: EColor.GREEN,
        textColor: EColor.WHITE,
      }}
      tooltip={{
        text: translation.translate('header.menu.helpCenter'),
      }}
      data-tour="tour-helpButton"
    />
  )
}

export default HeaderNavigation
