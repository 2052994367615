import classNames from 'classnames'
import {
  Button,
  FormGroup,
  FormHelper,
  Icon,
  InputGroupAddon,
  InputGroupText,
  Label,
} from 'components'
import 'flatpickr/dist/themes/airbnb.css'
import { Locale } from 'flatpickr/dist/types/locale'
import { Field, FieldProps, FormikValues, useFormikContext } from 'formik'
import { createIntl, useSelector, useTranslation } from 'hooks'
import { FC, useEffect, useRef, useState } from 'react'
import Flatpickr from 'react-flatpickr'
import { useAsync } from 'react-use'
import { selectOrganization } from 'state'
import { EColor } from 'types'
import {
  formatDate,
  getDateFormat,
  getDateObject,
  getDateObjectWithoutTime,
  getDateYMDFormat,
  getLocaleShortcode,
  sleep,
  uniqId,
} from 'utils'

import * as Style from './style'
import * as Type from './type'

export const CLEAR_FLAG = 'CLEAR'

const AbsoluteDateRange: FC<Type.IAbsoluteDate> = ({
  className,
  helpText,
  label,
  max,
  min,
  names,
  onChange,
  prepend = undefined,
  required = false,
  placeholder,
  ...rest
}) => {
  const intl = createIntl('components_absolutedate')
  const translation = useTranslation(intl)
  const {
    nonprofit: { locale },
  } = useSelector(selectOrganization)

  const refUniqueId = useRef(uniqId('absoluteDateRange'))
  const flatpickrRef = useRef<null | Flatpickr>(null)
  const [initialized, setInitialized] = useState(false)

  const { errors, values, setFieldTouched, setFieldValue, touched } =
    useFormikContext<FormikValues>()

  useEffect(() => {
    if (flatpickrRef.current && values[names.start] && values[names.end]) {
      flatpickrRef.current?.flatpickr.setDate([
        getDateObjectWithoutTime(values[names.start]),
        getDateObjectWithoutTime(values[names.end]),
      ])
    }
    setInitialized(true)
  }, [flatpickrRef, initialized, names, values])

  // Import Flatpickr locale
  const { value: flatpickrLocale } = useAsync(async () => {
    const localeShortcode = getLocaleShortcode(locale)
    const flatpickrLocaleData = await import(
      `flatpickr/dist/l10n/${
        localeShortcode === 'en' ? 'default' : localeShortcode
      }.js`
    )
    return Object.values(flatpickrLocaleData)[0] as Locale
  }, [])

  // Listen to field value to clear its flatpickr instance from outside the component
  useEffect(() => {
    if (values[names.base] === CLEAR_FLAG) {
      setFieldValue(names.base, '', false)
      if (
        flatpickrRef.current &&
        flatpickrRef.current?.flatpickr.selectedDates.length
      ) {
        flatpickrRef.current.flatpickr.clear()
      }
    }
  }, [names, setFieldValue, values])

  const clearAbsoluteDate = (clearFormikValues = true) => {
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.clear()
      if (clearFormikValues) {
        setFieldValue(names.start, '', false)
        setFieldValue(names.end, '', false)
      }
      onChange && onChange()
    }
  }

  const validateDateValues = async () => {
    if (min && values[names.start]) {
      const minDate = new Date(min)
      if (minDate.getTime() > new Date(values[names.start]).getTime()) {
        clearAbsoluteDate()
        return translation.translate('validate.minDate', {
          min: formatDate(new Date(min)),
        })
      }
    }

    if (max && values[names.end]) {
      const maxDate = new Date(max)
      if (maxDate.getTime() < new Date(values[names.end]).getTime()) {
        clearAbsoluteDate()
        return translation.translate('validate.maxDate', {
          max: formatDate(new Date(max)),
        })
      }
    }

    if (
      required &&
      (!flatpickrRef.current ||
        !flatpickrRef.current?.flatpickr.selectedDates.length)
    ) {
      if (!touched[names.base]) {
        // Use sleep to prevent infinite loop on validation
        sleep().then(async () => {
          setFieldTouched(names.base)
        })
      }
      return translation.translate('validate.required')
    }

    return ''
  }

  const getDatesFromText = (value: string): Array<Date> => {
    const dates = []

    for (const element of value.split(' ')) {
      if (!/[a-zA-Z]/.test(element)) {
        const date = getDateObject(element, getDateFormat(locale, 'parse'))

        if (!isNaN(date.getTime())) {
          dates.push(getDateObjectWithoutTime(getDateYMDFormat(date)))
        }
      }
    }

    return dates
  }

  const updateDatesValue = (value: string) => {
    const dates = getDatesFromText(value)

    const dateValues = {
      start: '',
      end: '',
    }

    if (dates.length === 2 && dates[0].getTime() && dates[1].getTime()) {
      dateValues.start = getDateYMDFormat(dates[0])
      dateValues.end = getDateYMDFormat(dates[1])

      // Update the flatpickr dates in case of manual input
      if (flatpickrRef.current) {
        flatpickrRef.current?.flatpickr.setDate(dates)
      }
    }

    setFieldValue(names.start, dateValues.start)
    setFieldValue(names.end, dateValues.end)

    onChange && onChange()
    sleep().then(async () => {
      setFieldTouched(names.base)
    })
  }

  if (!flatpickrLocale) {
    return <></>
  }

  return (
    <Field
      name={names.base}
      validate={validateDateValues}
      children={({ field }: FieldProps) => {
        const localeOption = {
          locale: flatpickrLocale,
        }
        const invalid = !!(touched[names.base] && errors[names.base])
        const minMaxOptions: Type.IMinMaxOptions = {}
        if (max) {
          minMaxOptions.maxDate = getDateObjectWithoutTime(max)
        }
        if (min) {
          minMaxOptions.minDate = getDateObjectWithoutTime(min)
        }

        return (
          <FormGroup className={className}>
            {label && (
              <Label isRequired={required} for={refUniqueId.current}>
                {label}
              </Label>
            )}
            <Flatpickr
              id={refUniqueId.current}
              ref={flatpickrRef}
              value={
                field.value && field.value !== CLEAR_FLAG
                  ? getDateObjectWithoutTime(field.value)
                  : ''
              }
              options={{
                allowInput: true,
                dateFormat: getDateFormat(locale, 'display'),
                ariaDateFormat: getDateFormat(locale, 'display'),
                mode: 'range',
                ...minMaxOptions,
                ...localeOption,
                ...flatpickrLocale,
              }}
              onClose={(selectedDates, dateStr) => {
                const dates = getDatesFromText(dateStr)
                // Clear the input if we do not have exactly two dates
                if (dates.length !== 2) {
                  clearAbsoluteDate()
                }
                // Force Flatpickr blur to trigger value changes and validation
                const inputElement = document.activeElement as HTMLElement
                inputElement.blur()
              }}
              render={(props, ref) => (
                <>
                  <Style.InputGroup
                    additionalElements={{
                      prepend,
                    }}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <span>
                          <Icon
                            icon={['far', 'calendar-day']}
                            color={EColor.GREY}
                          />
                        </span>
                      </InputGroupText>
                    </InputGroupAddon>
                    <input
                      {...rest}
                      placeholder={
                        placeholder ||
                        translation.translate('absoluteDateRange.placeholder')
                      }
                      ref={ref}
                      className={classNames([
                        'form-control',
                        { 'is-invalid': invalid },
                      ])}
                      onBlur={(event) => {
                        if (event.target.value.length) {
                          updateDatesValue(event.target.value)
                        }
                      }}
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText className="px-0">
                        <Button
                          color={EColor.TRANSPARENT}
                          className="text-dark"
                          onClick={() => {
                            clearAbsoluteDate(false)
                            setFieldValue(names.start, '', true)
                            setFieldValue(names.end, '', true)
                          }}
                        >
                          <Style.ClearIcon icon={['far', 'times']} />
                        </Button>
                      </InputGroupText>
                    </InputGroupAddon>
                  </Style.InputGroup>
                  <input {...field} type="hidden" />
                </>
              )}
            />
            <FormHelper fieldName={field.name} helpText={helpText} />
          </FormGroup>
        )
      }}
    />
  )
}

export default AbsoluteDateRange
