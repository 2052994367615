import { RouterContext } from 'providers'
import { useContext } from 'react'
import { useHistory as useHistoryReactRouter } from 'react-router'

import * as Type from './type'

const UseHistory = (): Type.IUseHistory => {
  // NOSONAR to ignore the TypeScript blocking of the useHistory function of react-router:
  // src/types/react-router/index.d.ts
  const history = useHistoryReactRouter() // NOSONAR
  const { urlSearchParameters } = useContext(RouterContext)

  const push = (
    path: string,
    state?: Record<string, unknown> | null | undefined,
    saveRedirectOnPath = false
  ): void => {
    const redirectUrl = urlSearchParameters?.redirect

    // if saveRedirectOnPath is set at false, and the redirect param exist the redirection url will be the param redirect
    // if saveRedirectOnPath is set at true, the redirect param in current url is included in new redirection url as a parameter
    return history.push(
      redirectUrl && !saveRedirectOnPath
        ? redirectUrl
        : path + history.location.search,
      state
    )
  }

  return { ...history, push }
}

export default UseHistory
