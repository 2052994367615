import { EApiKycStatus, TKycError } from 'api/type'

export interface IApiBaseBankAccount {
  id: string
  bankName: string
  bic: string | null
  iban: string | null
  isUsedOnKycProcess: boolean
  name: string
  organization: string
  ownBankAccount: boolean
  ownerName: string | null
  type: EBankAccountType
}

export interface IApiBankAccount extends IApiBaseBankAccount {
  '@id': string
  accountNumber: string | null
  branchCode: string | null
  isArchived: boolean
  isUnarchivable: boolean
  isDeletable: boolean
  proAccountAccount: string | null
}

export interface IApiBankAccountWithPspDetails extends IApiBankAccount {
  pspDetails?: IPspDetails
}

export type IPspDetails = {
  bankAccount: string
  id: string
  kycError: TKycError
  kycStatus: EApiKycStatus
}

export enum EBankAccountType {
  US = 'US',
  EUROPEAN = 'EUROPEAN',
}
