import { FC } from 'react'

import * as Style from './style'
import * as Type from './type'

const InputGroup: FC<Type.IInputGroup> = ({ ...props }) => (
  <Style.InputGroup {...props} />
)

export default InputGroup
