import { Input } from 'components'
import { FC } from 'react'

import { IInput } from '../Input/type'

const Textarea: FC<IInput> = ({ maxLength = null, ...props }) => (
  <Input type="textarea" maxLength={maxLength} rows={50} {...props} />
)

export default Textarea
