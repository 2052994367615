import styled from 'styled-components'

export const NotificationWrapper = styled.div`
  width: 100%;
  padding: 0 2.813rem;
  position: fixed;
  bottom: 40px;
  left: 0;
  z-index: 889;
  pointer-events: none;

  @media (min-width: ${(props) => props.theme['gridBreakpoints-md']}) {
    width: calc(100% - 220px);
    left: 220px;
  }
`
