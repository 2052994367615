export interface IApiOrganizationApps {
  '@context': string
  '@id': string
  '@type': string
  apps: IApiApp[]
}

export interface IApiApp {
  '@context': string
  '@id': string
  '@type': string
  name: EApp
  isEnabled: boolean
  isLocked: boolean
  lockedReason: string
}

export enum EApp {
  ACCOUNTING = 'ACCOUNTING',
  AGENDA = 'AGENDA',
  COLLECT_WITH_VALIDATION = 'COLLECT_WITH_VALIDATION',
  DIRECTORY = 'DIRECTORY',
  DONATION = 'DONATION',
  EMAILING = 'EMAILING',
  GALLERY = 'GALLERY',
  MEMBERSHIP = 'MEMBERSHIP',
  POLL = 'POLL',
  POST = 'POST',
  PRO_ACCOUNT = 'PRO_ACCOUNT',
  STORE = 'STORE',
  STRUCTURE = 'STRUCTURE',
  TICKETING = 'TICKETING',
  TIMESHEET = 'TIMESHEET',
  SITE = 'SITE',
  WALLET = 'WALLET',
}
