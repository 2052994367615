import { API } from 'api/connector'
import { IApiAccountingBreakdown, IApiAccountingEntry } from 'api/type'

export const getAccountingEntry = async (
  id: IApiAccountingEntry['@id']
): Promise<IApiAccountingEntry> => {
  const entries = await API.get(`/api/v1/accounting_entries/${id}`)
  return entries?.data
}

export const getAccountingEntryBreakdown = async (
  id: IApiAccountingEntry['@id']
): Promise<IApiAccountingBreakdown> => {
  const entries = await API.get(
    `/api/v1/accounting_entries/${id}/accounting_breakdown`
  )
  return entries?.data
}
