import { AccountingSettings, IApiAccountingSettings } from 'api/type'
import { EAccountingLevel } from 'pages/Accounting/type'
import { ELocale } from 'utils/Intl/type'

export enum ESector {
  ALUMNI = 'ALUMNI',
  CHARITY = 'CHARITY',
  CULTURE = 'CULTURE',
  ENVIRONMENT = 'ENVIRONMENT',
  FOUNDATION = 'FOUNDATION',
  LEISURE = 'LEISURE',
  MEDICAL = 'MEDICAL',
  MILITARY = 'MILITARY',
  OMNISPORTS = 'OMNISPORTS',
  POLITICAL = 'POLITICAL',
  PRO = 'PRO',
  RELIGIOUS = 'RELIGIOUS',
  RIGHTS = 'RIGHTS',
  SCHOOL = 'SCHOOL',
  SPORT = 'SPORT',
  STUDENT = 'STUDENT',
  OTHERS = 'OTHERS',
}

export type ITaxReceiptsSettings = {
  '@id': string
  firstname: string
  lastname: string
  jobTitle: string
}

export interface IGetNonProfitsByEmail {
  '@id': string
  '@type': string
  country: string
  createdAt: string
  currency: string
  dateArrete: string | null
  dateDecree: string | null
  datePublicationJO: string | null
  donationDiscount: string | null
  employerIdentificationNumber: string | null
  frenchRna: string | null
  frenchSiren: string | null
  id: string
  locale: ELocale
  name: string
  object: string | null
  status: string | null
  statusMore: string | null
  thematic: string | null
  timezone: 'Europe/Paris'
}

export interface ICreateNonProfit {
  firstname: string
  lastname: string
  email: string
  phone: string
  organizationName: string
  subscriptionType: string
  planReference: string
  partnership: string
  timezone: string
  sector: ESector
  anonymousId: string
  networkSlug: string
}

export interface INonProfitPlatform {
  redirectUrl: string
}

export interface INonprofit {
  '@id': string
  '@type': 'FrNonprofit'
  id: string
}

export interface INonProfitMarketingTracking {
  '@context': string
  '@id': string
  '@type': string
  demoBookedAt: string | null
  estimatedSize: string | null
  initialNeeds: string
}

export interface IApiNonprofitAccountingSettings
  extends AccountingSettings,
    IApiAccountingSettings {
  '@id': string
  id: string
  level?: EAccountingLevel
}

export interface IApiNonprofitAccountingSpecification {
  '@context': string
  '@id': string
  '@type': string
  nonprofitIsAnAccountingBeginner: boolean
}

export interface IApiTaxReceiptSettings {
  '@context': 'string'
  '@id': 'string'
  '@type': 'string'
  firstname: string
  lastname: string
  jobTitle: string
  customNotes: string
  isEnable: boolean
  enableStartDate: string
  discount: number
}
