import { Input, Select } from 'components'
import { getAllTimezones } from 'countries-and-timezones'
import { createIntl, useTranslation } from 'hooks'
import { FC } from 'react'

import * as Type from './type'

export const getCurrentTimezone = (): string =>
  Intl.DateTimeFormat().resolvedOptions().timeZone

const getTimezoneType = (timezone: string, timezoneList: Array<string>) => {
  // If the given timezone is UTC or does not exist
  if (timezone === 'UTC' || timezoneList.indexOf(timezone) < 0) {
    return 'manual'
  }
  return 'automatic'
}

const getTimezones = () => {
  const timezoneList = getAllTimezones()
  return Object.values(timezoneList).filter((tz) => tz.countries.length)
}

const Timezone: FC<Type.ITimezone> = ({
  label,
  name,
  required = false,
  timezone,
  ...rest
}) => {
  const intl = createIntl('components_timezone')
  const translation = useTranslation(intl)

  const timezoneList = getTimezones()
  const currentTimezone = timezone || getCurrentTimezone()
  const type = getTimezoneType(
    currentTimezone,
    timezoneList.filter((tz) => tz.countries.length).map((tz) => tz.name)
  )

  return (
    <>
      {type === 'manual' ? (
        <Select
          label={label || translation.translate('label')}
          name={name}
          options={timezoneList.map((tz) => ({
            label: tz.name.replaceAll('_', ' '),
            value: tz.name.replaceAll('_', ' '),
          }))}
          required={required}
        />
      ) : (
        <Input name={name} type="hidden" {...rest} />
      )}
    </>
  )
}

export default Timezone
