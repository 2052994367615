import { IAlert } from 'components/Alert/type'
import { FC } from 'react'
import { EColor } from 'types'

import * as Style from './style'

const Alert: FC<IAlert> = (props) => (
  <Style.Alert color={EColor.PUBLIC_MAIN_COLOR} {...props} />
)

export default Alert
