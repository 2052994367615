import { API } from 'api/connector'
import { IStoreApplication, IStoreOrganization } from 'state/type'

import { IApiOrganizationApps } from './type'

export const getOrganizationApps = async (
  organizationId: IStoreOrganization['id']
): Promise<IApiOrganizationApps> => {
  const organizationApps = await API.get(
    `/api/v1/organizations/${organizationId}/apps`
  )
  return organizationApps.data
}

export const updateOrganizationApps = async (
  iri: IApiOrganizationApps['@id'],
  apps: Partial<IStoreApplication>[]
): Promise<IApiOrganizationApps> => {
  const organizationApps = await API.put(iri, {
    apps,
  })
  return organizationApps.data
}
