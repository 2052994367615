import * as Sentry from '@sentry/react'
import { FC, Suspense } from 'react'
import { Provider } from 'react-redux'
import Router from 'routers'
import store from 'state/store'
import { createGlobalStyle, ThemeProvider } from 'styled-components'

import Fallback from './components/Fallback'
import './theme/bootstrap.scss'
import theme from './theme/variables.module.scss'

const GlobalStyle = createGlobalStyle`
  html {
    font-size: 14px;
  }
  body {
    background: ${theme.backgroundColor};
    overflow-x: hidden;
  }
  ul {
    padding-left: 2rem;
  }

  // Hack Bootstrap readonly
  input[readonly]:not([disabled]) {
    background-color: #fff !important;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: ${theme.lightGrey};
  }
  &::-webkit-scrollbar {
    width: 6px;
    background-color: ${theme.lightGrey};
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${theme.grey};
  }
  &::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }

  // Select component global style
  body {
    div[class^='css-'] {
      z-index: 999 !important;
    }
    .react-select__clear-indicator {
      cursor: pointer;
    }
    .react-select__menu {
      z-index: 900;
      box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.05),
      0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);

      .react-select__option {
        color: ${theme.black};
        background-color: ${theme.white};
        cursor: pointer;

        &.react-select__option--is-disabled {
          color: ${theme.grey};
        }

        &.react-select__option--is-focused {
          background-color: ${theme.backgroundColor};
        }
      }
    }
  }
`

const App: FC = () => (
  <Suspense fallback="">
    <Sentry.ErrorBoundary fallback={Fallback}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Provider store={store}>
          <Router />
        </Provider>
      </ThemeProvider>
    </Sentry.ErrorBoundary>
  </Suspense>
)

export default Sentry.withProfiler(App)
