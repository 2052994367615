import classNames from 'classnames'
import { Badge, Col, Icon, ResponsiveButton, Row, Title } from 'components'
import { EIconPosition } from 'components/Badge/type'
import { useTranslation } from 'hooks'
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { EColor, ESize } from 'types'

import * as Data from '../data'
import * as Style from './style'
import * as Type from './type'

const LongCardHeader: FC<Type.ILongCardHeader> = ({
  actions,
  isDeployable,
  isArchived,
  isOpen,
  setIsOpen,
  status,
  title,
}) => {
  const intl = useIntl()
  const translation = useTranslation(intl)
  return (
    <Row className="flex-row-reverse align-items-center">
      <Col xs={2} lg={1} className="px-0 text-right">
        {isDeployable && (
          <Style.ToggleButton
            color={EColor.TRANSPARENT}
            className="px-4 mx-2"
            borderLeft={true}
            onClick={() => setIsOpen(!isOpen)}
          >
            <Icon
              icon={['fas', isOpen ? 'chevron-up' : 'chevron-down']}
              color={EColor.DARK_GREY}
            />
          </Style.ToggleButton>
        )}
      </Col>
      <Col xs={isDeployable ? 10 : 12} lg={isDeployable ? 11 : 12}>
        <Row className="align-items-center">
          <Col lg={9}>
            <Row>
              <Col lg="auto">
                <Title
                  type="h5"
                  color={EColor.DARK_GREY}
                  className="mb-0 mr-0 mr-lg-3"
                  uppercase={false}
                >
                  {title}
                </Title>
              </Col>
              {status && (
                <Col lg="auto" className="pl-lg-0">
                  <Badge
                    color={status.color}
                    icon={status.icon}
                    iconPosition={EIconPosition.LEFT}
                    isBold={false}
                    size={ESize.XS}
                    text={status.text}
                  />
                </Col>
              )}
            </Row>
          </Col>
          <Col
            lg={3}
            className={classNames(
              'text-center text-lg-right mt-3 mt-lg-0 d-none d-lg-block',
              {
                'px-0': isDeployable,
              }
            )}
          >
            {actions.map((action, key) => (
              <ResponsiveButton
                icon={Data.responsiveButtonsIcons[action.name]}
                key={key}
                className="mx-1 my-2 my-lg-0"
                onClick={action.handler}
                disabled={action.isDisabled || isArchived}
              >
                {translation.translate(`action.${action.name}`)}
              </ResponsiveButton>
            ))}
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default LongCardHeader
