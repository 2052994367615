/**
 * Bootstrap use special naming convention to handle theme as 'primary', 'danger', 'success'...
 * This parser helps to simplify code by parsing AssoConnect naming convention to bootstrap
 */
import { EColor } from 'types'

export const bootstrapColorParser = (color: EColor): string | EColor => {
  switch (color) {
    case EColor.BLUE:
      return 'primary'
    case EColor.LIGHT_GREY:
      return 'secondary'
    case EColor.GREEN:
      return 'success'
    case EColor.YELLOW:
      return 'warning'
    case EColor.RED:
      return 'danger'
    case EColor.TRANSPARENT:
      return 'link'
    default:
      return color
  }
}
