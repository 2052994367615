import { Modal, ModalFooter, ModalHeader } from 'reactstrap'
import styled from 'styled-components'

export const ModalContainer = styled((props) => <Modal {...props} />)`
  .modal-content {
    border: none;
    border-radius: 10px;
  }
`

export const Footer = styled((props) => <ModalFooter {...props} />)`
  border: none;
  justify-content: center;
`

export const Header = styled((props) => <ModalHeader {...props} />)`
  border: none;
`
