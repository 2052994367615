import { useHistory } from 'hooks'
import { RouterContext } from 'providers'
import { FC, useContext } from 'react'
import { Redirect as RouterRedirect } from 'react-router'
import { getUrlWithParameters } from 'utils'

import * as Type from './type'

const Redirect: FC<Type.IRedirect> = ({
  to,
  parameters,
  saveRedirectOnPath,
  ...rest
}) => {
  const history = useHistory()

  const { urlSearchParameters } = useContext(RouterContext)

  // if saveRedirectOnPath is set at false, and the redirect param exist the redirection url will be the param redirect
  // if saveRedirectOnPath is set at true, the redirect param in current url is included in new redirection url as a parameter
  const toFinal =
    urlSearchParameters?.redirect && !saveRedirectOnPath
      ? urlSearchParameters?.redirect
      : getUrlWithParameters(to as string, history, parameters)

  return <RouterRedirect to={toFinal} {...rest} />
}

export default Redirect
