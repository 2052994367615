import { Icon, InputGroup as InputGroupComponent } from 'components'
import styled from 'styled-components'

export const InputGroup = styled((props) => <InputGroupComponent {...props} />)`
  input {
    background-image: none !important;
  }
`

export const ClearIcon = styled((props) => <Icon {...props} />)`
  color: ${({ theme }) => theme.grey};
  &:hover {
    color: ${({ theme }) => theme.darkGrey};
  }
`
