import { Title } from 'components'
import { FC } from 'react'

import * as Style from './style'
import * as Type from './type'

const Header: FC<Type.IHeader> = ({ title }) => (
  <>
    {title && (
      <Style.Header className="popover-header text-center" title={title}>
        <Title type="h6" className="text-uppercase m-0">
          {title}
        </Title>
      </Style.Header>
    )}
  </>
)

export default Header
