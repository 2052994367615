import { Background } from 'components'
import { lighten, transparentize } from 'polished'
import styled from 'styled-components'

export const RadioBackground = styled(({ active, isInvalid, ...props }) => (
  <Background {...props} />
))`
  background: ${({ active, color, theme }) =>
    active ? `${transparentize(0.85, theme[color])}` : theme.backgroundColor};
  box-shadow: none;
  border-width: 2px;
  border-style: solid;

  border-color: ${({ isInvalid, theme }) =>
    isInvalid ? theme.red : 'transparent'};
  ${({ active, disabled, theme }) =>
    !active && disabled && `border-color: ${lighten(0.1, theme.lightGrey)}`};
  ${({ active, color, disabled, theme }) =>
    active && !disabled && `border-color: ${theme[color]}`};
  ${({ active, color, disabled, theme }) =>
    active && disabled && `border-color: ${transparentize(0.6, theme[color])}`};

  transition: all 0.3s ease-in-out;
  color: ${({ disabled, theme }) => (disabled ? theme.grey : theme.black)};

  &:hover {
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    ${({ color, disabled, theme }) =>
      !disabled && `border-color: ${theme[color]};`};
  }
`

export const Description = styled(({ theme, ...props }) => <p {...props} />)`
  color: ${({ theme }) => theme.dark_grey};
`
