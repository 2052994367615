import algoliasearch from 'algoliasearch/lite'
import {
  getOrganizationAlgoliaContactConfig,
  getOrganizationAlgoliaThirdPartyConfig,
} from 'api'
import classNames from 'classnames'
import {
  AlgoliaAutocompleteWidget,
  AlgoliaHighlightWidget,
  FormGroup,
  FormHelper,
  Input,
  Label,
} from 'components'
import { Field, FieldProps, FormikValues, useField } from 'formik'
import { createIntl, useSelector, useTranslation } from 'hooks'
import { FC, FormEvent, useRef, useState } from 'react'
import { SuggestionSelectedEventData } from 'react-autosuggest'
import { Hit } from 'react-instantsearch-core'
import { Configure, InstantSearch } from 'react-instantsearch-dom'
import { useAsync } from 'react-use'
import { selectOrganization } from 'state'
import { uniqId } from 'utils'

import * as Data from './data'
import * as Style from './style'
import * as Type from './type'

const Contact: FC<Type.IPerson> = ({
  className,
  helpText = '',
  label,
  name,
  onSuggestionCleared,
  onSuggestionSelected,
  onInputUpdate,
  redirect = undefined,
  required = false,
  showHelpText = true,
  value,
  isDisabled = false,
  contactType = Type.EContactType.CONTACT,
  excludeStructures = false,
  validate,
  ...rest
}) => {
  const refUniqueId = useRef(uniqId('person'))
  const [defaultRefinement, setDefaultRefinement] = useState<
    undefined | string
  >()

  const intl = createIntl('components_person')
  const translation = useTranslation(intl)
  const organization = useSelector(selectOrganization)
  const [personField] = useField(name)

  const { value: algolia } = useAsync(async () => {
    const config =
      contactType === Type.EContactType.THIRD_PARTY
        ? await getOrganizationAlgoliaThirdPartyConfig(organization.id)
        : await getOrganizationAlgoliaContactConfig(organization.id)
    return {
      client: algoliasearch(config.appId, config.key),
      config,
    }
  })

  const getContactTagFilters = () =>
    `${
      contactType === Type.EContactType.THIRD_PARTY ? 'objectId' : 'entityId'
    }:${personField.value}`

  const getDefaultContact = (
    personId: string,
    hits?: Array<{ [key: string]: string }>
  ) => {
    if (!hits) {
      return null
    }

    return hits.find(
      (hit) =>
        hit[
          contactType === Type.EContactType.THIRD_PARTY
            ? 'objectId'
            : 'entityId'
        ] === personId
    )
  }

  const updateDefaultValue = (personId: string) => {
    if (algolia === undefined) {
      setDefaultRefinement('')
    } else {
      algolia.client
        .search<Hit>([{ indexName: algolia.config.indexName }], {
          'X-Algolia-TagFilters': `[${getContactTagFilters()}`,
        })
        .then((response) => {
          const contact = getDefaultContact(
            personId,
            response?.results[0]?.hits
          )
          if (contact) {
            setDefaultRefinement(
              contactType === Type.EContactType.THIRD_PARTY
                ? contact.name
                : `${contact.firstname} ${contact.lastname}`
            )
            onSuggestionSelected && onSuggestionSelected(contact)
          } else {
            setDefaultRefinement('')
          }
        })
    }
  }

  const renderSuggestion = (hit: Hit) => {
    if (contactType === Type.EContactType.THIRD_PARTY) {
      return <AlgoliaHighlightWidget attribute="name" hit={hit} />
    }
    return (
      <>
        {Data.renderAttributes[contactType].map((attribute) => (
          <Style.OptionAttribute
            className="float-left"
            key={attribute}
            tag={attribute === 'mail' ? 'small' : 'span'}
          >
            <AlgoliaHighlightWidget attribute={attribute} hit={hit} />
          </Style.OptionAttribute>
        ))}
      </>
    )
  }

  const validateInput = (
    fieldValue: string | FormikValues
  ): undefined | string | Promise<undefined | string> => {
    // Required
    if (required && !fieldValue) {
      return translation.translate('validate.required')
    }

    validate && validate(fieldValue)
  }

  if (algolia && defaultRefinement === undefined) {
    updateDefaultValue(personField.value)
  }

  const getUserCreationLink = () => {
    let link = `/organization/contacts/create/${organization.idOld}`
    if (redirect) {
      link += `?redirect=${redirect}`
    }
    return link
  }

  const getNumericFilters = () => {
    if (contactType === Type.EContactType.THIRD_PARTY) {
      return []
    }
    const filters = ['status=1']
    if (excludeStructures) {
      filters.push('isStructure!=1')
    }
    return filters
  }

  if (!algolia || defaultRefinement === undefined) {
    return (
      <Input
        name={name}
        label={label}
        helpText="&nbsp;"
        value=""
        disabled={true}
      />
    )
  }
  return (
    <FormGroup className={classNames(className, 'text-lg-left')}>
      {label && (
        <Label isRequired={required} for={refUniqueId.current}>
          {label}
        </Label>
      )}
      <InstantSearch
        indexName={algolia.config.indexName}
        searchClient={algolia.client}
      >
        <Configure
          attributesToRetrieve={
            Data.algoliaConfig[contactType].attributesToRetrieve
          }
          hitsPerPage={5}
          numericFilters={getNumericFilters()}
          restrictSearchableAttributes={
            Data.algoliaConfig[contactType].searchableAttributes
          }
        />
        <Field
          name={name}
          validate={validateInput}
          children={({ field, form: { setFieldValue } }: FieldProps) => (
            <>
              <AlgoliaAutocompleteWidget
                disabled={isDisabled}
                id={refUniqueId.current}
                noResult={translation.translate('noresult')}
                onSuggestionSelected={async (
                  _event: FormEvent,
                  selectedData: SuggestionSelectedEventData<Hit>
                ) => {
                  setFieldValue(
                    field.name,
                    contactType === Type.EContactType.THIRD_PARTY
                      ? selectedData.suggestion.objectID
                      : selectedData.suggestion.entityId
                  )
                  onSuggestionSelected &&
                    onSuggestionSelected(selectedData.suggestion)
                }}
                onSuggestionCleared={() => {
                  setFieldValue(field.name, '')
                  onSuggestionCleared && onSuggestionCleared()
                }}
                getSuggestionValue={(suggestion: Hit) =>
                  contactType === Type.EContactType.THIRD_PARTY
                    ? suggestion.name
                    : `${suggestion.firstname} ${suggestion.lastname}`
                }
                placeholder={translation.translate('placeholder')}
                renderSuggestion={renderSuggestion}
                defaultRefinement={defaultRefinement}
                name={name}
                onInputUpdate={(inputValue: string) => {
                  setFieldValue(
                    `${name}_contentText`,
                    inputValue.length > 2 ? inputValue : ''
                  )
                }}
                {...rest}
              />

              <FormHelper
                fieldName={field.name}
                {...(showHelpText && {
                  helpText: (
                    <>
                      <span className="mr-2">{helpText}</span>
                      {translation.translate('helpText.notFound', {
                        a: (string: string) => (
                          <a href={getUserCreationLink()} key="helpTextLink">
                            {string}
                          </a>
                        ),
                      })}
                    </>
                  ),
                })}
              />
            </>
          )}
        />
      </InstantSearch>
      <Field
        name={`${name}_contentText`}
        children={({ field }: FieldProps) => <input type="hidden" {...field} />}
      />
    </FormGroup>
  )
}

export default Contact
