import { FC } from 'react'
import { EColor } from 'types'

import * as Style from './style'
import * as Type from './type'

const Svg: FC<Type.ISvg> = ({
  color = EColor.GREY,
  colorSecondary = EColor.GREEN,
  height,
  width = '10em',
  version,
  src,
  ...rest
}) => {
  const heightCalc = () => (height ? height : width)
  const svgVersion = version ? `?v=${version}` : ''
  return (
    <Style.Svg
      height={heightCalc()}
      src={`${process.env.REACT_APP_CDN_HOST}/${src}.svg${svgVersion}`}
      fallback={() => {
        console.error(`"${src}" failed to load.`)
        return <></>
      }}
      color={color}
      colorSecondary={colorSecondary}
      width={width}
      {...rest}
    />
  )
}

export default Svg
