import { Dinero } from 'dinero.js'
import { useIntl } from 'react-intl'

import * as Type from './type'

const getMonthsAllocation = (monthsNumber: number) => {
  const allocationShares = []
  for (let i = 0; i < monthsNumber; i++) {
    allocationShares.push(100 / monthsNumber)
  }
  return allocationShares
}

const UseInstallment = (): Type.IUseInstallment => {
  const intl = useIntl()

  const createAllocationPlan = (
    dinero: Dinero,
    installmentDetails: null | {
      installmentNbMonths: string | number
      installmentNbPayments: string | number
    }
  ): Type.IInstallmentPlan => {
    if (!installmentDetails) {
      return []
    }
    // Create an array of months allocation division (e.g, [50, 50] for 2 months)
    const monthsAllocation = getMonthsAllocation(
      parseInt(installmentDetails.installmentNbPayments.toString())
    )

    // Create the dinero division (e.g [10€, 10€] for 2 months division for 20 EUR)
    const dineroDivision = dinero
      .setLocale(intl.locale)
      .allocate(monthsAllocation)

    // Create the date plan (e.g [01/01/2022, 01/02/2022] for 2 months from 01/01/2022)
    let lastMonthAllocationDate = new Date()
    const datePlan: Date[] = []
    while (datePlan.length < dineroDivision.length) {
      datePlan.push(lastMonthAllocationDate)
      const allocationDate = new Date(lastMonthAllocationDate)
      allocationDate.setMonth(
        lastMonthAllocationDate.getMonth() +
          parseInt(installmentDetails.installmentNbMonths.toString())
      )
      lastMonthAllocationDate = allocationDate
    }

    // Return the dineroDivision merged with the datePlan
    return dineroDivision.map((monthDinero, index) => ({
      date: datePlan[index],
      dinero: monthDinero,
    }))
  }

  return { createAllocationPlan }
}

export default UseInstallment
