import { createContext } from 'react'

import { IApplicationsContext } from './type'

const ApplicationsContext = createContext<IApplicationsContext>({
  // TO DO SIMON: update this default value to set with undefined values + create a type with loaded values for pages
  applications: {
    list: [],
    iri: '',
  },
  loading: false,
  retry: () => undefined,
})

export default ApplicationsContext
