import { EApiCrmPersonRole } from 'api/type'
import { createIntl, usePerson, useSelector, useTranslation } from 'hooks'
import { useMemo } from 'react'
import { selectOrganization } from 'state'
import Cookies from 'universal-cookie'
import { getMillisecondsInDays } from 'utils'

import * as Type from './type'

const UseTourData = (): Type.IUseTourData => {
  const intl = createIntl('hooks_usetourdata')
  const translation = useTranslation(intl)
  const organization = useSelector(selectOrganization)
  const { hasRole } = usePerson()
  const organizationCreationDate = useMemo(
    () => organization.createdAt,
    [organization.createdAt]
  )

  const getSelector = (key: string) => `[data-tour="tour-${key}"]`

  const getContent = (key: string) => (
    <div className="position-relative pb-3">
      {translation.translate(`tour.${key}`)}
    </div>
  )

  const getSteps = () => {
    const steps = ['menu', 'helpButton']
    if (
      hasRole(EApiCrmPersonRole.ACCOUNTING_WRITE) ||
      hasRole(EApiCrmPersonRole.ORGANIZATION_WRITE)
    ) {
      steps.splice(1, 0, 'settings')
    }
    return steps.map((key) => ({
      selector: getSelector(key),
      content: getContent(key),
    }))
  }

  const setTourCookie = (cookieName: string, expiration = 30): void => {
    const expires = new Date()
    expires.setTime(expires.getTime() + getMillisecondsInDays(expiration)) // expiration in days
    const cookies = new Cookies()
    cookies.set(cookieName, true, { path: '/', expires })
  }

  const isOnboardingDisplayable = () =>
    new Date(Date.now() - 90 * 24 * 60 * 60 * 1000) <= //Three months ago
    new Date(organizationCreationDate)

  return {
    getSteps,
    isOnboardingDisplayable,
    setTourCookie,
  }
}

export default UseTourData
