import Icon from 'components/Icon'
import { useTranslation } from 'hooks'
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { EColor } from 'types'

import * as Style from './style'
import * as Type from './type'

const HelperIcon: FC<Type.IHelperIcon> = ({
  backgroundColor = EColor.GREEN,
  textColor = EColor.WHITE,
  size,
  withText = false,
  ...props
}) => {
  const intl = useIntl()
  const translation = useTranslation(intl)

  return (
    <Style.HelperIconWrapper
      backgroundColor={backgroundColor}
      size={size}
      withText={withText}
      {...props}
    >
      {withText ? (
        translation.translate('helper.icon.textContent')
      ) : (
        <Icon
          icon="question"
          color={textColor}
          size={size === 'md' ? 'xs' : '1x'}
        />
      )}
    </Style.HelperIconWrapper>
  )
}

export default HelperIcon
