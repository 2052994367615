import { Background, Label as DefaultLabel } from 'components'
import styled from 'styled-components'

import { CheckedStyle } from '../CheckboxCard/style'

export const RadioCard = styled((props) => <Background {...props} />)`
  cursor: pointer;
  border: 1px solid transparent;
  ${({ checked }) => checked && CheckedStyle};
`

export const Label = styled((props) => <DefaultLabel {...props} />)`
  cursor: pointer;
`
