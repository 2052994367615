import { API } from 'api/connector'
import { FormikValues } from 'formik'

import * as Type from './type'

export const setCollectDuplicate = async (
  collectId: Type.ICollect['@id'],
  params?: Type.IDuplicateParams
): Promise<{ collectId: Type.ICollect['@id'] }> => {
  const duplicatedCollect = await API.post(
    `/api/v1/collects/${collectId}/duplicate`,
    {
      ...params,
    }
  )
  return duplicatedCollect.data
}

export const getCollect = async (
  collectId: Type.ICollect['id']
): Promise<Type.ICollect> => {
  const collect = await API.get(`/api/v1/collects/${collectId}`)
  return collect.data
}

export const getCollectExtraData = async (
  collectId: Type.ICollect['id']
): Promise<Type.ICollectExtraData> => {
  const collectExtraData = await API.get(
    `/api/v1/collects/${collectId}/extra_data`
  )
  return collectExtraData.data
}

export const updateCollectStatus = async (
  collectId: Type.ICollect['@id'],
  status: Type.ECollectStatus
): Promise<Type.ICollect> => {
  const collect = await API.put(`/api/v1/collects/${collectId}`, { status })
  return collect.data
}

export const sendCollectDonation = async (
  collectId: Type.ICollect['id'],
  params: FormikValues
): Promise<Type.ISendCollectDonation> => {
  const collectDonation = await API.post(
    '/api/v1/donation-funnel/process?iframe=1',
    {
      collectId,
      ...params,
    }
  )
  return collectDonation.data
}
