import classNames from 'classnames'
import { Icon } from 'components'
import { createIntl, useTranslation } from 'hooks'
import { FC } from 'react'

import * as Style from './style'
import * as Type from './type'

const CloseButton: FC<Type.ICloseButton> = ({
  className,
  onClick,
  ...rest
}) => {
  const intl = createIntl('components_closebutton')
  const translation = useTranslation(intl)
  return (
    <Style.Button
      type="button"
      className={classNames('close align-middle d-inline-block', className)}
      aria-label={translation.translate('label')}
      onClick={onClick}
      role="button"
    >
      <Icon className="align-middle" icon="times" {...rest} />
    </Style.Button>
  )
}

export default CloseButton
