import { IconProp } from '@fortawesome/fontawesome-svg-core'
import classNames from 'classnames'
import { Col, Icon, Row } from 'components'
import { FC, useState } from 'react'
import { EColor, ESize } from 'types'

import * as Style from './style'
import * as Type from './type'

const getAlertIcon = (color?: Type.IAlertColor): null | IconProp => {
  switch (color) {
    case EColor.RED:
      return ['far', 'times']
    case EColor.BLUE:
      return ['fas', 'info']
    case EColor.GREEN:
      return ['far', 'check']
    case EColor.FLORIDA:
      return ['fas', 'exclamation']
    default:
      return null
  }
}

const Alert: FC<Type.IAlert> = ({
  alignMiddle = false,
  children,
  color = EColor.BLUE,
  dismissible = false,
  handleClose,
  icon = null,
  outline = true,
  title,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(true)
  const toggleProp = dismissible
    ? {
        toggle: () => {
          setIsOpen(false)
          handleClose && handleClose()
        },
      }
    : {}
  const alertIcon: null | IconProp = icon || getAlertIcon(color)
  const content = (
    <>
      {title && <Style.AlertTitle>{title}</Style.AlertTitle>}
      {children}
    </>
  )

  return (
    <Style.Alert
      color={color}
      outline={outline}
      isOpen={isOpen}
      {...toggleProp}
      {...rest}
    >
      {alertIcon ? (
        <Row
          className={classNames(`pr-4`, alignMiddle && 'align-items-center')}
        >
          <Col xs="auto" className="pr-0">
            <Style.IconWrapper color={color} outline={outline}>
              <Icon
                className="d-flex"
                color={outline ? EColor.WHITE : color}
                icon={alertIcon}
                size={ESize.SM}
              />
            </Style.IconWrapper>
          </Col>
          <Style.ContentCol>{content}</Style.ContentCol>
        </Row>
      ) : (
        content
      )}
    </Style.Alert>
  )
}

export default Alert
