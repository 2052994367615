export interface IApiThirdParty {
  '@id': string
  name: string
  number: string
  type: EThirdPartyType
  isArchived: boolean
}

export enum EThirdPartyType {
  CLIENT = 'CLIENT',
  SUPPLIER = 'SUPPLIER',
  CONTACT = 'CONTACT',
}
