import { ApplicationsContext } from 'providers'
import { useContext } from 'react'

import * as Type from './type'

const useApplications = (): Type.IUseApplications => {
  const { applications } = useContext(ApplicationsContext)

  const isApplicationEnabled = (searchedApplication: string): boolean =>
    applications.list.filter(
      (application) => application.name === searchedApplication
    )[0]?.isEnabled || false

  const isApplicationAvailable = (searchedApplication: string): boolean =>
    !!applications.list.filter(
      (application) => application.name === searchedApplication
    ).length

  return {
    isApplicationEnabled,
    isApplicationAvailable,
  }
}

export default useApplications
