import { Button } from 'components'
import styled from 'styled-components'

export const ToggleButton = styled(({ borderLeft, ...props }) => (
  <Button {...props} />
))`
  outline: none;
  box-shadow: none !important;
  border-left: 1px solid
    ${({ borderLeft, ...props }) =>
      borderLeft ? props.theme.lightGrey : 'transparent'};
  border-radius: 0;
`
