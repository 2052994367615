import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip as TooltipComponent } from 'components'
import styled from 'styled-components'

export const Icon = styled((props) => <FontAwesomeIcon {...props} />)`
  color: ${({ theme, color }) => theme[color]};
`

export const Tooltip = styled((props) => <TooltipComponent {...props} />)`
  @media (min-width: ${(props) => props.theme['gridBreakpoints-lg']}) {
    display: none;
  }
`

export const TooltipContainer = styled((props) => <div {...props} />)`
  display: flex;
`

export const TooltipText = styled((props) => <div {...props} />)`
  display: none;
  @media (max-width: ${(props) => props.theme['gridBreakpoints-lg']}) {
    display: inline;
    margin-left: 0.3rem;
  }
`
