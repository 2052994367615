import { Button, Link } from 'components'
import { FC } from 'react'
import { EColor } from 'types'

import * as Style from './style'
import * as Type from './type'

const PopoverFooter: FC<Type.IFooter> = ({ ctas }) => (
  <>
    {ctas && (
      <Style.Footer className="popover-footer text-center">
        {Object.entries(ctas).map((cta, index) => {
          const linkProperties: Type.ILinkProperties = {}
          const buttonProperties: Type.IButtonProperties = {}
          if (cta[1]?.href) {
            linkProperties.href = cta[1]?.href
          }
          if (cta[1]?.onClick) {
            linkProperties.onClick = cta[1]?.onClick
          }
          if (cta[1]?.disabled) {
            buttonProperties.disabled = true
          }
          return (
            <Link key={index} {...linkProperties}>
              <Button
                className="text-uppercase mb-2"
                color={cta[0] === 'primary' ? EColor.BLUE : EColor.TRANSPARENT}
                {...buttonProperties}
              >
                {cta[1]?.label}
              </Button>
            </Link>
          )
        })}
      </Style.Footer>
    )}
  </>
)

export default PopoverFooter
