import classNames from 'classnames'
import { Icon, Link } from 'components'
import { createIntl, useTranslation } from 'hooks'
import { FC } from 'react'

import * as Style from './style'
import * as Type from './type'

const BackLink: FC<Type.IBackLink> = ({ href, className, ...rest }) => {
  const intl = createIntl('components_backlink')
  const translation = useTranslation(intl)

  return (
    <>
      {href && (
        <Link
          href={href}
          className={classNames([
            'd-inline-flex flex-column justify-content-center',
            className,
          ])}
          preventQueryParameter={true}
          {...rest}
        >
          <Icon icon={['fal', 'long-arrow-left']} size="2x" />
          <Style.Text>{translation.translate('backlink.text')}</Style.Text>
        </Link>
      )}
    </>
  )
}

export default BackLink
