import { FormFeedback } from 'components'
import { FormikValues, getIn, useFormikContext } from 'formik'
import { FC } from 'react'
import { EColor } from 'types'

import * as Style from './style'
import * as Type from './type'

export const FormHelper: FC<Type.IFormHelper> = ({
  color = EColor.DARK_GREY,
  fieldName,
  helpText,
  error,
}) => {
  const { errors, touched } = useFormikContext<FormikValues>()
  const invalid = getIn(errors, fieldName) && getIn(touched, fieldName)

  return (
    <div className="pl-1">
      {helpText && !invalid && (
        <Style.FormText color={color}>{helpText}</Style.FormText>
      )}
      {(!!error ||
        (invalid && typeof getIn(errors, fieldName) === 'string')) && (
        <FormFeedback className="d-block">
          {error || String(getIn(errors, fieldName))}
        </FormFeedback>
      )}
    </div>
  )
}

export default FormHelper
