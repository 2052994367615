import styled from 'styled-components'

export const AlgoliaAutocompleteWidgetContainer = styled.div`
  background-color: ${(props) => props.theme.white};
  z-index: 111;
  width: 80%;
  @media (min-width: ${(props) => props.theme['gridBreakpoints-md']}) {
    width: 60%;
  }
`

export const AlgoliaAutocompleteWidget = styled((props) => <div {...props} />)`
  div[role='listbox'] {
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 0 1px 3px 0 ${(props) => props.theme.lightGrey};
    margin-top: 0.357rem;
    padding: 0;
    width: calc(100% - 25px);

    li {
      border-radius: 0;
      border: 0;
      background-color: ${(props) => props.theme.white};
      transition: background-color 0.25s ease;

      &:hover,
      &[aria-selected='true'] {
        background-color: ${(props) => props.theme.lightGrey};
        cursor: pointer;
      }
    }
  }
`

export const AlgoliaAutocompleteWidgetNoResult = styled.div`
  padding: 0.75rem 1.25rem;
`
