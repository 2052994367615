import {
  useApplications,
  usePerson,
  useSelector,
  useSpecifications,
} from 'hooks'
import { ERefusalReason } from 'hooks/useSpecifications/type'
import { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { selectOrganization, selectPerson } from 'state'

import * as Type from './type'

const useHasAccess = ({
  app,
  roles,
  requiredSpecifications,
}: Type.AccessParams) => {
  const [access, setAccess] = useState<Type.AccessResult>({
    hasAccess: undefined,
    accessDenied: undefined,
    isLoaded: false,
  })

  const organization = useSelector(selectOrganization)
  const { hasRole, isLoaded: isRoleLoaded } = usePerson()
  const { isApplicationEnabled } = useApplications()
  const person = useSelector(selectPerson)
  const { getSpecifications } = useSpecifications()

  const location = useLocation()

  // clear access state on page change
  useEffect(
    () => () => {
      setAccess({
        hasAccess: undefined,
        accessDenied: undefined,
        isLoaded: false,
      })
    },
    [location.pathname]
  )

  const checkAccess = useCallback(async () => {
    if (!isRoleLoaded || !organization || !person) {
      return undefined
    }

    // roles
    if (roles && !roles?.some((role) => hasRole(role))) {
      return setAccess({
        hasAccess: false,
        accessDenied: { reason: ERefusalReason.UNAUTHORIZED },
        isLoaded: true,
      })
    }

    // app
    if (app && !isApplicationEnabled(app)) {
      return setAccess({
        hasAccess: false,
        accessDenied: { reason: ERefusalReason.APP_DISABLED },
        isLoaded: true,
      })
    }

    // specifications
    const specifications =
      requiredSpecifications &&
      (await getSpecifications(requiredSpecifications, organization, person))

    if (
      specifications &&
      !Object.values(specifications).every(
        (specification) => specification.isValid
      )
    ) {
      return setAccess({
        hasAccess: false,
        accessDenied: {
          reason:
            Object.values(specifications)[0].refusalReason ??
            ERefusalReason.SETTING,
        },
        isLoaded: true,
      })
    }

    return setAccess({
      hasAccess: true,
      accessDenied: null,
      isLoaded: true,
    })
  }, [
    app,
    isRoleLoaded,
    hasRole,
    roles,
    requiredSpecifications,
    organization,
    isApplicationEnabled,
    getSpecifications,
  ])

  useEffect(() => {
    if (
      access.isLoaded ||
      !organization ||
      !Object.values(person.roles).length
    ) {
      return
    }

    checkAccess()
  }, [checkAccess, access.isLoaded, organization, person.roles])

  return access
}

export default useHasAccess
