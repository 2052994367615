import { Button, Icon } from 'components'
import { FC } from 'react'
import { EColor } from 'types'

import * as Type from './type'

const DownloadButton: FC<Type.IDownloadButton> = ({ label, ...rest }) => (
  <Button color={EColor.BLUE} {...rest}>
    <Icon icon={['fas', 'download']} color={EColor.WHITE} className="mr-2" />
    {label}
  </Button>
)

export default DownloadButton
