import { EApiCrmPersonRole } from 'api/type'
import { EmptySpace } from 'components'
import { createIntl, usePerson, useTranslation } from 'hooks'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { selectOrganization } from 'state'
import { EYado } from 'types'

import * as Type from './type'

const EmptySpaceAccessDenied: FC<Type.IEmptySpaceAccessDenied> = ({
  missingApplication,
}) => {
  const intl = createIntl('components_emptyspace_accessdenied')
  const translation = useTranslation(intl)
  const organization = useSelector(selectOrganization)
  const { hasRole } = usePerson()

  const emptySpaceProps: { [key: string]: { [key: string]: string } } = {}
  if (missingApplication) {
    emptySpaceProps['button'] = {
      href: `/organization/${organization.idOld}/applications/${missingApplication}`,
      text: translation.translate('enableApplication'),
    }
  }

  return (
    <EmptySpace
      message={translation.translate(
        hasRole(EApiCrmPersonRole.GENERAL_ADMIN)
          ? 'messageForMainAdmin'
          : 'message'
      )}
      title={translation.translate('title')}
      yado={EYado.ACCESS_DENIED}
      {...emptySpaceProps}
    />
  )
}

export default EmptySpaceAccessDenied
