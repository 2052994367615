import { Button as ReactButton } from 'components'
import { darken } from 'polished'
import styled from 'styled-components'

export const Button = styled((props) => <ReactButton {...props} />)`
  &.btn-publicMainColor {
    color: ${(props) => props.theme.white};

    &:hover {
      background-color: ${(props) =>
        darken(0.05, props.theme['publicMainColor'])};
    }
  }
  &.btn-outline-publicMainColor {
    color: ${(props) => props.theme['publicMainColor']};
    border-color: ${(props) => props.theme['publicMainColor']};
    background-color: transparent;

    &:hover {
      color: ${(props) => props.theme.white};
      background-color: ${(props) => props.theme['publicMainColor']};
    }
  }
`
