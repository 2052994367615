import { IUseTranslation } from 'hooks/useTranslation/type'

import { IRowData, ITable } from './type'

export const toggleRow = (
  isChecking: boolean,
  rows: Array<IRowData>,
  selectedRows: Array<IRowData>
): Array<IRowData> => {
  if (isChecking) {
    return [...selectedRows, ...rows]
  } else {
    return selectedRows.filter(
      (selectedRow: IRowData) =>
        !rows.some((row: IRowData) => row['@id'] === selectedRow['@id'])
    )
  }
}

export const getBatchType = (
  batch: ITable['batch'],
  selectedRows: Array<IRowData>,
  translation: IUseTranslation
): string =>
  batch
    ? translation.translate(`table.batch.${batch?.type}`, {
        count: selectedRows.length,
      })
    : ''
