import { Input } from 'components'
import { FormikValues, useFormikContext } from 'formik'
import { createIntl, useTranslation } from 'hooks'
import { ChangeEvent, FC } from 'react'
import {
  electronicFormatIBAN,
  friendlyFormatIBAN,
  isValidBIC,
  isValidIBAN,
} from 'utils'

import * as Type from './type'

const BankAccountIban: FC<Type.IIban> = ({
  disabled = false,
  required = false,
  name,
  ...props
}) => {
  const intl = createIntl('components_bankaccount')
  const translation = useTranslation(intl)

  const { values, setFieldValue } = useFormikContext<FormikValues>()

  const getIbanMaxLength = (value: string) => {
    const countryShortCode = value.slice(0, 2)

    switch (countryShortCode) {
      case 'BE':
        return 19
      case 'BR':
        return 36
      case 'CR':
        return 27
      case 'FR':
        return 33
      case 'IE':
        return 27
      case 'DE':
        return 27
      case 'GR':
        return 33
      case 'MU':
        return 37
      case 'PK':
        return 29
      case 'PL':
        return 34
      case 'PT':
        return 31
      case 'RO':
        return 29
      case 'LC':
        return 39
      case 'SA':
        return 29
      case 'SK':
        return 29
      case 'ES':
        return 29
      case 'SE':
        return 29
      case 'CH':
        return 26
      case 'GB':
        return 27
      default:
        return 41
    }
  }

  const validateIban = async (
    value: FormikValues
  ): Promise<undefined | string> =>
    value.length && !isValidIBAN(electronicFormatIBAN(String(value)))
      ? translation.translate('iban.validate.incorrect')
      : ''

  const formatIban = (event: ChangeEvent<HTMLInputElement>): void => {
    const iban = friendlyFormatIBAN(event.target.value)
    setFieldValue(event.target.name, iban)
  }

  const validateBic = (value: FormikValues): undefined | string => {
    if (value.length && !isValidBIC(String(value))) {
      return translation.translate('bic.validate.incorrect')
    }
  }

  return (
    <div {...props}>
      <Input
        disabled={disabled}
        name={`${name}_iban`}
        label={translation.translate('iban.label')}
        onChange={formatIban}
        placeholder={translation.translate('iban.placeholder')}
        required={required || values[`${name}_bic`]}
        validate={validateIban}
        maxLength={getIbanMaxLength(values[`${name}_iban`])}
      />
      <Input
        disabled={disabled}
        name={`${name}_bic`}
        label={translation.translate('bic.label')}
        placeholder={translation.translate('bic.placeholder')}
        required={required || values[`${name}_iban`]}
        validate={validateBic}
      />
    </div>
  )
}

export default BankAccountIban
