import { createIntl, useTranslation } from 'hooks'
import { ReactNode } from 'react'

const UseAdyenTranslationError = () => {
  const intl = createIntl('hooks_useadyentranslationerror')
  const translation = useTranslation(intl)

  const getAdyenError = (
    error: {
      code: number
      message: string
    } | null,
    isBalancePlatform = false
  ): ReactNode | null => {
    if (!error) {
      return null
    }

    const errorCode = `${
      isBalancePlatform ? 'balancePlatform' : 'classic'
    }.${error.code.toString()}`

    return intl.messages[errorCode]
      ? translation.translate(errorCode)
      : translation.translate('other', {
          code: error.code,
          message: error.message,
        })
  }
  return { getAdyenError }
}

export default UseAdyenTranslationError
