import { FC } from 'react'
import { UncontrolledTooltip as ReactTooltip } from 'reactstrap'

import * as Type from './type'

const Tooltip: FC<Type.ITooltip> = ({ autohide = false, ...props }) => (
  <div className="d-inline">
    <ReactTooltip autohide={autohide} {...props} />
  </div>
)

export default Tooltip
