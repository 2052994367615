import { Button, Col, Link, Notification, Row } from 'components'
import { useSelector } from 'hooks'
import { FC } from 'react'
import { selectNotifications } from 'state'
import { EColor } from 'types'

import * as Style from './style'

const Notifications: FC = ({ ...rest }) => {
  const notifications = useSelector(selectNotifications)
  return (
    <>
      {notifications && (
        <Style.NotificationWrapper {...rest}>
          {notifications.map((notification) => (
            <Notification
              color={notification.color}
              dismissible={notification.dismissible}
              id={notification.id}
              key={notification.id}
              onCloseCallback={notification.onCloseCallback}
            >
              <Row className="align-items-center">
                <Col>
                  {notification.text && (
                    <>
                      {Array.isArray(notification.text)
                        ? notification.text.map((text, textIndex) => (
                            <span key={textIndex}>{text}</span>
                          ))
                        : notification.text}
                    </>
                  )}
                </Col>
                {notification.cta && (
                  <Col md="auto" className="pr-4 pt-3 pt-md-0">
                    <Link
                      href={notification.cta.href}
                      target={notification.cta.target || '_self'}
                    >
                      <Button color={EColor.WHITE} outline={true}>
                        {notification.cta.text}
                      </Button>
                    </Link>
                  </Col>
                )}
              </Row>
            </Notification>
          ))}
        </Style.NotificationWrapper>
      )}
    </>
  )
}

export default Notifications
