import { Col, Row } from 'components'
import styled from 'styled-components'

export const ResponsiveButtonsRow = styled(({ isArchived, ...props }) => (
  <Row {...props} />
))`
  border-top: 1px solid
    ${({ isArchived, ...props }) =>
      isArchived ? props.theme.grey : props.theme.lightGrey};
`
export const ResponsiveDetailsCol = styled(({ isFirst, ...props }) => (
  <Col {...props} />
))`
  @media (max-width: ${(props) => props.theme['gridBreakpoints-xl']}) {
    border-top: ${({ isFirst, ...props }) =>
      !isFirst ? `1px solid ${props.theme.lightGrey}` : 0};
  }
`
