import classNames from 'classnames'
import { useSelector } from 'hooks'
import { FC, ReactNode } from 'react'
import { selectLoader } from 'state'
import { EColor } from 'types'

import * as Style from './style'
import * as Type from './type'

export const LoaderSpinner: FC<{
  color?: EColor
  text?: ReactNode
  size?: number
  isRelative?: boolean
}> = ({ color, text, size, isRelative }) => (
  <div
    className={
      isRelative
        ? 'd-inline-block mr-2'
        : 'd-flex align-items-center justify-content-center h-100 flex-column text-center'
    }
  >
    <Style.Loader
      color={color}
      size={size}
      className={classNames(isRelative && 'mx-auto')}
    />
    {!!text && <div className="mt-3">{text}</div>}
  </div>
)

const Loader: FC<Type.ILoader> = ({
  color = EColor.BLUE,
  isRelative = false,
  text,
  size = 15,
  ...rest
}) => {
  const loader = useSelector(selectLoader)

  if (isRelative) {
    return (
      <LoaderSpinner
        color={color}
        isRelative={isRelative}
        text={text}
        size={size}
      />
    )
  }
  return (
    <>
      {loader.display && (
        <Style.LoaderWrapper {...rest}>
          <LoaderSpinner color={color} text={text || loader.text} />
        </Style.LoaderWrapper>
      )}
    </>
  )
}

export default Loader
