import { API } from 'api/connector'
import { IApiOrganization } from 'api/type'

import * as Type from './type'

export const getProAccountAccountHolder = async (
  organizationId: IApiOrganization['id']
): Promise<Type.IApiProAccountAccountHolder> => {
  const proAccountAccountHolder = await API.get(
    `/api/v1/organizations/${organizationId}/pro_account_account_holder`
  )
  return proAccountAccountHolder.data
}
