import classNames from 'classnames'
import { FC } from 'react'

import { EStepType } from '../type'
import * as Style from './style'
import * as Type from './type'

const Steps: FC<Type.IStepVisualization> = ({ steps, currentStep }) => {
  const currentStepData = steps[currentStep]

  const hasIntroduction = !!steps.find(
    (step) => step?.type === EStepType.INTRODUCTION
  )

  const current = hasIntroduction ? currentStep - 1 : currentStep

  const filteredSteps = steps.filter((step) => step?.type === EStepType.STEP)

  // "regularStep" => is not intro nor conclusion
  const isRegularStep = currentStepData?.type === EStepType.STEP

  if (currentStepData === undefined) {
    return <></>
  }

  return (
    <Style.MainContainer isRegularStep={isRegularStep}>
      <Style.StepperVisualizationContainer
        isRegularStep={isRegularStep}
        currentStep={current}
        stepsLength={filteredSteps.length}
        className={isRegularStep ? 'isRegularStep' : 'isNotRegularStep'}
      >
        {/* Introduction / conclusion display */}
        {!isRegularStep && (
          <>
            {currentStepData.yado && (
              <>
                <Style.Yado
                  width="30rem"
                  src={`common/yado/${currentStepData.yado}`}
                  className="d-lg-inline-block mx-auto mt-5"
                />
              </>
            )}
            {currentStepData.title &&
              currentStepData.type !== EStepType.INTRODUCTION && (
                <div>
                  <strong>{currentStepData.title}</strong>
                </div>
              )}
          </>
        )}

        {/* step display */}
        {isRegularStep && (
          <Style.StepperVisualization steps={steps}>
            {filteredSteps.map((step, index) => (
              <Style.StepContainer
                index={index}
                key={step.title}
                current={current}
                active={current > index}
              >
                <div className="step">
                  <div
                    className={classNames(
                      'stepRound',
                      current > index && 'active',
                      current === index && 'current'
                    )}
                  >
                    {index <= current - 1 ? '✓' : index + 1}
                  </div>
                  {step.title && (
                    <div
                      className={classNames(
                        'stepTitle px-1 text-center text-md-left pl-md-3',
                        current > index && 'active',
                        current === index && 'current'
                      )}
                    >
                      {step.title}
                    </div>
                  )}
                </div>
              </Style.StepContainer>
            ))}
          </Style.StepperVisualization>
        )}
      </Style.StepperVisualizationContainer>
    </Style.MainContainer>
  )
}

export default Steps
