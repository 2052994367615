import classNames from 'classnames'
import { Icon } from 'components'
import { FC } from 'react'
import { EColor, EListType } from 'types'

import * as Style from './style'
import * as Type from './type'

const ListItem: FC<Type.IListItem> = ({
  children,
  className,
  color = EColor.GREEN,
  isTimeline = false,
  type = EListType.BULLET,
  ...rest
}) => (
  <Style.ListItem
    className={classNames(
      'mt-2 list-unstyled',
      {
        'pl-4': type === EListType.BULLET,
      },
      className
    )}
    color={color}
    isTimeline={isTimeline}
    type={type}
    {...rest}
  >
    {type === EListType.CHECK && (
      <Icon
        icon={['fas', 'check-circle']}
        color={color}
        className="mr-2 mt-1"
      />
    )}
    {type === EListType.HEART && (
      <Icon icon={['fas', 'heart']} color={color} className="mr-2 mt-1" />
    )}
    {children}
  </Style.ListItem>
)

export default ListItem
