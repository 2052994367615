import { useTranslation } from 'hooks'
import { FC } from 'react'
import { useIntl } from 'react-intl'

import * as Style from './style'
import * as Type from './type'

const TableButton: FC<Type.ITableButton> = ({
  tag,
  value,
  selectedRows,
  className,
}) => {
  const intl = useIntl()
  const translation = useTranslation(intl)

  const isDisabled =
    !!selectedRows.length &&
    // if see action, allow click
    (Array.isArray(value.icon)
      ? !value.icon?.some((iconValue: string) => iconValue === 'eye')
      : value.icon !== 'eye')

  return (
    <div>
      <Style.TableButton
        tag={tag}
        disabled={isDisabled}
        className={className}
        {...value}
      >
        {isDisabled && tag === 'ResponsiveButton'
          ? translation.translate('tooltip.button.disabled')
          : value.text}
      </Style.TableButton>
    </div>
  )
}

export default TableButton
