import { Background } from 'components'
import styled, { keyframes } from 'styled-components'

const loading = keyframes`
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(100%);
  }
`
export const Skeleton = styled(({ color, minHeight, ...props }) => (
  <Background {...props} />
))`
  position: relative;
  padding: 0;
  overflow: hidden;
  background: ${({ color, theme }) => theme[color]};
  min-height: ${({ minHeight }) => minHeight}px;
  box-shadow: 0 1px 3px 0 ${({ color, theme }) => theme[color]};

  &:after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.2),
      transparent
    );
    animation: ${loading} 2s infinite;
  }
`
