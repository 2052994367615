import { API } from 'api/connector'

import { IApiProAccountAccountHolder } from '../holder/type'
import * as Type from './type'

export const getProAccountPayments = async (
  id: IApiProAccountAccountHolder['account']['id']
): Promise<{
  data: Array<Type.IApiProAccountPayments>
  totalItems: number
}> => {
  const proAccountPayments = await API.get(
    `/api/v1/pro_account_accounts/${id}/payments`
  )
  return {
    data: proAccountPayments.data['hydra:member'],
    totalItems: proAccountPayments.data['hydra:totalItems'],
  }
}

export const getProAccountCheckPayments = async (
  id: Type.IApiProAccountPayments['id']
): Promise<Type.IApiProAccountPayments> => {
  const proAccountCheckPayments = await API.get(
    `/api/v1/pro_account_check_payments/${id}`
  )
  return proAccountCheckPayments.data
}
