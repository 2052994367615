import classNames from 'classnames'
import { FC } from 'react'

import * as Style from './style'
import * as Type from './type'

const Progress: FC<Type.IProgress> = ({ value, barClassName, ...rest }) => (
  <Style.Progress
    value={value}
    barClassName={classNames(
      'text-right font-weight-bold',
      { 'px-3': value && value > 0 },
      barClassName
    )}
    {...rest}
  >
    {!!value && `${value}%`}
  </Style.Progress>
)

export default Progress
