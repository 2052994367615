import { Background as BackgroundComponent, Button, Col } from 'components'
import styled from 'styled-components'

export const Background = styled((props) => <BackgroundComponent {...props} />)`
  .react-select__multi-value {
    z-index: 886 !important;
  }
`

export const CollapseButton = styled((props) => <Button {...props} />)`
  min-width: 130px;
`

export const MainFilterCol = styled((props) => <Col {...props} />)`
  .form-group {
    margin-bottom: 0;
  }
`

export const BadgeWrapper = styled((props) => <div {...props} />)`
  line-height: 32px;
`
