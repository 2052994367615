import classNames from 'classnames'
import { Svg } from 'components'
import { useSelector, useStore } from 'hooks'
import { FC } from 'react'
import { selectPage } from 'state'
import { EColor } from 'types'

import HeaderBacklink from './BackLink'
import HeaderNavigation from './Navigation'
import * as Style from './style'
import * as Type from './type'

const Header: FC<Type.IHeader> = ({
  className,
  hasNavigation = true,
  fullWidthStyle = false,
  isMenuHidden = false,
  title,
  ...rest
}) => {
  const page = useSelector(selectPage)
  const store = useStore()

  return (
    <Style.Header
      tag="header"
      role="banner"
      fullWidthStyle={fullWidthStyle}
      isMenuHidden={isMenuHidden}
    >
      <div
        className={classNames([
          'd-none d-md-flex align-items-center py-3 position-relative',
          className,
        ])}
        {...rest}
      >
        {page.backLinkHref && (
          <HeaderBacklink href={page.backLinkHref} className="mr-3" />
        )}
        {isMenuHidden && (
          <Svg src="common/logo/logo-simple" color={EColor.BLUE} width="35px" />
        )}
        <Style.PageTitle className="my-0 lh-1">
          {title || page.title || ' '}
          {page.subtitle && (
            <small className="text-muted ml-3">{page.subtitle}</small>
          )}
        </Style.PageTitle>
        {store.isLoaded && hasNavigation && <HeaderNavigation />}
      </div>
    </Style.Header>
  )
}

export default Header
