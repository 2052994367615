import { API } from 'api/connector'

import { IApiFeatureFlag } from './type'

export const getFeatureFlags = async (
  organizationId: string
): Promise<{ data: Array<IApiFeatureFlag>; totalItems: number }> => {
  const featureFlags = await API.get(
    `/api/v1/organizations/${organizationId}/feature_flags`
  )
  return {
    data: featureFlags.data['hydra:member'],
    totalItems: featureFlags.data['hydra:totalItems'],
  }
}
