import { Input } from 'components'
import { INumeric } from 'components/Form/Numeric/type'
import { FormikValues } from 'formik'
import { createIntl, useTranslation } from 'hooks'
import { FC } from 'react'

const SocialSecurityNumberLast4Digits: FC<INumeric> = ({
  label,
  placeholder,
  required = false,
  ...props
}) => {
  const intl = createIntl('components_social_security_number')
  const translation = useTranslation(intl)

  const validate = (value: FormikValues) => {
    if (required && !value?.length) {
      return translation.translate('validate.required')
    }

    const regex = /^(?!0000)\d{4}$/
    return regex.test(String(value))
      ? ''
      : translation.translate('validate.incorrect')
  }

  return (
    <Input
      label={label || translation.translate('label')}
      placeholder={placeholder || translation.translate('placeholder')}
      validate={validate}
      type="numeric"
      {...props}
    />
  )
}

export default SocialSecurityNumberLast4Digits
