import { API } from 'api/connector'

import { IApiOrder, IApiOrderInstallment } from './type'

export const getOrder = async (id: string): Promise<IApiOrder> => {
  const order = await API.get(`/api/v1/orders/${id}`)
  const installments = await API.get(`/api/v1/orders/${id}/installments`)

  order.data.installments = await Promise.all(
    installments.data['hydra:member'].map(
      async (installment: IApiOrderInstallment) => {
        const payments = await API.get(`${installment['@id']}/payments`)
        return {
          ...installment,
          payments: payments.data['hydra:member'],
        }
      }
    )
  )

  /* Order installments by dueAt date */
  order.data.installments.sort(
    (a: IApiOrderInstallment, b: IApiOrderInstallment) =>
      a.dueAt.localeCompare(b.dueAt)
  )

  return order.data
}
