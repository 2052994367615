import { Select } from 'components'
import { createIntl, useTranslation } from 'hooks'
import { FC, useState } from 'react'
import { useAsync } from 'react-use'
import { formatLocale, getLocaleShortcode } from 'utils'
import { ECaseType } from 'utils/Intl/type'

import * as Data from './data'
import * as Type from './type'

const getCountriesOptions = (
  collection: { [key: string]: string | string[] },
  whitelist: string[]
): Type.ICountriesCollection => {
  const countriesCollection: Type.ICountriesCollection = []
  const otherCountries: { label: string; value: string }[] = []

  for (const countryCode of Object.keys(collection)) {
    const countryName = collection[countryCode].toString()
    if (whitelist.includes(countryCode)) {
      countriesCollection.push({ label: countryName, value: countryCode })
    } else {
      otherCountries.push({ label: countryName, value: countryCode })
    }
  }

  // Remove overseas (DOM/TOM) from countries list
  const filteredOtherCountries = otherCountries.filter(
    (country) => !Data.overseasFranceList.includes(country.value)
  )

  // Sort other countriesCollection alphabetically
  filteredOtherCountries.sort((a, b) => a.label.localeCompare(b.label))

  // Merge whitelist collection and other countriesCollection collection
  Array.prototype.push.apply(countriesCollection, filteredOtherCountries)

  return countriesCollection
}

export const importCountries = async (
  shortLocale: string
): Promise<Type.ICountriesCollection> => {
  let sortedCountries: Type.ICountriesCollection = []
  /* Import i18n countries library */
  await import('i18n-iso-countries').then(async (countries) => {
    /* Import countries locales for current organization */
    await import(`i18n-iso-countries/langs/${shortLocale}.json`).then(
      async (locale) => {
        /* Get localized countries alphabetically sorted with a whitelist on top  */
        countries.registerLocale(locale)
        sortedCountries = getCountriesOptions(
          countries.getNames(shortLocale),
          Data.assoconnectSignUpWhitelistedCountries
        )
      }
    )
  })

  return sortedCountries
}

const Country: FC<Type.ICountry> = ({ label, onChangeCallback, ...rest }) => {
  const intl = createIntl('components_country')
  const translation = useTranslation(intl)
  const [countries, setCountries] = useState<
    { value: string; label: string }[]
  >([])
  const shortLocale = getLocaleShortcode(
    formatLocale(intl.locale, ECaseType.SNAKE)
  )

  useAsync(async () => {
    const countriesList = await importCountries(shortLocale)
    setCountries(countriesList)
  }, [shortLocale])

  return (
    <div>
      <Select
        label={label === undefined ? translation.translate('label') : label}
        options={countries}
        onChangeCallback={onChangeCallback}
        disabled={!countries}
        isSearchable={true}
        placeholder={translation.translate('placeholder')}
        isClearable={true}
        {...rest}
      />
    </div>
  )
}

export default Country
