import { Link } from 'components'
import styled from 'styled-components'

export const HelperLink = styled(({ ...props }) => <Link {...props} />)`
  // Widen the target zone on mobile by adding invisible padding
  width: 48px;
  height: 48px;
  margin: -6px 0 0 -15px;

  @media (min-width: ${(props) => props.theme['gridBreakpoints-md']}) {
    width: auto;
    height: auto;
    margin: -3px 0 0 0;
  }
`
