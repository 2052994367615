import { API } from 'api/connector'
import { IApiOrganizationPspWallet } from 'api/type'

import * as Type from './type'

export const getAdminMasterPspWalletAdyenUrl = async (
  pspWalletId: IApiOrganizationPspWallet['id'] | undefined
): Promise<Type.IPspWalletAdyenUrl> => {
  const adyenUrl = await API.get(`/api/v1/psp_wallet/${pspWalletId}/adyen_url`)
  return adyenUrl.data
}
