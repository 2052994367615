import { getOrganizationEmailCampaigns } from 'api'
import { isAxiosError } from 'api/connector'
import {
  EAPIPspLifecycleStatus,
  IApiAccountingSettings,
  IApiOrganizationPspSubWallet,
  IApiOrganizationPspWallet,
} from 'api/type'
import { useSettings } from 'hooks'
import { ESetting } from 'hooks/useSettings/type'
import {
  ISpecificationStatus,
  IStoreOrganization,
  IStorePerson,
} from 'state/type'

import * as Type from './type'
import { ERefusalReason } from './type'
import * as Utils from './utils'

const UseSpecifications = (): Type.IUseSpecifications => {
  const { getSetting } = useSettings()

  const getSpecificationResult = async (
    specificationType: Type.ESpecification,
    specification: ISpecificationStatus,
    organization: IStoreOrganization,
    person: IStorePerson
  ) => {
    switch (specificationType) {
      case Type.ESpecification.ACCOUNTING_SETUP: {
        const accountingSetting = (await getSetting(
          ESetting.ACCOUNTING,
          false,
          {
            allowedErrorCodes: [404, 403],
          }
        )) as IApiAccountingSettings

        const isValid = Boolean(
          accountingSetting?.hasOngoingPeriod &&
            accountingSetting?.accountingSetUp &&
            !accountingSetting?.resetInProgress
        )
        specification.isValid = isValid
        specification.refusalReason = isValid ? null : ERefusalReason.SETTING

        // If the user has no ongoing period
        if (
          !isValid &&
          accountingSetting?.accountingSetUp &&
          !accountingSetting?.hasOngoingPeriod
        ) {
          specification.refusalReason = ERefusalReason.ACCOUNTING_PERIOD
        }

        break
      }
      case Type.ESpecification.WALLET_VERIFIED: {
        const pspWallet = (await getSetting(
          ESetting.PSP_WALLET,
          false,
          false
        )) as IApiOrganizationPspWallet

        const pspSubWallet = (await getSetting(
          ESetting.PSP_SUB_WALLET,
          false,
          false
        )) as IApiOrganizationPspSubWallet

        specification.isValid =
          person.isAdminMaster ||
          (!pspWallet.isBlocked &&
            !pspWallet.isSuspended &&
            pspSubWallet !== undefined)

        specification.refusalReason = Utils.getPspWalletRefusalReason(pspWallet)
        break
      }
      case Type.ESpecification.WALLET_CREATED: {
        const pspWallet = (await getSetting(
          ESetting.PSP_WALLET,
          false,
          false
        )) as IApiOrganizationPspWallet

        const hasBankAccountsAccess = await Utils.getBankAccountsAccess(
          organization,
          getSetting
        )

        const isValid =
          person.isAdminMaster ||
          (!pspWallet.isSuspended &&
            hasBankAccountsAccess &&
            [
              EAPIPspLifecycleStatus.PENDING,
              EAPIPspLifecycleStatus.VALID,
            ].includes(pspWallet.pspLifecycleStatus))

        specification.isValid = isValid
        specification.refusalReason = isValid ? null : ERefusalReason.SETTING

        break
      }
      case Type.ESpecification.EMAILING_HAS_CAMPAIGNS: {
        const campaigns = await getOrganizationEmailCampaigns(organization.id, {
          page: 1,
          itemsPerPage: 1,
        })
        const isValid = campaigns.totalItems > 0

        specification.isValid = isValid
        specification.refusalReason = isValid
          ? null
          : ERefusalReason.EMAILING_HAS_NO_CAMPAIGNS
      }
    }
  }

  const getSpecification = async (
    specificationType: Type.ESpecification,
    organization: IStoreOrganization,
    person: IStorePerson
  ) => {
    const specification: ISpecificationStatus = {
      isValid: false,
      refusalReason: ERefusalReason.OTHER,
    }
    try {
      await getSpecificationResult(
        specificationType,
        specification,
        organization,
        person
      )
    } catch (error: unknown) {
      if (isAxiosError(error) && error.response?.status) {
        return {
          isValid: false,
          refusalReason: Utils.getRefusalReason(error.response.status),
        }
      }
    }

    return specification
  }

  const getSpecifications = async (
    specificationsList: Array<Type.ESpecification>,
    organization: IStoreOrganization,
    person: IStorePerson
  ) => {
    const specifications = await Promise.all(
      specificationsList.map(async (specification) => {
        const spec = await getSpecification(specification, organization, person)
        return { [specification]: spec }
      })
    )

    const specificationsFormatted: { [key: string]: ISpecificationStatus } = {}
    for (const item of specifications) {
      const key: string = Object.keys(item)[0]
      specificationsFormatted[key] = item[key]
    }

    return specificationsFormatted
  }

  return {
    getSpecifications,
  }
}

export default UseSpecifications
