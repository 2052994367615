import { getMapboxCity } from 'api'
import { IMapboxCityCollection } from 'api/_external/Mapbox/type'
import { Autocomplete } from 'components'
import { UseComboboxStateChange } from 'downshift'
import { createIntl, useTranslation } from 'hooks'
import { FC, useState } from 'react'

import * as Type from './type'

const City: FC<Type.ICity> = ({ name, label, placeholder, ...rest }) => {
  const intl = createIntl('components_city')
  const translation = useTranslation(intl)
  const [items, setItems] = useState<IMapboxCityCollection>([])

  const mapboxSearch = async ({
    inputValue,
  }: UseComboboxStateChange<string>) => {
    const cityCollection = await getMapboxCity(inputValue || '')
    setItems(cityCollection)
  }

  return (
    <Autocomplete
      name={name}
      placeholder={
        placeholder === undefined
          ? translation.translate('placeholder')
          : placeholder
      }
      label={label === undefined ? translation.translate('label') : label}
      items={items}
      maxLength={100}
      onIsOpenChange={mapboxSearch}
      onInputValueChange={mapboxSearch}
      {...rest}
    />
  )
}

export default City
