import { Input } from 'components'
import { Field, FieldProps, FormikValues } from 'formik'
import { createIntl, useTranslation } from 'hooks'
import { ChangeEvent, FC } from 'react'
import { isLuhn } from 'utils'

import * as Type from './type'
import * as Utils from './utils'

const FrenchSiren: FC<Type.IFrenchSiren> = ({
  label,
  name,
  required,
  ...rest
}) => {
  const intl = createIntl('components_frenchsiren')
  const translation = useTranslation(intl)

  return (
    <Field
      name={name}
      children={({
        field: { name: fieldName, value: fieldValue, ...field },
        form: { errors, touched, setFieldValue, setFieldTouched },
      }: FieldProps) => {
        const invalid = !!(touched[name] && errors[name])
        return (
          <>
            <Input
              {...field}
              className="mb-0"
              fieldName={name}
              label={
                label === undefined ? translation.translate('label') : label
              }
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                event.preventDefault()
                setFieldValue(
                  name,
                  Utils.normalizeSirenValue(event.target.value)
                )
              }}
              onBlur={() => {
                setFieldTouched(name, true)
              }}
              placeholder={translation.translate('placeholder')}
              isInvalid={invalid}
              value={Utils.getSirenDisplayForInput(fieldValue)}
              {...rest}
            />
          </>
        )
      }}
      validate={(value: FormikValues): undefined | string => {
        if (required && !value) {
          return translation.translate('validate.required')
        }
        if (!value) {
          return
        }
        if (value.match(/^[0-9]{9}$/) === null || !isLuhn(String(value))) {
          return translation.translate('validate.incorrect')
        }
      }}
    />
  )
}

export default FrenchSiren
