import { EBrand } from 'utils'
import { ELocale } from 'utils/Intl/type'

export interface IStoreI18n {
  isLoaded: boolean
  brand: IStoreBrand
  locale: ELocale
  country: string
  timezone: string
}

export interface IStoreBrand {
  name: EBrand
  publicName: string
}

export enum ECurrency {
  EUR = 'EUR',
  USD = 'USD',
}
