import { Select as SelectComponent } from 'components'
import styled, { css } from 'styled-components'

export const Iban = styled.small`
  color: ${(props) => props.theme.darkGrey};
`

const MultilineSelect = css`
  .react-select__value-container {
    padding-top: 5px;
    padding-bottom: 5px;
  }
`

export const Select = styled(({ hasIban, ...props }) => (
  <SelectComponent {...props} />
))`
  ${({ hasIban }) => hasIban && MultilineSelect}

  .react-select__single-value {
    display: contents;
  }
`
