import { Input, ModalConfirm } from 'components'
import { Formik, FormikValues } from 'formik'
import { createIntl, useTranslation } from 'hooks'
import { FC } from 'react'
import { Label } from 'reactstrap'

import { IModalConfirm } from '../Confirm/type'

const ModalSubmitKeywords: FC<IModalConfirm & { requiredText: string }> = ({
  requiredText,
  callbackConfirm,
  ...props
}) => {
  const intl = createIntl('components_confirm_keywords')
  const translation = useTranslation(intl)

  return (
    <Formik
      validateOnMount={true}
      validate={(values) => {
        if (!values.keywords.length || values.keywords !== requiredText) {
          return {
            keywords: translation.translate('error.matching', {
              keywords: requiredText,
            }),
          }
        }
        return undefined
      }}
      initialValues={{ keywords: '' }}
      onSubmit={() => callbackConfirm()}
    >
      {({ setFieldValue, errors, submitForm }) => (
        <ModalConfirm
          callbackConfirm={async () => {
            if (errors.keywords) {
              return undefined
            }
            return callbackConfirm()
          }}
          {...props}
        >
          <Label className="mt-2">
            <strong>
              {translation.translate('label', {
                keywords: requiredText,
              })}
            </strong>
          </Label>
          <Input
            onKeyDown={(event: KeyboardEvent) => {
              if (event.key === 'Enter') {
                void submitForm()
              }
            }}
            type="text"
            name="keywords"
            placeholder={translation.translate('placeholder')}
            onChange={(event: FormikValues) =>
              setFieldValue('keywords', event.target.value)
            }
            required={true}
          />
        </ModalConfirm>
      )}
    </Formik>
  )
}

export default ModalSubmitKeywords
