export const formatCheckNumberDisplayValue = (value: string): string => {
  if (!value) {
    return ''
  }

  if (value.length > 19) {
    return `${value.slice(0, 7)} ${value.slice(7, 19)} ${value.slice(19)}`
  } else if (value.length > 7) {
    return `${value.slice(0, 7)} ${value.slice(7)}`
  }
  return value
}
