import { Col, Row, Title } from 'components'
import { FC, useState } from 'react'
import { EColor } from 'types'

import StepperFooter from './Footer'
import Steps from './Steps'
import * as Style from './style'
import * as Type from './type'
import { EStepType } from './type'

const Stepper: FC<Type.IStepper> = ({ steps }) => {
  const [currentStep, setCurrentStep] = useState(0)
  const currentStepData = steps[currentStep]
  const hasManySteps =
    steps.filter((step) => step.type === EStepType.STEP).length > 1
  const isRegularStep = currentStepData?.type === EStepType.STEP

  return (
    <Style.StepperContainer>
      {/* SideBlock */}
      {/* Displayed if more than one step or if intro-conclusion   */}
      {(hasManySteps || currentStepData.type !== EStepType.STEP) && (
        <Steps steps={steps} currentStep={currentStep} />
      )}

      {/* Content */}
      <Style.Content className="mt-lg-0">
        <div className="w-100">
          <Style.StepperForm
            isRegularStep={isRegularStep}
            className={isRegularStep && 'isRegularStep'}
          >
            {/* if is intro or conclusion */}
            {!isRegularStep && (
              <>
                {currentStepData.title &&
                  currentStepData.type === EStepType.INTRODUCTION && (
                    <Title type="h1" color={EColor.BLUE}>
                      {currentStepData.title}
                    </Title>
                  )}

                {currentStepData.yado && (
                  <Style.Yado
                    src={`common/yado/${currentStepData.yado}`}
                    className="d-lg-none"
                  />
                )}
              </>
            )}
            <Row className="w-100">
              <Col>{currentStepData.content}</Col>
            </Row>
          </Style.StepperForm>

          {currentStepData.type !== EStepType.CONCLUSION && (
            <StepperFooter
              step={currentStepData}
              updateCurrentStep={setCurrentStep}
            />
          )}
        </div>
      </Style.Content>
    </Style.StepperContainer>
  )
}

export default Stepper
