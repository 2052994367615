export interface IApiCrmWebhook {
  '@id': string
  groups: ICrmWebhookOrganization[]
  name: string
  organization: ICrmWebhookOrganization
  status: ECrmWebhookStatus
  url: string
}
export enum ECrmWebhookStatus {
  ACTIVE = 'ACTIVE',
  BLOCKED = 'BLOCKED',
}

type ICrmWebhookOrganization = {
  '@id': string
  type: string
  name: string
}
