import { Display } from 'components'
import { FC } from 'react'
import { ModalBody } from 'reactstrap'

import { Header, ModalContainer } from '../Confirm/style'
import * as Type from './type'

const ModalBlank: FC<Type.IModalBlank> = ({
  callback,
  isOpen,
  size = 'sm',
  title,
  content,
  centered = true,
  ...rest
}) => (
  <ModalContainer
    centered={centered}
    isOpen={isOpen}
    size={size}
    toggle={callback}
    {...rest}
  >
    <Header className="pb-0" toggle={() => callback && callback()} />
    <ModalBody className="py-0 mx-0 mx-md-4 mb-4">
      {title && (
        <Display type="h5" className="pb-2">
          {title}
        </Display>
      )}
      <div className="mb-1">{content}</div>
    </ModalBody>
  </ModalContainer>
)

export default ModalBlank
