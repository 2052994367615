export enum ELocale {
  FR_FR = 'fr_FR',
  EN_US = 'en_US',
}

export enum ECountry {
  FR = 'FR',
  US = 'US',
  CA = 'CA',
  GB = 'GB',
  AU = 'AU',
  BE = 'BE',
  CH = 'CH',
  LU = 'LU',
}

export enum ECaseType {
  KEBAB = 'KEBAB',
  SNAKE = 'SNAKE',
}
