import { Dinero } from 'dinero.js'

export type ITranslationParameter = string | Date | Dinero | number

export enum ETranslationParameterType {
  DATE = 'DATE',
  DINERO = 'DINERO',
  NUMBER = 'NUMBER',
  STRING = 'STRING',
}

/*eslint-disable @typescript-eslint/no-explicit-any */
export type ETranslationParameters = Record<string, any>
/*eslint-enable @typescript-eslint/no-explicit-any */

export interface IUseTranslation {
  translate: (key: string, params?: ETranslationParameters) => string
}
