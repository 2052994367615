import axios from 'axios'
import { IUseTranslation } from 'hooks/useTranslation/type'

import * as Data from './data'

export const handleValidateEmail = async (
  value: string,
  required: boolean,
  translation: IUseTranslation,
  isDomainValid: undefined | boolean = undefined
): Promise<undefined | string> => {
  // Empty email and not required, no need for validation
  if (!value?.length && !required) {
    return undefined
  }

  if (!value?.length && required) {
    return translation.translate('validate.required')
  }
  // Email
  if (
    !value.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  ) {
    return translation.translate('validate.email')
  }

  if (isDomainValid === false) {
    return translation.translate('validate.domain')
  }

  return undefined
}

export const checkEmailDomain = async (value: string) => {
  const [, domain] = value.split('@')
  // Do not check the domain if it is in the whitelist.
  if (Data.domainsWhitelist.includes(domain)) {
    return true
  }

  const check = await checkEmailDnsMx(String(value))
  return Boolean(check)
}

const checkEmailDnsMx = async (email: string) => {
  const domain = email.replace(/.*@/, '')
  return axios
    .get(
      `${process.env.REACT_APP_GOOGLE_DNS_RESOLVER_URL}?name=${domain}&type=mx`
    )
    .then((response) => response.data.Answer || false)
    .catch(() => true)
}
