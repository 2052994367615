import { getMapboxAddress } from 'api'
import {
  IMapboxAddress,
  IMapboxAddressCollection,
} from 'api/_external/Mapbox/type'
import { Autocomplete } from 'components'
import { UseComboboxStateChange } from 'downshift'
import { createIntl, useTranslation } from 'hooks'
import debounce from 'lodash.debounce'
import { FC, useMemo, useRef, useState } from 'react'

import * as Type from './type'

const Street: FC<Type.IStreet> = ({
  name,
  label,
  placeholder,
  onSelectedItemChange,
  handleValidation,
  ...rest
}) => {
  const intl = createIntl('components_street')
  const translation = useTranslation(intl)
  const [collection, setCollection] = useState<IMapboxAddressCollection>([])
  const [items, setItems] = useState<string[]>([])
  const refAddress = useRef<IMapboxAddress>()

  const debouncedEventHandler = useMemo(
    () =>
      debounce(async (inputValue: string) => {
        const addressCollection = await getMapboxAddress(inputValue || '')
        setCollection(addressCollection)
        setItems(addressCollection.map((address) => address.place_name))
      }, 250),
    []
  )

  const mapboxSearch = async ({
    inputValue,
  }: UseComboboxStateChange<string>) => {
    await debouncedEventHandler(String(inputValue))
  }

  const extractAddress = (placeName: string | null) => {
    if (placeName) {
      refAddress.current = collection.find(({ place_name }) =>
        place_name.includes(placeName)
      )
    }
  }

  return (
    <Autocomplete
      name={name}
      maxLength={100}
      placeholder={placeholder || translation.translate('placeholder')}
      label={label || translation.translate('label')}
      items={items}
      itemToString={(placeName: string | null) => {
        extractAddress(placeName)
        return refAddress.current?.street1 || ''
      }}
      onIsOpenChange={mapboxSearch}
      onInputValueChange={mapboxSearch}
      handleValidation={handleValidation}
      onSelectedItemChange={() => {
        onSelectedItemChange && onSelectedItemChange(refAddress.current)
      }}
      {...rest}
    />
  )
}

export default Street
