import axios from 'axios'

import * as Data from './data'
import * as Type from './type'

const MAPBOX_MIN_CHARS = 4
const cache: Type.IMapboxCache = {
  address: {},
  city: {},
}

const Mapbox = axios.create({ baseURL: process.env.REACT_APP_MAPBOX_HOST })
Mapbox.interceptors.request.use(({ params, ...config }) => ({
  ...config,
  params: {
    ...params,
    proximity: 'ip',
    access_token: process.env.REACT_APP_MAPBOX_TOKEN,
  },
}))

const extractContextById = (
  feature: Type.IMapboxFeature,
  idSearched: string
): string => {
  // Short_code for country
  if (idSearched === 'country') {
    return (
      feature?.context
        ?.find(({ id }) => id.includes(idSearched))
        ?.short_code?.toUpperCase() || ''
    )
  }
  // Text for others
  return feature?.context?.find(({ id }) => id.includes(idSearched))?.text || ''
}

export const getMapboxAddress = async (
  search: string
): Promise<Type.IMapboxAddressCollection> => {
  // Search too short
  if (search.length < MAPBOX_MIN_CHARS) {
    return []
  }

  // Returns cache data
  const encodedSearch = encodeURI(search)
  if (encodedSearch in cache.address) {
    return cache.address[encodedSearch]
  }

  // Returns API data
  const addressCollection: Type.IMapboxAddressCollection = []
  const response = await Mapbox.get<Type.IMapboxResponse>(
    `/mapbox.places/${encodedSearch}.json`,
    {
      params: { types: 'address,poi' },
    }
  )

  const getAdministrativeAreas = (feature: Type.IMapboxFeature) => {
    const district = extractContextById(feature, 'district')
    const country = extractContextById(feature, 'country')
    const region = extractContextById(feature, 'region')
    if (district || Data.countriesWithState.includes(country)) {
      return {
        administrativeArea1: region,
        administrativeArea2: district,
      }
    }
    return {
      administrativeArea1: '',
      administrativeArea2: region,
    }
  }

  response.data.features.forEach((feature) => {
    const { administrativeArea1, administrativeArea2 } =
      getAdministrativeAreas(feature)
    const street1 = [feature.text]
    if (feature.address) {
      street1.unshift(feature.address)
    }
    addressCollection.push({
      place_name: feature.place_name,
      administrativeArea1,
      administrativeArea2,
      street1: street1.join(' '),
      street2: '',
      city: extractContextById(feature, 'place'),
      country: extractContextById(feature, 'country'),
      postal: extractContextById(feature, 'postcode'),
      latitude: String(feature?.center?.[1] || ''),
      longitude: String(feature?.center?.[0] || ''),
    })
  })

  cache.address[encodedSearch] = addressCollection
  return addressCollection
}

export const getMapboxCity = async (
  search: string
): Promise<Type.IMapboxCityCollection> => {
  // Search too short
  if (search.length < MAPBOX_MIN_CHARS) {
    return []
  }

  // Returns cache data
  const encodedSearch = encodeURI(search)
  if (encodedSearch in cache.city) {
    return cache.city[encodedSearch]
  }

  const cityCollection: Type.IMapboxCityCollection = []
  const response = await Mapbox.get<Type.IMapboxResponse>(
    `/mapbox.places/${encodedSearch}.json`,
    {
      params: { types: 'place' },
    }
  )

  response.data.features.forEach((feature) => {
    cityCollection.push(feature.text)
  })

  const uniqueCityCollection = [...new Set(cityCollection)]
  cache.city[encodedSearch] = uniqueCityCollection
  return uniqueCityCollection
}
