import { Dinero as TDinero } from 'dinero.js'
import { IntlShape } from 'react-intl'
import { formatDate, getFormattedAmount } from 'utils'

import * as Type from './type'
import { ETranslationParameters, ETranslationParameterType } from './type'

const UseTranslation = (intl: IntlShape): Type.IUseTranslation => {
  const getParameterType = (
    parameter: Type.ITranslationParameter
  ): Type.ETranslationParameterType => {
    // Date
    if (parameter instanceof Date) {
      return Type.ETranslationParameterType.DATE
    }
    // Dinero
    const dineroEntry = parameter as TDinero
    if (typeof dineroEntry?.getAmount === 'function') {
      return Type.ETranslationParameterType.DINERO
    }

    if (typeof parameter === 'number') {
      return Type.ETranslationParameterType.NUMBER
    }

    return ETranslationParameterType.STRING
  }

  const translate = (key: string, params?: ETranslationParameters): string => {
    // Return translated content for simple translation
    if (!params) {
      return intl.formatMessage({ id: key })
    }

    // Decorate parameters
    const translationParams: { [key: string]: string } = {}
    for (const paramEntry of Object.entries(params)) {
      // Get param type according to its value
      const paramType = getParameterType(paramEntry[1])

      // Decorate the value according to its type
      switch (paramType) {
        case Type.ETranslationParameterType.DATE:
          translationParams[paramEntry[0]] = formatDate(paramEntry[1] as Date)
          break
        case Type.ETranslationParameterType.DINERO:
          translationParams[paramEntry[0]] = getFormattedAmount(
            paramEntry[1] as TDinero
          )
          break
        default:
          translationParams[paramEntry[0]] = paramEntry[1]
          break
      }
    }

    return intl.formatMessage({ id: key }, translationParams)
  }

  return {
    translate,
  }
}

export default UseTranslation
