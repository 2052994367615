export enum ECalendlyOrigin {
  SIGNUP = 'signup',
  IN_APP = 'inapp',
  PAYMENT_ONBOARDING = 'payment_onboarding',
}

export interface ICalendly {
  calendlyLink?: string
  handleMeetingBooked?: () => void
  origin: ECalendlyOrigin
  height?: number | string
  title?: string
  trackDemoBooking?: boolean
}
