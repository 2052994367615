import * as Type from './type'

const UseIframeHeightListener = (): Type.IUseIframeListener => {
  const resizeIframe = () => {
    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        window.parent.postMessage(
          {
            action: 'donation-iframe.height',
            height: entry.contentRect.height,
          },
          '*'
        )
      }
    })
    observer.observe(document.body)

    return () => {
      observer.disconnect()
    }
  }

  const getIsIframe = () => window.self !== window.top

  return { getIsIframe, resizeIframe }
}

export default UseIframeHeightListener
