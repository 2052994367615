import { API } from 'api/connector'
import { IApiAccountingSubBudget } from 'api/type'

import * as Type from './type'

export const updateAccountingSubBudget = async ({
  subbudgetItemId,
  name,
  budgetId,
  isArchived,
}: Type.IUpdateAccountingSubBudget): Promise<Type.IApiAccountingSubBudgetCreation> => {
  const updatedAccountingSubBudget = await API.put(
    `/api/v1/accounting_sub_budgets/${subbudgetItemId}`,
    {
      name,
      budget: `/api/v1/accounting_budgets/${budgetId}`,
      isArchived,
    }
  )
  return updatedAccountingSubBudget.data
}

export const createAccountingSubBudget = async ({
  accountingBudgetId,
  name,
}: Type.ICreateAccountingSubBudget): Promise<Type.IApiAccountingSubBudgetCreation> => {
  const newAccountingSubBudget = await API.post(
    `/api/v1/accounting_sub_budgets`,
    {
      name,
      budget: `/api/v1/accounting_budgets/${accountingBudgetId}`,
    }
  )
  return newAccountingSubBudget.data
}

export const deleteAccountingSubBudget = async ({
  id,
}: {
  id: IApiAccountingSubBudget['id']
}): Promise<{ status: number }> =>
  API.delete(`/api/v1/accounting_sub_budgets/${id}`)
