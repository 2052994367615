import { API } from 'api/connector'

import { IPaymentRealization, IPaymentRealizationPayload } from './type'

export const createPaymentRealizations = async (
  payload: IPaymentRealizationPayload
): Promise<IPaymentRealization> => {
  const paymentRealizations = await API.post(
    '/api/v1/check_payment_realizations',
    payload
  )
  return paymentRealizations.data
}

export const updatePaymentRealizations = async (
  paymentRealizationId: IPaymentRealization['id'],
  payload: IPaymentRealizationPayload
): Promise<IPaymentRealization> => {
  const paymentRealizations = await API.put(
    `/api/v1/check_payment_realizations/${paymentRealizationId}`,
    payload
  )
  return paymentRealizations.data
}
