import {
  electronicFormatIBAN as electronicFormatIBANDep,
  extractIBAN,
  friendlyFormatIBAN as friendlyFormatIBANDep,
  isValidBIC as isValidBICDep,
  isValidIBAN as isValidIBANDep,
} from 'ibantools'

export const friendlyFormatIBAN = (iban: string, separator?: string): string =>
  friendlyFormatIBANDep(iban, separator) ?? ''

export const electronicFormatIBAN = (iban: string): string =>
  electronicFormatIBANDep(iban) ?? ''

export const isValidIBAN = (iban: string): boolean => isValidIBANDep(iban)

export const isValidBIC = (bic: string): boolean => isValidBICDep(bic)

export const getIBANAccountNumber = (iban: string): string | null =>
  extractIBAN(iban).accountNumber ?? null
