import axios from 'axios'

import * as Type from './type'

export const getOrganizationByName = async (
  name: string
): Promise<Type.IApiOrganizationSiret> => {
  const organization = await axios.get(
    `https://recherche-entreprises.api.gouv.fr/search?q=${name}&est_association=true&limite_matching_etablissements=1&minimal=false&page=1&per_page=1`
  )
  return organization.data
}
