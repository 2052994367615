import { useHistory } from 'hooks'
import { FC, ReactNode, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { addNotification } from 'state'
import { EColor } from 'types'
import { deleteUrlParameters } from 'utils'

import RouterContext from './RouterContext'
import { IRouterContext } from './type'

const RouterProvider: FC<{
  children: ReactNode
  keepUrlSearchParameters: boolean
}> = ({ children, keepUrlSearchParameters = false }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)
  const [currentPathname, setCurrentPathname] = useState<string | undefined>(
    undefined
  )

  const [urlSearchParameters, setUrlSearchParameters] = useState<
    IRouterContext['urlSearchParameters'] | undefined
  >(undefined)

  useEffect(() => {
    if (history.location.pathname !== currentPathname) {
      setIsLoading(true)
      setUrlSearchParameters(undefined)
      setCurrentPathname(history.location.pathname)
    }
  }, [history.location.pathname, currentPathname])

  useEffect(() => {
    if (!isLoading) {
      return
    }

    const searchParameters = new URLSearchParams(history.location.search)

    const messageSuccess = searchParameters.get('messageSuccess[]')
    const messageSignature = searchParameters.get('messageSignature[]')

    if (messageSuccess) {
      dispatch(
        addNotification({
          color: EColor.GREEN,
          text: messageSuccess,
        })
      )
      deleteUrlParameters(history, ['messageSuccess[]'])
    }

    if (messageSignature) {
      deleteUrlParameters(history, ['messageSignature'])
    }

    setUrlSearchParameters({
      ...Object.fromEntries(searchParameters),
    })
    if (!keepUrlSearchParameters) {
      for (const entries of searchParameters) {
        deleteUrlParameters(history, entries)
      }
    }

    setIsLoading(false)
  }, [history, currentPathname, dispatch, isLoading, keepUrlSearchParameters])

  if (isLoading) {
    return <></>
  }

  return (
    <RouterContext.Provider value={{ urlSearchParameters }}>
      {children}
    </RouterContext.Provider>
  )
}

export default RouterProvider
