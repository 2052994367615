import { Tooltip } from 'components'
import { FC, useRef } from 'react'
import { EColor } from 'types'
import { uniqId } from 'utils'

import * as Style from './style'
import * as Type from './type'

const ResponsiveButton: FC<Type.IResponsiveButton> = ({
  children,
  color = EColor.BLUE,
  id,
  icon,
  tooltipPosition = 'bottom',
  type = 'button',
  ...rest
}) => {
  const prefixId = 'responsive-btn' // Prevent errors if the id starts with a number
  const refId = useRef(id || uniqId(prefixId))
  return (
    // Important : Keep ref on span to handle tooltips if disabled
    <span id={refId.current}>
      <Style.ResponsiveButton
        color={color}
        outline={true}
        type={type}
        {...rest}
      >
        <Style.ResponsiveIcon
          icon={icon}
          color={color}
          className="d-none d-md-inline-block mr-2 mr-lg-0"
        />
        <span className="d-inline d-lg-none">{children}</span>
        <Tooltip
          placement={tooltipPosition}
          target={refId.current}
          className="d-none d-lg-block"
        >
          {children}
        </Tooltip>
      </Style.ResponsiveButton>
    </span>
  )
}

export default ResponsiveButton
