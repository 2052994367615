import { API } from 'api/connector'
import { IApiAccountingBreakdown, IApiAccountingBreakdownParam } from 'api/type'

export const updateAccountingBreakdown = async ({
  id,
  param,
}: {
  id: IApiAccountingBreakdown['@id']
  param: IApiAccountingBreakdownParam
}): Promise<IApiAccountingBreakdown> => {
  const updatedAccountingBreakdown = await API.put(
    `/api/v1/accounting_breakdowns/${id}`,
    param
  )
  return updatedAccountingBreakdown.data
}

export const deleteAccountingBreakdown = async (
  id: IApiAccountingBreakdown['@id']
): Promise<IApiAccountingBreakdown> =>
  API.delete(`/api/v1/accounting_breakdowns/${id}`)

export const createAccountingBreakdown = async (
  param: IApiAccountingBreakdownParam
): Promise<IApiAccountingBreakdown> => {
  const newAccountingBreakdown = await API.post(
    `/api/v1/accounting_breakdowns`,
    param
  )
  return newAccountingBreakdown.data
}
