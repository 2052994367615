import { IconProp } from '@fortawesome/fontawesome-svg-core'
import classNames from 'classnames'
import { Button, Icon, Link, Svg, Title, Tooltip } from 'components'
import { useTranslation } from 'hooks'
import { Placement } from 'popper.js'
import { FC, useRef } from 'react'
import { useIntl } from 'react-intl'
import { EColor } from 'types'
import { uniqId } from 'utils'

import { ETitleFont } from '../Text/Title/type'
import * as Style from './style'
import * as Type from './type'

const getPinColor = (status: string) => {
  switch (status) {
    case 'pending':
      return ' bg-warning'
    case 'error':
      return ' bg-danger'
    case 'success':
      return ' bg-success'
    default:
      return ''
  }
}

const getPinIcon = (status: string): IconProp => {
  switch (status) {
    case 'pending':
      return ['fas', 'clock']
    case 'error':
      return ['far', 'times']
    default:
      return ['far', 'check']
  }
}

const getPinType = (duration: undefined | number, status: string) => {
  if (['todo', 'todoImportant'].includes(status) && duration) {
    return 'duration'
  }
  if (['error', 'pending', 'success'].includes(status)) {
    return 'icon'
  }
  return null
}

const getButtonColor = (forceDisableButton: boolean, status: string) => {
  if (forceDisableButton) {
    return EColor.LIGHT_GREY
  }
  return status === 'todoImportant' ? EColor.YELLOW : EColor.BLUE
}

// If you have this ↓         But you want this ↓
//       -----  -----             -----  -----
//       | a |  | b |             | a |  | b |
//       | a |  -----             | a |  |   |
//       -----                    -----  -----
// If you want your cards to be the same size even if they do not have the same content, use the flex position:
// https://www.notion.so/assoconnect/I-create-several-Backgrounds-Cards-of-the-same-height-04c8a42ac5c0446087653c2b351695bd

const Card: FC<Type.ICard> = ({
  alignButtons = true,
  buttonTranslationId,
  descriptionTranslationId,
  duration,
  forceDisableButton = false,
  buttonTooltip,
  handleClick,
  href,
  titleColor = EColor.GREY,
  titleTranslationId,
  status,
  svg,
}) => {
  const pinType = getPinType(duration, status)
  const intl = useIntl()
  const translation = useTranslation(intl)
  const refUniqueId = useRef(uniqId('card-button'))
  const cardButton = (
    <>
      <Style.Button
        className="my-3"
        color={getButtonColor(forceDisableButton, status)}
        disabled={forceDisableButton}
      >
        <div
          style={{ display: 'inline-block', position: 'relative' }}
          id={refUniqueId.current}
        >
          {translation.translate(buttonTranslationId)}
        </div>
      </Style.Button>
      {buttonTooltip && (
        <Tooltip
          placement={(buttonTooltip.position as Placement) ?? 'auto'}
          target={refUniqueId.current}
        >
          {buttonTooltip.text}
        </Tooltip>
      )}
    </>
  )

  const getCardButton = () => (
    <>
      {forceDisableButton ? (
        cardButton
      ) : (
        <Link href={href} onClick={handleClick}>
          {cardButton}
        </Link>
      )}
    </>
  )

  return (
    <Style.Card
      status={status}
      hoverShadow={['todo', 'todoImportant', 'error'].includes(status)}
      hoverTransform={['todo', 'todoImportant', 'error'].includes(status)}
      className={classNames(
        'd-flex align-items-center text-center flex-grow-1 w-100',
        { 'border border-danger': status === 'error' }
      )}
    >
      {pinType && (
        <Style.CardPin className={getPinColor(status)}>
          <div className="d-flex w-100 justify-content-center align-items-center">
            {pinType === 'icon' && (
              <Icon color={EColor.WHITE} icon={getPinIcon(status)} />
            )}
            {pinType === 'duration' && (
              <div className="w-100">
                <Style.CardDuration className="m-2">
                  <Svg src="common/icon/unicolor/time" color={EColor.BLUE} />
                  <Style.CardDurationAmount>
                    {`${duration}'`}
                  </Style.CardDurationAmount>
                </Style.CardDuration>
              </div>
            )}
          </div>
        </Style.CardPin>
      )}
      <Svg
        src={svg}
        width="90px"
        className="mb-3"
        color={
          ['success', 'pending'].includes(status)
            ? EColor.LIGHT_GREY
            : EColor.GREY
        }
        colorSecondary={
          ['success', 'pending'].includes(status)
            ? EColor.LIGHT_GREY
            : EColor.GREEN
        }
      />
      <Title
        className={classNames('mb-0', { 'flex-grow-1': alignButtons })}
        type="h6"
        font={ETitleFont.POPPINS}
        color={titleColor}
      >
        {translation.translate(titleTranslationId)}
      </Title>
      {['pending', 'success'].includes(status) ? (
        <Button color={EColor.LIGHT_GREY} className="my-3" disabled={true}>
          {translation.translate(buttonTranslationId)}
        </Button>
      ) : (
        getCardButton()
      )}
      {descriptionTranslationId && (
        <Style.CardDescription>
          <small
            className={classNames('mt-4', {
              'text-danger': status === 'error',
            })}
          >
            {typeof descriptionTranslationId === 'string'
              ? translation.translate(descriptionTranslationId)
              : descriptionTranslationId}
          </small>
        </Style.CardDescription>
      )}
    </Style.Card>
  )
}
export default Card
