import { History } from 'history'
import { ERedirectionType } from 'utils/Url/type'

import { TLinkParameters } from './type'

export const getUrlWithParameters = (
  url: string,
  history: History,
  parameters: TLinkParameters = { redirect: undefined }
) => {
  // Get parameters of the current URL
  const currentUrlParameters = new URLSearchParams(history.location.search)

  // Get parameters of the given URL
  const searchParams = new URL(window.location.origin + url).searchParams

  // Merge given URL parameters with currentUrlParameters
  searchParams.forEach((value, key) => {
    currentUrlParameters.set(key, value ?? '')
  })

  // Merge given parameters object with currentUrlParameters
  Object.entries(parameters).forEach(([key, value]) => {
    // Replace existing "redirect" parameter with given parameter one
    if (key === 'redirect' && value === undefined) {
      return currentUrlParameters.delete(key)
    }

    return currentUrlParameters.set(key, value ?? '')
  })

  const baseUrl = url.split('?')[0]

  const urlParameters = currentUrlParameters.toString()

  if (!urlParameters) {
    return baseUrl
  }
  return `${baseUrl}?${urlParameters}`
}

/**
 * Redirect to the given URL when <Redirect /> component is not an option
 */
export const redirectTo = (
  url: string,
  params?: { [key: string]: string },
  redirectionType?: ERedirectionType
) => {
  const searchParams = new URLSearchParams(window.location.search)
  if (params) {
    Object.keys(params).forEach((key) => {
      searchParams.set(key, params[key])
    })
  }

  const queryString = searchParams.toString()
  const queryStringToAppend = queryString ? `?${queryString}` : ''

  if (redirectionType === ERedirectionType.BLANK) {
    return window.open(`${url}${queryStringToAppend}`)
  }

  window.location.href = `${url}${queryStringToAppend}`
  return null
}

export const deleteUrlParameters = (
  history: History,
  parameters: Array<string>
) => {
  const searchParams = new URLSearchParams(window.location.search)

  parameters.forEach((param) => searchParams.delete(param))

  history.replace({ search: searchParams.toString() })
}

export const isInternalPath = (url: string) =>
  url.startsWith('/') && !url.startsWith('//')

export const decodeUrlParameters = <T,>(
  urlParameter?: string | null
): T | undefined => (urlParameter ? (JSON.parse(urlParameter) as T) : undefined)
