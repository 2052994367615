import { API } from 'api/connector'
import * as Type from 'api/type'

export const updateCollectSettings = async (
  id: string,
  collectSettings: Type.IListOfCollectsSettings
): Promise<Type.IApiCollectsSettings> => {
  const collectsSettingsUpdated = await API.put(
    `/api/v1/collect/collect_feature_settings/${id}`,
    collectSettings
  )
  return collectsSettingsUpdated.data
}
