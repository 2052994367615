import { Svg } from 'components'
import styled from 'styled-components'

export const MainContainer = styled(({ isRegularStep, ...props }) => (
  <div {...props} />
))`
  background-color: white;
  width: 100vw;
  display: ${({ isRegularStep }) => !isRegularStep && 'none'};

  @media (min-width: ${(props) => props.theme['gridBreakpoints-md']}) {
    width: calc(100vw - 220px);
  }

  @media (min-width: ${(props) => props.theme['gridBreakpoints-lg']}) {
    display: flex;
    width: 400px;
    max-height: 100%;
    overflow-y: auto;
    border-top: 4px solid ${(props) => props.theme.backgroundColor};
  }
`
export const Yado = styled((props) => <Svg {...props} />)`
  width: 80%;
`

export const StepperVisualizationContainer = styled(
  ({ isRegularStep, stepsLength, currentStep, ...props }) => <div {...props} />
)`
  position: relative;
  width: 100%;
  padding-top: 1.5rem;
  display: ${({ isRegularStep }) => (isRegularStep ? 'flex' : 'none')};

  @media (min-width: ${(props) => props.theme['gridBreakpoints-lg']}) {
    width: 100%;
    display: flex;
    padding-top: 0;

    &.isRegularStep {
      padding: 3rem;
    }

    &.isNotRegularStep {
      flex-direction: column;
      padding: 1rem;
      text-align: center;
    }
  }
`

export const StepContainer = styled(({ index, active, current, ...props }) => (
  <div {...props} />
))`
  position: relative;
  min-height: 80px;
  height: 100%;
  width: 100%;
  min-width: 120px;

  @media (min-width: ${(props) => props.theme['gridBreakpoints-lg']}) {
    right: unset;
    bottom: ${({ current }) => current > 2 && (current - 1) * 80}px;
  }

  &:before {
    content: '';
    position: absolute;
    background: ${({ active, ...props }) =>
      active ? props.theme.green : props.theme.lightGrey};
    top: 12px;
    height: 2px;
    width: calc(100% - 32px);
    left: 58%;

    @media (min-width: ${(props) => props.theme['gridBreakpoints-lg']}) {
      height: calc(100% - 36px);
      width: 2px;
      left: 17px;
      top: unset;
      bottom: 0;
    }
  }

  &:last-child {
    height: 0;

    &:before {
      display: none;
    }
  }
`

export const StepperVisualization = styled(({ steps, ...props }) => (
  <div {...props} />
))`
  width: 100%;
  display: flex;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
  padding: 1rem 0;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: ${(props) => props.theme['gridBreakpoints-lg']}) {
    flex-direction: column;
    overflow: inherit;
    padding: 0;
  }

  &nth-child(-n+3) .stepLine {
    width: ${({ steps }) => `${steps.length * 21}vw`};
    height: 3px;
    background: ${({ theme }) => theme.lightGrey};
    position: absolute;
    margin-top: 1rem;

    @media (min-width: ${(props) => props.theme['gridBreakpoints-xl']}) {
      width: 3px;
      height: ${({ steps }) => `${steps.length * 10}vh`};
      margin-left: 1.2rem;
      margin-top: 1rem;
    }
  }

  .step {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: ${(props) => props.theme['gridBreakpoints-lg']}) {
      flex-direction: row;
    }
  }

  .stepTitle {
    font-size: 12px;
    padding-top: 5px;
    font-weight: bold;
    color: ${({ theme }) => theme.lightGrey};

    @media (min-width: ${(props) => props.theme['gridBreakpoints-lg']}) {
      font-size: 15px;
      padding-top: 0;
    }

    @media (min-width: ${(props) => props.theme['gridBreakpoints-xl']}) {
      font-size: 17px;
    }

    &.active {
      color: ${({ theme }) => theme.green};
    }

    &.current {
      color: ${({ theme }) => theme.green};
    }
  }

  .stepRound {
    width: 25.7px;
    height: 25.7px;
    border-radius: 25px;
    background: white;
    color: ${({ theme }) => theme.lightGrey};
    z-index: 2;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    box-shadow: 0 0 0 3px ${({ theme }) => theme.lightGrey}, 0 0 0 6px white;

    @media (min-width: ${(props) => props.theme['gridBreakpoints-lg']}) {
      width: 36px;
      height: 36px;
      font-size: 20px;
    }

    &.active {
      color: white;
      background: ${({ theme }) => theme.green};
      box-shadow: 0 0 0 3px ${({ theme }) => theme.green}, 0 0 0 6px white;
    }

    &.current {
      color: ${({ theme }) => theme.green};
      box-shadow: 0 0 0 3px ${({ theme }) => theme.green}, 0 0 0 6px white;
    }
  }
`
