import { darken } from 'polished'
import { DropdownItem, DropdownToggle } from 'reactstrap'
import styled, { css } from 'styled-components'

const ClickableBreadcrumbItem = css`
  text-decoration: underline;
  cursor: pointer;
`

export const BreadcrumbItem = styled(({ ...props }) => <div {...props} />)`
  color: ${(props) => props.theme.black};
  & {
    color: inherit !important;
  }
  &:hover {
    ${({ position }) => position !== 'last' && ClickableBreadcrumbItem}
  }
`

export const Ellipsis = styled(({ ...props }) => <DropdownToggle {...props} />)`
  position: relative;
  width: 34px;
  height: 34px;
  background-color: ${({ theme }) => darken(0.05, theme.lightBlue)};
  border-radius: 5px;
  transition: background-color 0.3s ease;
  border: 0;

  &:hover {
    background-color: ${({ theme }) => darken(0.1, theme.lightBlue)};
    cursor: pointer;
    color: ${(props) => props.theme.black};
  }

  &:before {
    content: '…';
    position: absolute;
    left: 0;
    top: -50%;
    width: 100%;
    height: 100%;
    font-size: 36px;
    line-height: 46px;
    font-weight: 300;
    text-align: center;
  }

  @media (min-width: ${(props) => props.theme['gridBreakpoints-md']}) {
    height: 17px;

    &:before {
      line-height: 15px;
    }
  }
`

export const BreadcrumbSelectItem = styled(({ ...props }) => (
  <DropdownItem {...props} />
))`
  &,
  &:hover {
    color: ${(props) => props.theme.black};
    text-decoration: none;
  }
`

export const Increment = styled.span`
  position: relative;
  color: transparent;
  letter-spacing: 2px;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 14px;
    border-left: 1px solid;
    border-bottom: 1px solid;
    top: calc(50% - 12px);
    color: ${(props) => props.theme.black};
  }
`
