import * as Sentry from '@sentry/react'
import {
  EmptySpace,
  EmptySpaceAccessDenied,
  Loader,
  Redirect,
} from 'components'
import { createIntl, useStore, useTranslation } from 'hooks'
import useHasAccess from 'hooks/useHasAccess'
import { ERefusalReason } from 'hooks/useSpecifications/type'
import { ICheckAccess } from 'layouts/Admin/type'
import { OrganizationContext, RouterProvider } from 'providers'
import { FC, useContext } from 'react'
import { Route, RouteComponentProps } from 'react-router-dom'
import { useEffectOnce } from 'react-use'
import { ERouterType } from 'routers/type'
import { EYado } from 'types'

import * as Type from './type'

const UseRouter = (
  type?: ERouterType,
  keepUrlSearchParameters = false
): Type.IUseRouter => {
  const RouteLayout: FC<Type.IRouteLayout> = ({
    component: Component,
    layout: Layout,
    app,
    roles,
    fullWidthStyle = false,
    hideMenu = false,
    requiredSpecifications,
    ...rest
  }) => {
    const intlPath = 'hooks_router'
    const intl = createIntl(intlPath)
    const translation = useTranslation(intl)

    const store = useStore()

    // Initialize store
    useEffectOnce(() => {
      if (type === ERouterType.ADMIN && !store.isLoaded) {
        void store.initialize()
      }
    })

    const isAccessRequired = type === ERouterType.ADMIN
    const access = useHasAccess({ app, roles, requiredSpecifications })

    const { organization } = useContext(OrganizationContext)

    const handleAccessDenied = (reason: ICheckAccess['reason']) => {
      switch (reason) {
        case ERefusalReason.SETTING:
        case ERefusalReason.UNAUTHORIZED:
          return <EmptySpaceAccessDenied />
        case ERefusalReason.PSP_WALLET_SUSPENDED:
          return (
            <Redirect
              to={`/organization/${organization.idOld}/wallet/adyen/verification/account-suspended`}
            />
          )
        case ERefusalReason.PSP_WALLET_BLOCKED:
          return (
            <Redirect
              to={`/organization/${organization.idOld}/wallet/adyen/verification/account-blocked`}
            />
          )
        case ERefusalReason.APP_DISABLED:
          return (
            <EmptySpace
              title={translation.translate('accessDenied.app.missing.title')}
              yado={EYado.ACCESS_DENIED}
              button={{
                text: translation.translate('accessDenied.app.link.text'),
                href: `/organization/${organization.idOld}/applications`,
              }}
            />
          )
        case ERefusalReason.ACCOUNTING_PERIOD:
          return (
            <EmptySpace
              title={translation.translate(
                'accessDenied.accounting.noAccountingPeriod.title'
              )}
              yado={EYado.ACCESS_DENIED}
              button={{
                text: translation.translate(
                  'accessDenied.accounting.noAccountingPeriod.button'
                ),
                href: `/organization/${organization.idOld}/settings/accounting/accounting-periods`,
              }}
            />
          )
        case ERefusalReason.EMAILING_HAS_NO_CAMPAIGNS:
          return (
            <Redirect
              to={`/organization/${organization.idOld}/emailing/campaigns-onboarding`}
            />
          )
        default:
          return <EmptySpaceAccessDenied />
      }
    }

    const renderContent = (props: RouteComponentProps) => {
      if (isAccessRequired) {
        if (!access.isLoaded) {
          return <Loader />
        }
        if (!access.hasAccess) {
          return handleAccessDenied(access.accessDenied?.reason)
        }

        return <Component {...props} />
      }
      return <Component {...props} />
    }

    if (type === ERouterType.ADMIN && !store.isLoaded) {
      return <></>
    }

    return (
      <Route
        {...rest}
        render={(props) => (
          <RouterProvider keepUrlSearchParameters={keepUrlSearchParameters}>
            <Layout
              fullWidthStyle={fullWidthStyle}
              accessSettings={isAccessRequired ? access : null}
              hideMenu={hideMenu}
              requiredSpecifications={requiredSpecifications}
            >
              {renderContent(props)}
            </Layout>
          </RouterProvider>
        )}
      />
    )
  }

  const RouteLayoutSentry = Sentry.withSentryRouting(RouteLayout)

  return { RouteLayout, RouteLayoutSentry }
}

export default UseRouter
