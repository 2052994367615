import classNames from 'classnames'
import { Col, Icon, Row, Tooltip } from 'components'
import { FormikValues, useFormikContext } from 'formik'
import { createIntl, useTranslation } from 'hooks'
import { FC } from 'react'
import { EColor } from 'types'

import { ENavigation, EStepType, IActionButton } from '../type'
import * as Style from './style'
import * as Type from './type'

const StepperFooter: FC<Type.IStepperFooter> = ({
  updateCurrentStep,
  step,
}) => {
  const intlPath = 'components_stepper'
  const intl = createIntl(intlPath)
  const translation = useTranslation(intl)
  const formikContext = useFormikContext<FormikValues>()

  const getButton = (
    isMobile = false,
    navigate?: ENavigation,
    disabled = false,
    onClick?: () => void | boolean | Promise<void | boolean>,
    validate?: () => Promise<boolean>,
    color?: EColor,
    outline = false
  ) => {
    const className = ['px-5']

    const button = {
      className: '',
      onClick: async () => handleClick(navigate, onClick, validate),
      disabled: disabled || formikContext.isSubmitting,
      color:
        color ||
        (isMobile && navigate === ENavigation.PREVIOUS
          ? EColor.TRANSPARENT
          : EColor.BLUE),
      outline: outline || (!isMobile && navigate === ENavigation.PREVIOUS),
    }

    if (button.color === EColor.TRANSPARENT && !button.outline) {
      className.push('text-primary')
    }

    className.push(isMobile ? 'd-lg-none' : 'd-none d-lg-block')
    button.className = className.join(' ')

    return button
  }

  const handleClick = async (
    navigate?: ENavigation,
    onClick?: () => void | boolean | Promise<void | boolean>,
    validate?: () => Promise<boolean>
  ) => {
    const { setSubmitting } = formikContext

    setSubmitting(true)
    const isValid = !validate || (await validate())
    if (!isValid) {
      setSubmitting(false)
      return
    }

    if (onClick) {
      const action = await onClick()
      if (action === false) {
        setSubmitting(false)
        return
      }
    }

    if (navigate) {
      updateCurrentStep((currentStep) =>
        navigate === ENavigation.PREVIOUS ? currentStep - 1 : currentStep + 1
      )
    }
    setSubmitting(false)
  }

  const getActionButton = (action: [string, IActionButton]) => {
    const key = action[0]
    const {
      disabled,
      icon,
      navigate,
      onClick,
      text,
      tooltip,
      validate,
      ...options
    } = action[1]

    const actionButtonText = text || translation.translate(`action.${key}`)

    return (
      <>
        <div id={`stepper-action-${key}`}>
          {[true, false].map((isMobile) => (
            <Style.DesktopActionButton
              {...getButton(
                isMobile,
                navigate,
                disabled,
                onClick,
                validate,
                options.color,
                options.outline
              )}
              key={isMobile.toString()}
            >
              {icon && <Icon icon={icon} className="mr-3" />}
              {navigate === ENavigation.PREVIOUS && (
                <Icon icon={['far', 'chevron-left']} className="mr-3" />
              )}
              {actionButtonText}
              {navigate === ENavigation.NEXT &&
                step.type !== EStepType.INTRODUCTION && (
                  <Icon icon={['far', 'chevron-right']} className="ml-3" />
                )}
            </Style.DesktopActionButton>
          ))}
        </div>
        {tooltip && (
          <Tooltip target={`stepper-action-${key}`}>{tooltip}</Tooltip>
        )}
      </>
    )
  }

  return (
    <Style.StepperFooter>
      <Row className="w-100">
        <Col>
          <Style.StepperActions
            className={classNames(
              step.type === EStepType.INTRODUCTION
                ? 'mt-4 flex-column flex-lg-row'
                : 'mt-0 mt-md-4 flex-column-reverse flex-lg-row justify-content-between'
            )}
          >
            {step.actions &&
              Object.entries(step.actions).map((action) => (
                <div
                  key={action[0]}
                  className={classNames(
                    step.type === EStepType.INTRODUCTION && 'pr-lg-4'
                  )}
                >
                  {getActionButton(action)}
                </div>
              ))}
          </Style.StepperActions>
        </Col>
      </Row>
    </Style.StepperFooter>
  )
}

export default StepperFooter
