import styled, { css } from 'styled-components'
import { EListType } from 'types'

const ListItemBullet = (color: string) => css`
  position: relative;
  &::before {
    content: '';
    position: absolute;
    left: 3px;
    top: 0.5em;
    height: 7px;
    width: 7px;
    background-color: ${({ theme }) => theme[color]};
    border-radius: 50%;
    z-index: 1;
  }
`

export const ListItem = styled(({ color, isTimeline, type, ...rest }) => (
  <li {...rest} />
))`
  ${({ type, color }) => type === EListType.BULLET && ListItemBullet(color)}
  ${({ isTimeline, theme }) =>
    isTimeline &&
    `
    &:not(:last-child) {
      &:after {
        content: '';
        position: absolute;
        width: 1px;
        height: calc(100% + 0.5em);
        background: ${theme.grey};
        top: 0.5em;
        left: 6px;
        z-index: 0;
      }
    }
  `}
`
