import { Background, Button, Select as ReactSelect } from 'components'
import styled, { css } from 'styled-components'

export const Select = styled((props) => <ReactSelect {...props} />)`
  margin: 0;
  justify-content: center;
  min-width: 85px;
`

export const PageNumbersWrapper = styled((props) => <div {...props} />)`
  border: 1px solid transparent;
`

export const Ul = styled((props) => <ul {...props} />)`
  min-height: 50px;
  @media (min-width: ${(props) => props.theme['gridBreakpoints-xl']}) {
    min-height: 30px;
  }
`

const PageChangeButton = css`
  width: 34px;
  font-size: 18px;
  height: 34px;
  padding: 0;
  color: ${({ theme }) => theme.darkGrey};
  box-shadow: none !important;
  border: 0;
  border-radius: 0;

  @media (min-width: ${(props) => props.theme['gridBreakpoints-xl']}) {
    width: 26px;
    height: 16px;
    line-height: 16px;
    font-size: 14px;
  }

  &:hover,
  &:active,
  &:focus {
    color: ${({ theme }) => theme.black} !important;
  }
  &:disabled {
    color: ${({ theme }) => theme.grey} !important;
  }
  svg {
    color: inherit;
  }
`

export const PreviousButton = styled((props) => <Button {...props} />)`
  ${PageChangeButton};
  border-right: 1px solid ${({ theme }) => theme.grey};
`
export const NextButton = styled((props) => <Button {...props} />)`
  ${PageChangeButton};
  border-left: 1px solid ${({ theme }) => theme.grey};
`
export const PageButton = styled(({ active, ...props }) => (
  <button data-active={active} {...props} />
))`
  width: 26px;
  height: 26px;
  background-color: ${({ active, theme }) =>
    active ? theme.blue : 'transparent'};
  box-shadow: none !important;
  padding: 0;
  border-radius: 50%;
  font-size: 12px;
  border: 1px solid transparent;
  outline: 0 !important;
  color: ${({ active, theme }) => (active ? theme.white : theme.black)};
  transition: all 0.3s ease-out;

  &:hover {
    border-color: ${({ theme }) => theme.blue};
    background-color: ${({ active, theme }) =>
      active ? theme.blue : 'transparent'} !important;
    color: ${({ active, theme }) => (active ? theme.white : theme.black)};
  }
`
export const PageNumbersBackground = styled((props) => (
  <Background {...props} />
))`
  border-radius: 25px;
  margin: 1px;
  box-shadow: 0 1px 3px 0 ${(props) => props.theme.lightGrey};
  @media (min-width: ${(props) => props.theme['gridBreakpoints-xl']}) {
    background-color: ${(props) => props.theme.white};
    border-radius: 16px;
  }
`

export const PageNumberDisplay = styled((props) => <li {...props} />)`
  width: 100%;
  min-width: 100px;
  text-align: center;
  line-height: 34px;
`
