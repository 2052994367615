import { useApiError } from 'hooks'
import { useState } from 'react'

import * as Type from './type'

const useTableData = <T, E>() => {
  const apiError = useApiError()

  const [hasData, setHasData] = useState<boolean | undefined>(undefined)

  const getTableData = async (
    query: Promise<Type.ApiResponse<T>>,
    tableContent: (entry: T, refreshTable?: () => void) => E,
    refreshTable?: () => void
  ): Promise<{ data: E[]; totalItems: number }> => {
    try {
      const response = await query
      setHasData(response.totalItems > 0)
      return {
        data: response.data.map((campaign) =>
          tableContent(campaign, refreshTable)
        ),
        totalItems: response.totalItems,
      }
    } catch (error: unknown) {
      apiError.handleApiError(error)
    }
    return {
      data: [],
      totalItems: 0,
    }
  }

  return { hasData, getTableData, isLoading: hasData === undefined }
}

export default useTableData
