import { useSelector } from 'hooks'
import { createIntl, createIntlCache, IntlShape } from 'react-intl'
import { selectLocale } from 'state'
import { EProcessEnv } from 'types'
import { formatLocale } from 'utils'
import { ECaseType, ELocale } from 'utils/Intl/type'

export const fallbackLocale = ELocale.EN_US

export const importMessages = (
  file: string,
  importLocale: ELocale
): Record<string, string> => {
  try {
    // Do not load translation in the test env to apply: https://testing-library.com/docs/guiding-principles
    if (process.env.NODE_ENV === EProcessEnv.TEST) {
      return {}
    }
    return require(`translations/${importLocale}/${file}.${importLocale}.json`)
  } catch (error: unknown) {
    if (importLocale.match(/[a-z]{2}_[A-Z]{2}/)) {
      // No file for regionalized locale
      return require(`translations/${importLocale.substring(
        0,
        2
      )}/${file}.${importLocale.substring(0, 2)}.json`)
    } else if (fallbackLocale !== importLocale) {
      // No file for a global locale
      return require(`translations/${fallbackLocale}/${file}.${fallbackLocale}.json`)
    } else {
      // No file for the fallback locale, should not happen
      return {}
    }
  }
}

/**
 * Create an instance of react-intl and import the translation file
 * @param file - File to use from ./src/translations folder
 * @param locale - Force a language (use case: error component)
 */
const CreateIntl = (file: string, locale?: ELocale): IntlShape => {
  const storeLocale = useSelector(selectLocale)

  if (!locale) {
    locale = storeLocale
  }

  const getIsEmptyMessage = (error: string) => {
    const str = 'Missing message:'
    const messages = importMessages(file, locale as ELocale)
    if (error.indexOf(str) >= 0) {
      const messageKey = error.split('"')[1]
      if (Object.keys(messages).includes(messageKey)) {
        return true
      }
    }
    return false
  }

  return createIntl(
    {
      defaultRichTextElements: {
        // Eslint ignores the fake link tag and fix the React-Intl <br /> type
        a: (chunks) => <a>{chunks}</a>, // eslint-disable-line
        b: (chunks) => <b>{chunks}</b>,
        i: (chunks) => <i>{chunks}</i>,
        p: (chunks) => <p>{chunks}</p>,
        u: (chunks) => <u>{chunks}</u>,
        ul: (chunks) => <ul className="pl-3 my-3">{chunks}</ul>,
        li: (chunks) => <li>{chunks}</li>,
        em: (chunks) => <em>{chunks}</em>,
        br: (<br />) as any, // eslint-disable-line
        sup: (chunks) => <sup>{chunks}</sup>,
        small: (chunks) => <small>{chunks}</small>,
        strong: (chunks) => <strong>{chunks}</strong>,
      },
      locale: formatLocale(locale, ECaseType.KEBAB),
      messages: importMessages(file, locale),
      onError: (error) => {
        if (process.env.NODE_ENV === EProcessEnv.DEVELOPMENT) {
          // Check if there is an empty translation
          const isEmptyMessage = getIsEmptyMessage(error.toString())
          if (!isEmptyMessage) {
            console.error(error)
          }
        }
      },
      onWarn: (warn) => {
        // Fix default warning after using defaultRichTextElements: https://github.com/formatjs/formatjs/pull/3506
        if (
          warn.search(
            '"defaultRichTextElements" was specified but "message" was not pre-compiled.'
          ) === -1
        ) {
          console.warn(warn)
        }
      },
    },
    createIntlCache()
  )
}

export default CreateIntl
