import { API } from 'api/connector'
import { IFormikWebhooksValues } from 'templates/Webhooks/type'

import * as Type from './type'

export const getWebhook = async (id: string): Promise<Type.IApiCrmWebhook> => {
  const webhook = await API.get(`/api/v1/crm/webhooks/${id}`)
  return webhook.data
}

export const deleteWebhook = async (id: string): Promise<void> =>
  API.delete(`/api/v1/crm/webhooks/${id}`)

export const createWebhook = async (
  organization: string,
  values: IFormikWebhooksValues
): Promise<Type.IApiCrmWebhook> => {
  const webhook = await API.post(`/api/v1/crm/webhooks`, {
    organization,
    ...values,
  })
  return webhook.data
}

export const updateWebhook = async (
  id: string,
  organization: string,
  values: IFormikWebhooksValues
): Promise<Type.IApiCrmWebhook> => {
  const webhook = await API.put(`/api/v1/crm/webhooks/${id}`, {
    organization,
    ...values,
  })
  return webhook.data
}
