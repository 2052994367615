export interface IStorePage {
  menuItemActiveId: string // TO REMOVE WHEN MENUOLD HAS BEEN DELETED
  menuSubItemActiveId: string // TO REMOVE WHEN MENUOLD HAS BEEN DELETED
  backLinkHref?: string | null
  supportLabel?: string
  tabs?: Array<IStorePageTab>
  title: string
  subtitle?: string
  pageId?: IPageId
}

export interface IStorePageTab {
  label: string
  href: string
  disabled?: boolean
  isExternal?: boolean
}

export enum ESupportLabels {
  APPLICATION = 'application',
  COMPTA_PARAM_BUDGETS = 'compta_param_budgets',
  COMPTA_REMISE = 'compta_remise',
  ACCOUNTING_CLOSING = 'Accounting_Closing',
  ACCOUNTING_ONBOARDING = 'accounting_onboarding',
  ACCOUNTING_BULKIMPORT = 'accounting_bulkImport',
  COMPTA_PARAM_BANQUE = 'compta_param_banque',
  COMPTA_PARAM_CAISSE = 'compta_param_caisse',
  COMPTA_PARAM_PERIODS = 'compta_param_periodes',
  COMMUNICATION_EMAILING = 'communication_emailing_campagnes',
  COMPTA_PARAM_TIERS = 'compta_param_tiers',
  PARAM_COMPTE_EN_LIGNE_CERTIF = 'param_compte-en-ligne-certif',
  PARAM_COMPTE_EN_LIGNE_MENU = 'param_compte-en-ligne-menu',
  PARAM_COMPTE_EN_LIGNE_MENU_2 = 'param_compte-en-ligne-menu-2',
  LANDING_PAYMENT = 'landing_payment',
  DETAIL_COMPTE_EN_LIGNE_FACTURATION = 'detail_compte-en-ligne-facturation',
  DETAIL_COMPTE_EN_LIGNE_OPERATIONS = 'detail_compte-en-ligne-operations',
  COMMUNAUTE_DONS_MENU = 'communaute_dons_menu',
  EVENTS_MENU = 'events_menu',
  COMMUNAUTE_ADHESIONS_MENU = 'communaute_adhesions_menu',
  BOUTIQUE_MENU = 'boutique_menu',
  COMMUNAUTE_DONS_FORMULAIRE = 'communauté_dons_formulaire',
  EVENTS_FORMULAIRE = 'events_formulaire',
  COMMUNAUTE_ADHESIONS_FORMULAIRE = 'communauté_adhésions_formulaire',
  BOUTIQUE_FORMULAIRE = 'boutique_formulaire',
  EXPORT_CENTER = 'export_center',
  NAVIGATE_NETWORK = 'navigate_network',
  COLLECTS_ONBOARDING = 'collects_onboarding',
  COLLECT_INSTALLMENT = 'collect_installment',
  SETTINGS = 'settings',
  PARAM_ABONNEMENT = 'param_abonnement',
  PAYMENT_REQUEST = 'payment_link',
}

export type IPageId =
  | EGlobalPageId
  | EAccountingPageId
  | EApplicationsPageId
  | EDocumentsPageId
  | ECollectPageId
  | EEmailingPageId
  | EExportCenterPageId
  | EMasterPageId
  | ENetworkPageId
  | EOnboarding
  | EOrderPageId
  | EPlatformPageId
  | ESettingsPageId
  | EWalletPageId
  | EPaymentPageId
  | EProAccountPageId
  | ESignupPageId
  | EStorybookPageId
  | ESubscriptionPageId
  | ETaxReceipts

export enum EGlobalPageId {
  DASHBOARD = 'DASHBOARD',
  HOME = 'HOME',
  STORYBOOK = 'STORYBOOK',
  UPGRADE = 'UPGRADE',
  WEBHOOKS = 'WEBHOOKS',
}

export enum EAccountingPageId {
  ACCOUNTING_BOOKENTRY = 'ACCOUNTING_BOOKENTRY',
  ACCOUNTING_BOOKENTRY_BULKIMPORT = 'ACCOUNTING_BOOKENTRY_BULKIMPORT',
  ACCOUNTING_BOOKENTRY_BULKIMPORT_EXPLANATION = 'ACCOUNTING_BOOKENTRY_BULKIMPORT_EXPLANATION',
  ACCOUNTING_BOOKENTRY_BULKIMPORT_ONBOARDING = 'ACCOUNTING_BOOKENTRY_BULKIMPORT_ONBOARDING',
  ACCOUNTING_BUDGET_HISTORY = 'ACCOUNTING_BUDGET_HISTORY',
  ACCOUNTING_CHECK_RECEIVED = 'ACCOUNTING_CHECK_RECEIVED',
  ACCOUNTING_CHECK_REMITTANCE_FORMS = 'ACCOUNTING_CHECK_REMITTANCE_FORMS',
  ACCOUNTING_CHECK_REMITTANCE_FORMS_DETAIL = 'ACCOUNTING_CHECK_REMITTANCE_FORMS_DETAIL',
  ACCOUNTING_CHECK_REMITTANCE_HISTORY = 'ACCOUNTING_CHECK_REMITTANCE_HISTORY',
  ACCOUNTING_CLOSING = 'ACCOUNTING_CLOSING',
  ACCOUNTING_DOCUMENTS = 'ACCOUNTING_DOCUMENTS',
  ACCOUNTING_ONBOARDING_STEPS = 'ACCOUNTING_ONBOARDING_STEPS',
  ACCOUNTING_ONBOARDING_END = 'ACCOUNTING_ONBOARDING_END',
  ACCOUNTING_SETUP = 'ACCOUNTING_SETUP',
  ACCOUNTING_SETTINGS_ALLOCATION = 'ACCOUNTING_SETTINGS_ALLOCATION',
  ACCOUNTING_SETTINGS_BANKACCOUNTS = 'ACCOUNTING_SETTINGS_BANKACCOUNTS',
  ACCOUNTING_SETTINGS_BUDGET = 'ACCOUNTING_SETTINGS_BUDGET',
  ACCOUNTING_SETTINGS_BUDGET_FORECAST = 'ACCOUNTING_SETTINGS_BUDGET_FORECAST',
  ACCOUNTING_SETTINGS_CHARTOFACCOUNTS = 'ACCOUNTING_SETTINGS_CHARTOFACCOUNTS',
  ACCOUNTING_SETTINGS_PETTY_CASH = 'ACCOUNTING_SETTINGS_PETTY_CASH',
  ACCOUNTING_SETTINGS_THIRD_PARTIES = 'ACCOUNTING_SETTINGS_THIRD_PARTIES',
  ACCOUNTING_RESETINPROGRESS = 'ACCOUNTING_ACCOUNTING_SETTINGS_RESETINPROGRESS',
}

export enum EApplicationsPageId {
  APPLICATIONS_INDEX = 'APPLICATIONS_INDEX',
  APPLICATIONS_DETAIL = 'APPLICATIONS_DETAIL',
}

export enum EDocumentsPageId {
  DOCUMENTS_GENERALMEETING = 'DOCUMENTS_GENERALMEETING',
}

export enum EWalletPageId {
  WALLET_ADYEN_VERIFICATION = 'WALLET_ADYEN_VERIFICATION',
  WALLET_ADYEN_VERIFICATION_ACCOUNT_BLOCKED = 'WALLET_ADYEN_VERIFICATION_ACCOUNT_BLOCKED',
  WALLET_ADYEN_VERIFICATION_ACCOUNT_SUSPENDED = 'WALLET_ADYEN_VERIFICATION_ACCOUNT_SUSPENDED',
  WALLET_ADYEN_VERIFICATION_ORGANIZATION = 'WALLET_ADYEN_VERIFICATION_ORGANIZATION',
  WALLET_ADYEN_VERIFICATION_CONTACT = 'WALLET_ADYEN_VERIFICATION_CONTACT',
  WALLET_ADYEN_VERIFICATION_BANK_ACCOUNTS = 'WALLET_ADYEN_VERIFICATION_BANK_ACCOUNTS',
  WALLET_CHOICE = 'WALLET_CHOICE',
  WALLET_CREATION = 'WALLET_CREATION',
  WALLET_DASHBOARD = 'WALLET_DASHBOARD',
  WALLET_ONBOARDING = 'WALLET_ONBOARDING',
  WALLET_FEES_INVOICING = 'WALLET_FEES_INVOICING',
  WALLET_STATEMENT_BALANCE = 'WALLET_STATEMENT_BALANCE',
  WALLET_STATEMENT_SETTLED = 'WALLET_STATEMENT_SETTLED',
  WALLET_INVOICING = 'WALLET_INVOICING',
  WALLET_INVOICING_DETAIL = 'WALLET_INVOICING_DETAIL',
}

export enum EPaymentPageId {
  PAYMENT_REQUEST_ONBOARDING = 'PAYMENT_REQUEST_ONBOARDING',
  PAYMENT_REQUEST = 'PAYMENT_REQUEST',
}

export enum ECollectPageId {
  COLLECT_EVENT = 'COLLECT_EVENT',
  COLLECT_MEMBERSHIP = 'COLLECT_MEMBERSHIP',
  COLLECT_PRODUCT = 'COLLECT_PRODUCT',
  COLLECT_DONATION = 'COLLECT_DONATION',
  COLLECT_EVENT_INTRODUCTION = 'COLLECT_EVENT_INTRODUCTION',
  COLLECT_MEMBERSHIP_INTRODUCTION = 'COLLECT_MEMBERSHIP_INTRODUCTION',
  COLLECT_PRODUCT_INTRODUCTION = 'COLLECT_PRODUCT_INTRODUCTION',
  COLLECT_DONATION_INTRODUCTION = 'COLLECT_DONATION_INTRODUCTION',
  COLLECT_EVENT_SUCCESS = 'COLLECT_EVENT_SUCCESS',
  COLLECT_MEMBERSHIP_SUCCESS = 'COLLECT_MEMBERSHIP_SUCCESS',
  COLLECT_PRODUCT_SUCCESS = 'COLLECT_PRODUCT_SUCCESS',
  COLLECT_DONATION_SUCCESS = 'COLLECT_DONATION_SUCCESS',
}

export enum EEmailingPageId {
  EMAILING_ONBOARDING = 'EMAILING_ONBOARDING',
  EMAILING_CAMPAIGNS_DRAFT = 'EMAILING_CAMPAIGNS_DRAFT',
  EMAILING_CAMPAIGNS_PROGRAMMED = 'EMAILING_CAMPAIGNS_PROGRAMMED',
  EMAILING_CAMPAIGNS_SENT = 'EMAILING_CAMPAIGNS_SENT',
}

export enum EExportCenterPageId {
  EXPORT_CENTER_INDEX = 'EXPORT_CENTER_INDEX',
  EXPORT_CENTER_ACCOUNTING = 'EXPORT_CENTER_ACCOUNTING',
  EXPORT_CENTER_DONORS = 'EXPORT_CENTER_DONORS',
  EXPORT_CENTER_MEMBERS = 'EXPORT_CENTER_MEMBERS',
  EXPORT_CENTER_DETAILS = 'EXPORT_CENTER_DETAILS',
}

export enum EMasterPageId {
  MASTER_DISCOUNTS = 'MASTER_DISCOUNTS',
  MASTER_SAV = 'MASTER_SAV',
  MASTER_TOOLS = 'MASTER_TOOLS',
}

export enum ENetworkPageId {
  NETWORK = 'NETWORK',
}

export enum EOnboarding {
  ONBOARDING_DEMO = 'ONBOARDING_DEMO',
  ONBOARDING_COLLECT = 'ONBOARDING_COLLECT',
}

export enum EOrderPageId {
  ORDER_INSTALLMENTS = 'ORDER_INSTALLMENTS',
}

export enum EPlatformPageId {
  PLATFORM_CREATION_SIZE = 'PLATFORM_CREATION_SIZE',
  PLATFORM_CREATION_APPS = 'PLATFORM_CREATION_APPS',
}

export enum EProAccountPageId {
  PRO_ACCOUNT_ADMINISTRATORS = 'PRO_ACCOUNT_ADMINISTRATORS',
  PRO_ACCOUNT_DETAILS_IBAN = 'PRO_ACCOUNT_DETAILS_IBAN',
  PRO_ACCOUNT_CONSENT = 'PRO_ACCOUNT_CONSENT',
  PRO_ACCOUNT_CHECKS_REMITTANCE = 'PRO_ACCOUNT_CHECKS_REMITTANCE',
  PRO_ACCOUNT_CHECKS_HISTORY = 'PRO_ACCOUNT_CHECKS_HISTORY',
}

export enum ESettingsPageId {
  SETTINGS_INDEX = 'SETTINGS_INDEX',
  SETTINGS_ACCOUNTING = 'SETTINGS_ACCOUNTING',
  SETTINGS_ACCOUNTING_PERIODS = 'SETTINGS_ACCOUNTING_PERIODS',
  SETTINGS_ACCOUNTING_OPENINGBALANCE = 'SETTINGS_ACCOUNTING_OPENINGBALANCE',
  SETTINGS_COLLECT = 'SETTINGS_COLLECT',
  SETTINGS_CRM = 'SETTINGS_CRM',
  SETTINGS_PAYMENT = 'SETTINGS_PAYMENT',
  SETTINGS_PAYMENT_SEPA = 'SETTINGS_PAYMENT_SEPA',
  SETTINGS_SUBSCRIPTION_DISCOUNT = 'SETTINGS_SUBSCRIPTION_DISCOUNT',
}

export enum ESignupPageId {
  SIGNUP = 'SIGNUP',
}

export enum EStorybookPageId {
  TABS = 'TABS',
}

export enum ESubscriptionPageId {
  SUBSCRIPTION_PLAN_CHOICE = 'SUBSCRIPTION_PLAN_CHOICE',
}

export enum ETaxReceipts {
  TAX_RECEIPTS_INDEX = 'TAX_RECEIPTS_INDEX',
  TAX_RECEIPTS_ISSUED = 'TAX_RECEIPTS_ISSUED',
  TAX_RECEIPTS_SETTINGS = 'TAX_RECEIPTS_SETTINGS',
}
