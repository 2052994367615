export interface IApiNewSubscription {
  name: string
  description: string
  value: {
    type: string
    value: string
  }
  nbMax: number
  validFor: number
  startsAt: string
  endsAt: string
  category: string
  network?: string
}

export interface IApiSubscription {
  '@id': string
  id: string
  name: string
  description: string
  value: {
    type: string
    value: string
  }
  nbMax: number
  validFor: number
  startsAt: string
  endsAt: string
  status: string
  category: string
  network?: string
  usages: Array<IApiSubscriptionDiscountUsage>
}

export interface IApiSubscriptionDiscount {
  '@id': string
  '@type': string
  activeFrom: string
  lastValidDate: string
  createdAt: string
  discountedPrice: {
    amount: string
    currency: string
  }
  initialPrice: {
    amount: string
    currency: string
  }
  organization: string
  subscriptionDiscount: {
    '@type': string
    value: {
      type: string
      value: string
    }
    validFor: string
    discountType: string
  }
}

export type IApiSubscriptionDiscountCategories =
  Array<IApiSubscriptionDiscountCategory>

export interface IApiSubscriptionDiscountCategory {
  '@id': string
  '@type': string
  id: string
  name: string
}

export interface IApiSubscriptionDiscountUsage {
  '@id': string
  createdAt: string
  organization: { '@id': string; name: string }
  rowClickAction: string
}

export enum ESubscriptionPlan {
  COMMUNICATION = 'COMMUNICATION',
  COMPTABILITE = 'COMPTABILITE',
  PRO = 'PRO',
  SERENITE = 'SERENITE',
  PRO_US = 'PRO_US',
  SERENITY_US = 'SERENITY_US',
  LIBERTE = 'LIBERTE',
  LIBERTY_US = 'LIBERTY_US',
}
