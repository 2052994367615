import { countriesWithState } from 'api/_external/Mapbox/data'
import { IMapboxAddress } from 'api/_external/Mapbox/type'
import {
  City,
  Col,
  Country,
  FormGroup,
  Input,
  Postal,
  Row,
  Street,
} from 'components'
import { Field, FieldProps, FormikValues, useFormikContext } from 'formik'
import { createIntl, useTranslation } from 'hooks'
import { FC, useEffect } from 'react'
import { usePrevious } from 'react-use'
import { isObjectEqual } from 'utils'

import * as Data from './data'
import * as Type from './type'

const Address: FC<Type.IFormAddress> = ({
  arePoBoxesAuthorized = true,
  className,
  labels,
  required = false,
}) => {
  const { values, setFieldTouched, setValues } =
    useFormikContext<FormikValues>()
  const intl = createIntl('components_address')
  const translation = useTranslation(intl)
  const prevValues = usePrevious(values)

  // Clean the hidden inputs if the address changes and the latitude and longitude do not change and ignore street2
  useEffect(() => {
    if (
      !isObjectEqual(values, prevValues || {}) &&
      values.street2 === prevValues?.street2 &&
      values.latitude === prevValues?.latitude &&
      values.longitude === prevValues?.longitude
    ) {
      const newValues: { [key: string]: string } = {
        administrativeArea2: '',
        latitude: '',
        longitude: '',
      }
      if (!countriesWithState.includes(values?.country)) {
        newValues.administrativeArea1 = ''
      }
      setValues({ ...values, ...newValues })
    }
  }, [values, prevValues, setValues])

  const validatePoBoxes = (): undefined | string => {
    if (arePoBoxesAuthorized) {
      return ''
    }

    // PO box error (Adyen code: https://gitlab.com/assoconnect/assoconnect/-/issues/18640#note_514520231)
    const pobRegexs = {
      EN: `([\\w\\s*\\W]*(P(OST)?\\s*?\\.?\\s*((O(FF(ICE)?)?)?(X)?\\s*?\\.?\\s*(B(IN|OX|\\.?))|B(IN|OX))+)|MAILBOX)($|(\\.|\\d+|\\s+)[\\w\\s*\\W]*)`,
      FR: `([\\w\\s*\\W]*(BOITE|BOÎTE|(B(OITE|OÎTE)?\\.?\\s*(P(OSTALE)?\\.?\\s*)+)))($|(\\d+|\\s+)[\\w\\s*\\W]*)`,
      DE: `([\\w\\s*\\W]*POSTFACH)($|(\\d+|\\s+)[\\w\\s*\\W]*)`,
      NL: `([\\w\\s*\\W]*P(OST)?\\.?\\s*(B(US)?\\.?\\s*(NUMMER)?\\s*))($|(\\d+|\\s+)[\\w\\s*\\W]*)`,
      IT: `([\\w\\s*\\W]*C(AS(ELLA)?)?\\.?\\s*(P(OS(TALE)?)?\\.?\\s*))($|(\\d+|\\s+)[\\w\\s*\\W]*)`,
      ES: `([\\w\\s*\\W]*AP(ARTADO|TDO|TO)\\.?\\s*((DE)?\\s*(CORREOS)\\s*)?)($|(\\d+|\\s+)[\\w\\s*\\W]*)`,
    }
    const pobRegexsPerCountry: {
      [key: string]: string[]
    } = {
      GB: [pobRegexs.EN],
      US: [pobRegexs.EN],
      IE: [pobRegexs.EN],
      FR: [pobRegexs.FR],
      DE: [pobRegexs.DE, pobRegexs.EN],
      NL: [pobRegexs.NL],
      IT: [pobRegexs.IT],
      ES: [pobRegexs.ES],
      AT: [pobRegexs.DE],
      AU: [pobRegexs.EN],
      BE: [pobRegexs.FR, pobRegexs.NL],
      CA: [pobRegexs.FR, pobRegexs.EN],
      CH: [pobRegexs.FR, pobRegexs.DE, pobRegexs.IT],
      LU: [pobRegexs.FR, pobRegexs.DE],
    }
    if (pobRegexsPerCountry[values.country]) {
      const pobRegex = new RegExp(
        pobRegexsPerCountry[values.country].join('|'),
        'i'
      )
      const pobError = translation.translate('errors.pob')

      // Street 1
      if (
        values['street1'].match(pobRegex) ||
        values['street2'].match(pobRegex)
      ) {
        return pobError
      }
    }
  }

  const handleSelectedItemChange = (address: IMapboxAddress | undefined) => {
    const fieldsToUpdate = Data.addressFieldNames.filter(
      (field) => field !== 'street1'
    )

    const updatedValues = values
    for (const field of fieldsToUpdate) {
      setFieldTouched(field, true)
      updatedValues[field] =
        (address && address[field as keyof IMapboxAddress]) || ''
    }

    setValues(updatedValues)
  }

  return (
    <FormGroup className={className}>
      <Street
        label={labels?.street}
        name="street1"
        onSelectedItemChange={handleSelectedItemChange}
        handleValidation={validatePoBoxes}
        required={required}
      />
      <Input
        label={translation.translate('street2.label')}
        maxLength={100}
        name="street2"
        placeholder={translation.translate('street2.placeholder')}
      />
      <Row>
        <Col sm={8}>
          <City name="city" required={required} />
        </Col>
        <Col sm={4}>
          <Postal country={values?.country} name="postal" required={required} />
        </Col>
      </Row>
      <Country name="country" required={required} />
      {countriesWithState.includes(values?.country) ? (
        <Input
          label={translation.translate('administrativeArea1.label')}
          name="administrativeArea1"
          placeholder={translation.translate('administrativeArea1.placeholder')}
          maxLength={100}
          required={required}
        />
      ) : (
        <Field
          name="administrativeArea1"
          children={({ field }: FieldProps) => (
            <input type="hidden" {...field} />
          )}
        />
      )}
      <Field
        name="administrativeArea2"
        children={({ field }: FieldProps) => <input type="hidden" {...field} />}
      />
      <Field
        name="latitude"
        children={({ field }: FieldProps) => <input type="hidden" {...field} />}
      />
      <Field
        name="longitude"
        children={({ field }: FieldProps) => <input type="hidden" {...field} />}
      />
    </FormGroup>
  )
}

export default Address
