import styled from 'styled-components'

export const Background = styled(({ menuOpened, color, border, ...rest }) => (
  <div {...rest} />
))`
  width: 300px;
  height: 100%;
  display: block;
  ${(props) => !props.menuOpened && 'display: none;'};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 222;
  @media (min-width: ${(props) => props.theme['gridBreakpoints-md']}) {
    width: 220px;
    display: block;
  }
  transition: background-color 0.3s ease;
  background-color: ${({ theme, color }) => theme[color]};
  ${({ border, theme }) =>
    border !== null && `border-left: 9px solid ${theme[border]};`};
  box-shadow: 0 0 12px 0 ${(props) => props.theme.lightGrey};
`

export const Menu = styled(({ menuOpened, ...rest }) => <nav {...rest} />)`
  width: 300px;
  ${(props) => !props.menuOpened && 'display: none;'};
  position: absolute;
  top: 0;
  left: 0;
  padding-bottom: 100px;
  z-index: 333;
  word-wrap: break-word;
  transition: width linear 0.2s;
  @media (min-width: ${(props) => props.theme['gridBreakpoints-md']}) {
    width: 220px;
    display: block;
    padding-bottom: 0;

    font-size: 1rem;
  }
`
