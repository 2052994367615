import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IStoreSpecifications } from 'state/type'

export const specificationsInitialState: IStoreSpecifications = {
  ACCOUNTING_SETUP: undefined,
  COLLECT: undefined,
  SUBSCRIPTION: undefined,
  WALLET_VERIFIED: undefined,
}

const specificationsSlice = createSlice({
  name: 'specifications',
  initialState: specificationsInitialState,
  reducers: {
    resetSpecifications: () => specificationsInitialState,
    setSpecifications: (state, action: PayloadAction<IStoreSpecifications>) =>
      action.payload,
  },
})

export const { setSpecifications, resetSpecifications } =
  specificationsSlice.actions
export default specificationsSlice.reducer
