import { CustomInput } from 'reactstrap'
import styled, { css } from 'styled-components'

export const checkInputStyle = css`
  .custom-control-label {
    font-weight: 500;
    vertical-align: middle;

    &::after {
      cursor: pointer;
    }
  }
  /* Hack fix the right margin when there is no label */
  padding-left: 1.4rem;

  .custom-control-label {
    &::before {
      left: -1.4rem;
    }
    &::after {
      left: -1.4rem;
    }
    span:not(:empty) {
      display: inline-block;
      margin-left: 0.5rem;
    }
  }
`

export const Radio = styled(CustomInput)`
  ${checkInputStyle}
`
