import { ECustomFieldType } from 'api/type'
import { Currency } from 'dinero.js'

export enum ECollectStatus {
  PUBLISHED = 'PUBLISHED',
  DRAFT = 'DRAFT',
  SUSPENDED = 'SUSPENDED',
  DELETED = 'DELETED',
}

export enum ECollectType {
  EVENT = 'EVENT',
  MEMBERSHIP = 'MEMBERSHIP',
  PRODUCT = 'PRODUCT',
  DONATION = 'DONATION',
}

export type ICollect = {
  '@context': string
  '@id': string
  '@type': string
  availableOnline: boolean
  contactType: string
  createdAt: string
  description: string | null
  email: string
  emailReminderEnabled: boolean
  endsAt: string
  eventEndsAt?: string
  eventStartsAt?: string
  hasRegularMembership?: boolean
  id: string
  isPaymentDisabled: boolean
  name: string
  organization: string
  phone: string
  publicUrl: string
  startsAt: string
  status: ECollectStatus
  termsAndConditions: string | null
  thumbUrl: string | null
  type: ECollectType
  websitePageId: string | null
  withValidation: boolean
}

export type ICollectExtraData = {
  '@context': string
  '@id': string
  '@type': string
  collectId: string
  documents: null | Array<{
    '@context': string
    '@id': string
    '@type': string
    name: string
    url: string
  }>
  coverUrl: null | string
  primaryColor: string
}

export enum ERecurrence {
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
  YEARLY = 'yearly',
}

export type TPricingPlans = {
  '@id': string
  '@type': string
  amount: { amount: number; currency: Currency }
  id: string
  label: string
  priceFixed: boolean
  recurrent: null | ERecurrence
}

export type ICollectPricingPlans = Array<TPricingPlans>

export interface ICollectResults {
  data: Array<ICollect>
  totalItems: number
}

export type ICollectPage = { pageId: number; pageName: string }

export type IDuplicateParams = {
  durationStartDate?: string
  durationEndDate?: string
  durationMonths?: number
}

export interface ICollectApiDetails {
  '@id': string
  '@type': string
  id: string
  amountCommitted: { amount: number; currency: string }
  nbSold: number
}

export type ICollectDetails = {
  amountCommitted: null | { amount: number; currency: string }
  nbSold: null | number
}

export type ICollectsListDetails = {
  [key: string]: ICollectDetails
}

export type ICustomFieldCategory = {
  '@type': string
  id: string
  name: string
  customFields: Array<ICustomField>
  position: number
}

export type ICustomField = {
  '@id': string
  '@type': string
  id: string
  entityId: string
  contactType: string
  name: string
  type: ECustomFieldType
  category: string
  possibleValues: Array<string>
  algoliaId: number
  isRequired: boolean
  position: number
}

export interface ICollectCustomFields {
  '@context': string
  '@id': string
  '@type': string
  collectId: string
  customFieldCategories: Array<ICustomFieldCategory>
}

export enum ENativeFieldRequired {
  NOT_REQUIRED = 'NOT_REQUIRED',
  OPTIONAL = 'OPTIONAL',
  REQUIRED = 'REQUIRED',
}

export enum ENativeFieldType {
  DATE_OF_BIRTH = 'dateOfBirth',
  DATE_OF_BIRTH_FOR_PUBLIC_FUNNEL = 'dateOfBirthForPublicFunnel',
  EMAIL = 'email',
  EMAIL_FOR_PUBLIC_FUNNEL = 'emailForPublicFunnel',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  PHONE_LANDLINE = 'phoneLandline',
  PHONE_MOBILE = 'phoneMobile',
  POSTAL_ADDRESS = 'postalAddress',
  POSTAL_ADDRESS_FOR_PUBLIC_FUNNEL = 'postalAddressForPublicFunnel',
  SEX = 'sex',
  SEX_FOR_PUBLIC_FUNNEL = 'sexForPublicFunnel',
}

export enum ESexConfiguration {
  NOT_REQUIRED = 'NOT_REQUIRED',
  BINARY_OPTIONAL = 'BINARY_OPTIONAL',
  NON_BINARY_OPTIONAL = 'NON_BINARY_OPTIONAL',
  BINARY_REQUIRED = 'BINARY_REQUIRED',
  NON_BINARY_REQUIRED = 'NON_BINARY_REQUIRED',
}

export interface ICollectNativeFields {
  '@context': string
  '@id': string
  '@type': string
  collectId: string
  dateOfBirth: ENativeFieldRequired
  email: ENativeFieldRequired
  firstName: ENativeFieldRequired
  id: string
  lastName: ENativeFieldRequired
  phoneLandline: ENativeFieldRequired
  phoneMobile: ENativeFieldRequired
  postalAddress: ENativeFieldRequired
  sex: ENativeFieldRequired
  dateOfBirthForPublicFunnel: ENativeFieldRequired
  sexForPublicFunnel: ESexConfiguration
  postalAddressForPublicFunnel: ENativeFieldRequired
  emailForPublicFunnel: ENativeFieldRequired
}

export interface ISendCollectDonation {
  paymentUrl: string
}
