import { Button } from 'components'
import styled from 'styled-components'

export const RadioButtonsButton = styled(({ color, ...props }) => (
  <Button {...props} />
))`
  display: flex;
  align-items: center;
  justify-content: center;
  &.active {
    background-color: ${({ color, ...props }) => props.theme[color]} !important;
    box-shadow: none !important;
  }
`
