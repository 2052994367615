import { API } from 'api/connector'
import { IApiOperation } from 'api/type'

export const getOperation = async (
  operationId: string
): Promise<IApiOperation> => {
  const operation = await API.get(`/api/v1/operations/${operationId}`)
  return operation.data
}

export const createOperationAccountingEntry = async (
  id: string
): Promise<{ success: boolean }> => {
  const accountingEntryExport = await API.post(
    `/api/v1/operations/${id}/export`,
    {}
  )
  return accountingEntryExport.data
}
