import { API } from 'api/connector'

import * as Type from './type'

export const getSupportMessages = async (): Promise<Type.ISupportMessage> => {
  const message = await API.get('/api/v1/support/message/read')
  return message.data
}

export const setSupportMessage = async (
  messageFr: string | null,
  messageEn: string | null
): Promise<{ success: boolean }> =>
  API.post('/api/v1/support/message/write', {
    messageFr,
    messageEn,
  })
