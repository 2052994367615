import { Col, Link, ListItem, Row } from 'components'
import { useSelector, useTranslation } from 'hooks'
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { selectBrand } from 'state'
import { EColor, EListType } from 'types'
import { EBrand } from 'utils'

import * as Type from './type'

const List: FC<Type.IList> = ({
  color = EColor.GREEN,
  cols = 1,
  translationIds,
  type = EListType.BULLET,
}) => {
  const intl = useIntl()
  const translation = useTranslation(intl)
  const colSize = Math.ceil(translationIds.length / cols)
  const translationIdsPerCols = Array.from({ length: cols }, (value, index) =>
    translationIds.slice(index * colSize, index * colSize + colSize)
  )
  const brand = useSelector(selectBrand)
  return (
    <Row>
      {translationIdsPerCols.map((col, colIndex) => (
        <Col key={colIndex} md={Math.floor(12 / cols)}>
          <ul className="pl-0">
            {col.map((item, itemIndex) => {
              const isSiteTip1 = item === 'applications.SITE.tip.1'
              return (
                <ListItem key={itemIndex} color={color} type={type}>
                  {isSiteTip1 && brand.name !== EBrand.SPRINGLY ? (
                    <span>
                      {translation.translate(item, {
                        link: (
                          <Link
                            href={`https://info.assoconnect.com/creer-site-web-association-guide`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {translation.translate(
                              'applications.SITE.tip.1.linkText'
                            )}
                          </Link>
                        ),
                      })}
                    </span>
                  ) : (
                    translation.translate(item)
                  )}
                </ListItem>
              )
            })}
          </ul>
        </Col>
      ))}
    </Row>
  )
}

export default List
