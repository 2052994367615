export interface IApiOperation {
  '@id': string
  amount: { amount: number; currency: string }
  appId: string
  publicComment?: string
  bankAccountApiDto: { '@id': string; bankName: string }
  settledAt: string
  status: string
  transactionId: number
  transactionParentId: number
  paymentRequestId: string | null
  collectName: string
  type: EOperationType
  person?: { '@id': string; firstName: string; lastName: string }
  invoiceId?: string
  accountingRecordId: string | null
  accountingExportStatus: string
}

export enum EOperationType {
  FEE = 'FEE',
  PAYOUT = 'PAYOUT',
  PAYMENT = 'PAYMENT',
  REFUND = 'REFUND',
  REGULARIZATION_IN = 'REGULARIZATION_IN',
  REGULARIZATION_OUT = 'REGULARIZATION_OUT',
  CHARGEBACK = 'CHARGEBACK',
}
