import { checkInputStyle } from 'components/Form/Radio/style'
import { CustomInput } from 'reactstrap'
import styled from 'styled-components'

export const Checkbox = styled(CustomInput)`
  .custom-control-label {
    vertical-align: top !important;

    &::before {
      border-radius: 0.25rem;
    }
  }
  ${checkInputStyle}
`
