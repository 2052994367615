import { API } from 'api/connector'
import * as Type from 'api/type'
import { FormikValues } from 'formik'

export const createPettyCash = async (
  id: string,
  values: FormikValues
): Promise<Type.IApiPettyCash> => {
  const pettyCash = await API.post(`/api/v1/petty_cash`, {
    ...values,
    organization: `/api/v1/organizations/${id}`,
  })
  return pettyCash.data
}

export const getPettyCash = async (
  pettyCashId: string
): Promise<Type.IApiPettyCash> => {
  const pettyCash = await API.get(`/api/v1/petty_cash/${pettyCashId}`)
  return pettyCash.data
}

export const deletePettyCash = async (
  id: string | undefined
): Promise<{ status: number }> => API.delete(`/api/v1/petty_cash/${id}`)

export const updatePettyCash = async (
  id: string,
  values: FormikValues
): Promise<Type.IApiPettyCash> => {
  const pettyCash = await API.put(`/api/v1/petty_cash/${id}`, {
    ...values,
  })
  return pettyCash.data
}
