import { Input } from 'components'
import { Field, FieldProps, FormikValues, useFormikContext } from 'formik'
import { createIntl, useTranslation } from 'hooks'
import { ChangeEvent, FC, useState } from 'react'

import * as type from './type'
import { formatCheckNumberDisplayValue } from './utils'

const CheckNumber: FC<type.ICheckNumber> = ({
  label,
  name,
  required = false,
  className,
  ...rest
}) => {
  const intl = createIntl('components_checknumber')
  const translation = useTranslation(intl)
  const { values, setFieldValue, setFieldTouched } =
    useFormikContext<FormikValues>()

  const fieldValue = values[name]
  const [inputValue, setInputValue] = useState<string>(
    formatCheckNumberDisplayValue(fieldValue)
  )

  const parseFormValue = (value: string): string => value.replace(/\s+/g, '')

  const validate = (value: string | undefined): undefined | string => {
    if (!value && required) {
      return translation.translate('validate.required')
    }

    if (!value || parseFormValue(value).length !== 31) {
      return translation.translate('validate.incorrect')
    }
    return undefined
  }

  const handleBlur = () => {
    const parsedValue = parseFormValue(inputValue)

    setFieldValue(name, parsedValue)
    setFieldTouched(name, true)
    setInputValue(formatCheckNumberDisplayValue(parsedValue))
  }

  return (
    <Field
      name={name}
      validate={validate}
      required={required}
      children={({ field }: FieldProps) => (
        <Input
          fieldName={name}
          label={label}
          {...field}
          placeholder={translation.translate('placeholder')}
          value={inputValue}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            event.preventDefault()
            setInputValue(event.target.value)
          }}
          onBlur={handleBlur}
          minLength={31}
          maxLength={33}
          required={required}
          {...rest}
        />
      )}
    />
  )
}

export default CheckNumber
