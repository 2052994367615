import classNames from 'classnames'
import { Badge, Button, Col, Collapse, Form, Row } from 'components'
import { ISetFieldValue, ISubmitForm } from 'components/Form/type'
import { FormikValues, useFormikContext } from 'formik'
import { useSelector, useTranslation } from 'hooks'
import { FC, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { selectSidePanel } from 'state'
import { EColor } from 'types'
import { getMonthEndsFromDate, isObjectEqual, sleep } from 'utils'

import * as Data from './data'
import * as Style from './style'
import * as Type from './type'

export const updateDateFilters = async (
  value: string | undefined,
  setFieldValue: ISetFieldValue,
  submitForm: ISubmitForm
): Promise<void> => {
  if (!value) {
    return
  }

  const selectedMonth = getMonthEndsFromDate(value)
  if (setFieldValue) {
    setFieldValue('startDate', selectedMonth.start)
    setFieldValue('endDate', selectedMonth.end)
  }

  await submitForm()
}

const TableFilter: FC<Type.ITableFilter> = ({
  children,
  loading,
  onSubmitFilter,
  resetPageNumber,
  resultsNumber,
  mainFilterField,
  setHaveFilter,
  displayMoreFilters,
}) => {
  const intl = useIntl()
  const translation = useTranslation(intl)
  const [isOpen, setIsOpen] = useState(false)

  const { values, initialValues, setFieldValue } =
    useFormikContext<FormikValues>()
  const sidePanel = useSelector(selectSidePanel)

  const previousValues = useRef<FormikValues>(initialValues)

  useEffect(() => {
    setHaveFilter(!isObjectEqual(initialValues, values))
  }, [initialValues, values, setHaveFilter])

  useEffect(() => {
    if (!isObjectEqual(previousValues.current, values)) {
      previousValues.current = values
      resetPageNumber()
    }
  }, [values, resetPageNumber])

  return (
    <Form
      onChange={(event) =>
        sleep().then(async () => {
          if ((event.target as Element).classList.contains('flatpickr-input')) {
            return undefined
          } else {
            await onSubmitFilter()
          }
        })
      }
    >
      <Style.Background className="pb-3">
        <Row className="align-items-center">
          <Style.MainFilterCol sm={8} lg={6} xl={5}>
            {mainFilterField}
          </Style.MainFilterCol>
          {displayMoreFilters && (
            <Col
              sm={4}
              lg="auto"
              className="px-xs-0 text-center text-sm-center text-md-right mt-3 mt-sm-0"
            >
              <Style.CollapseButton
                onClick={() => setIsOpen(!isOpen)}
                color={EColor.BLUE}
                outline={!isOpen}
              >
                {translation.translate(
                  `form.collapseButton.${isOpen ? 'isOpen' : 'isClosed'}`
                )}
              </Style.CollapseButton>
            </Col>
          )}

          {!loading && (
            <>
              <Col md="auto" className="pt-3 pt-lg-0">
                <Style.BadgeWrapper className="d-flex justify-content-center">
                  <Badge
                    className="align-self-center"
                    color={
                      Data.resultsNumberBadge[
                        resultsNumber > 0 ? 'results' : 'noResults'
                      ].color
                    }
                    icon={
                      Data.resultsNumberBadge[
                        resultsNumber > 0 ? 'results' : 'noResults'
                      ].icon
                    }
                  />
                  <span className="ml-2 align-self-center font-weight-bold">
                    {translation.translate('form.badge.text.result', {
                      count: resultsNumber,
                    })}
                  </span>
                </Style.BadgeWrapper>
              </Col>
              {!isObjectEqual(initialValues, values) && (
                <Col
                  md="auto"
                  className={classNames('px-0 pt-3', {
                    'pt-lg-3': sidePanel.isOpen,
                    'pt-lg-0': !sidePanel.isOpen,
                  })}
                >
                  <div className="d-flex justify-content-center">
                    <Button
                      color={EColor.TRANSPARENT}
                      onClick={async () => {
                        Object.keys(values).forEach((value) =>
                          setFieldValue(value, '')
                        )

                        await onSubmitFilter()
                      }}
                    >
                      {translation.translate(`form.eraseFiltersButton`)}
                    </Button>
                  </div>
                </Col>
              )}
            </>
          )}
        </Row>
        <Collapse isOpen={isOpen}>
          <hr />
          {children}
        </Collapse>
      </Style.Background>
    </Form>
  )
}

export default TableFilter
