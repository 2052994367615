import styled from 'styled-components'

export const Padding = styled.div`
  padding: 0;
  position: relative;
`

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`

export const Video = styled.div`
  display: inline-block;
  min-height: 167px;
  height: 100%;
  position: relative;
  min-width: 300px;
  width: 100%;
`
