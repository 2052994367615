import classNames from 'classnames'
import Icon from 'components/Icon'
import { useHistory, useSelector } from 'hooks'
import { FC, createRef, useEffect, useRef } from 'react'
import { selectPage } from 'state'
import { IStorePageTab } from 'state/type'

import * as Style from './style'
import * as Type from './type'

const getElementCoords = (element: Type.ITabPosition) => ({
  width: `${element.offsetWidth}px`,
  left: `${element.offsetLeft}px`,
})

const Tabs: FC = () => {
  const { tabs } = useSelector(selectPage)
  const history = useHistory()
  const currentLocation = window.location.pathname
  const refTab = createRef<HTMLDivElement>()
  const refCurrentCoords = useRef<{
    width: string
    left: string
  }>()

  useEffect(() => {
    if (refTab.current) {
      refCurrentCoords.current = getElementCoords(refTab.current)
    }
  }, [refTab])

  return (
    <>
      {tabs && tabs.length > 0 && (
        <Style.Tabs className="d-flex align-items-center">
          <Style.Scroll>
            {tabs.map((tab: IStorePageTab, key: number) => {
              const ref = currentLocation === tab.href ? refTab : null
              return (
                <Style.Tab
                  active={currentLocation === tab.href}
                  disabled={tab.disabled}
                  className={classNames(
                    { 'ml-0': key === 0 },
                    'd-inline-block pb-1'
                  )}
                  onClick={() => {
                    if (!tab.disabled) {
                      tab.isExternal
                        ? window.open(tab.href, '_blank')
                        : history.push(tab.href, null, true)
                    }
                  }}
                  key={key}
                >
                  <div ref={ref}>
                    {tab.label}
                    {tab.isExternal && (
                      <Icon
                        className="ml-2"
                        icon={['far', 'arrow-up-right-from-square']}
                      />
                    )}
                  </div>
                </Style.Tab>
              )
            })}
            <Style.Line
              style={{
                left: refCurrentCoords.current?.left,
                width: refCurrentCoords.current?.width,
              }}
            />
          </Style.Scroll>
        </Style.Tabs>
      )}
    </>
  )
}

export default Tabs
