import { RadioButtons as ReactRadioButtons } from 'components'
import { IRadioButtons } from 'components/Form/RadioButtons/type'
import { FC } from 'react'
import { EColor } from 'types'

const RadioButtons: FC<IRadioButtons> = (props) => (
  <ReactRadioButtons color={EColor.PUBLIC_MAIN_COLOR} {...props} />
)

export default RadioButtons
