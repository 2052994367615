import { API } from 'api/connector'
import * as Type from 'api/type'
import { IApiNonprofit, IApiNonprofitAccountingSettings } from 'api/type'

import { IApiAccountingSettings } from './type'

export const createAccountingSettings = async (
  nonProfitId: IApiNonprofit['id'],
  accountingSettings: Partial<IApiAccountingSettings>
): Promise<IApiAccountingSettings> => {
  const accountingSettingsUpdated = await API.post(
    `/api/v1/accounting_settings`,
    {
      ...accountingSettings,
      nonprofit: nonProfitId,
    }
  )
  return accountingSettingsUpdated.data
}

export const updateAccountingSettings = async (
  id: IApiNonprofitAccountingSettings['id'],
  accountingSettings: Partial<Type.IApiNonprofitAccountingSettings>
): Promise<Type.IApiCollectsSettings> => {
  const accountingSettingsUpdated = await API.put(
    `/api/v1/accounting_settings/${id}`,
    accountingSettings
  )
  return accountingSettingsUpdated.data
}
