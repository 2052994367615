import { Progress as ReactStrapProgress } from 'reactstrap'
import styled from 'styled-components'

export const Progress = styled(ReactStrapProgress)`
  .progress-bar {
    border-radius: 2.143rem;
    ${({ value }) => value && value > 0 && 'min-width: 40px'};
    ${({ color, theme }) =>
      !color &&
      `background: linear-gradient(
    to right,
    ${theme.blue} 0%,
    ${theme.green} 100%
  )`};
  }
`
