import { Button, Display } from 'components'
import { createIntl } from 'hooks'
import { FC, useState } from 'react'
import { ModalBody } from 'reactstrap'
import { EColor } from 'types'

import * as Style from './style'
import * as Type from './type'
import { EModalType } from './type'

const ModalConfirm: FC<Type.IModalConfirm> = ({
  buttonsText,
  isOpen,
  confirmIsDisabled = false,
  callbackConfirm,
  callbackCancel,
  size = 'sm',
  title,
  description,
  type,
  isProcessing,
  children,
  ...rest
}) => {
  const intlConfirm = createIntl('components_confirm')
  const [isDisabled, setIsDisabled] = useState(false)

  const toggle = async (status: string) => {
    setIsDisabled(true)
    if (status === 'confirm') {
      await callbackConfirm()
    } else if (callbackCancel) {
      await callbackCancel()
    }
    setIsDisabled(false)
  }

  return (
    <Style.ModalContainer
      centered={true}
      isOpen={isOpen}
      size={size}
      toggle={() => toggle('cancel')}
      {...rest}
    >
      <Style.Header className="pb-0" toggle={() => toggle('cancel')} />
      <ModalBody className="py-0 mx-4">
        {title && (
          <Display type="h5" className="pb-2">
            {title}
          </Display>
        )}
        <div className="mb-1">{description}</div>
        {children}
      </ModalBody>

      <Style.Footer className="mb-2 text-center mx-4">
        <Button
          className="text-uppercase"
          color={EColor.TRANSPARENT}
          onClick={() => toggle('cancel')}
        >
          {buttonsText?.cancel ||
            intlConfirm.formatMessage({ id: 'button.cancel' })}
        </Button>
        <Button
          className="text-uppercase"
          color={type === EModalType.DELETE ? EColor.RED : EColor.BLUE}
          onClick={() => toggle('confirm')}
          disabled={confirmIsDisabled || isDisabled}
          isLoading={isProcessing}
        >
          {buttonsText?.confirm ||
            intlConfirm.formatMessage({ id: `button.${type}` })}
        </Button>
      </Style.Footer>
    </Style.ModalContainer>
  )
}

export default ModalConfirm
