import classNames from 'classnames'
import { Input } from 'components'
import { additionalElements } from 'components/Form/Input/type'
import { Field, FieldProps } from 'formik'
import { createIntl, useSelector, useTranslation } from 'hooks'
import { NonprofitContext } from 'providers'
import { FC, useContext, useRef } from 'react'
import { selectLocale } from 'state'
import { EBrand, getCurrencySymbol, getLocaleShortcode, uniqId } from 'utils'
import { ELocale } from 'utils/Intl/type'

import * as Type from './type'

const getAppendPrepend = (
  currency: string,
  type: Type.ENumericType,
  append?: additionalElements,
  prepend?: additionalElements
) => {
  const inputElements: {
    append?: additionalElements
    prepend?: additionalElements
  } = {
    append,
    prepend,
  }
  if (type === Type.ENumericType.PERCENTAGE) {
    inputElements.append = { text: '%' }
  }
  if (type === Type.ENumericType.CURRENCY) {
    inputElements[currency === 'USD' ? 'prepend' : 'append'] = {
      text: getCurrencySymbol(currency),
    }
  }

  return inputElements
}

const getLocale = (brand: EBrand) => {
  if (brand === EBrand.ASSOCONNECT) {
    return ELocale.FR_FR
  }

  return ELocale.EN_US
}

const Numeric: FC<Type.INumeric> = ({
  append,
  className,
  currency,
  helpText,
  label,
  max,
  min,
  minLength,
  name,
  numberType = Type.ENumericType.NUMBER,
  placeholder,
  prepend,
  required = false,
  brand,
  ...rest
}) => {
  const intl = createIntl('components_number', brand && getLocale(brand))
  const translation = useTranslation(intl)
  const refUniqueId = useRef(uniqId('numeric'))
  const { nonprofit } = useContext(NonprofitContext)
  const locale = useSelector(selectLocale)

  const validate = (value: string): undefined | string => {
    const number = Number(value)

    // Required
    if (required && !number) {
      return translation.translate('validate.required')
    }
    if (value !== '') {
      // Max value
      if (max !== undefined && number > max) {
        return translation.translate('validate.max', { maxValue: max })
      }
      // Min value
      if (min !== undefined && number < min) {
        return translation.translate('validate.min', { minValue: min })
      }
      // Min length
      if (minLength !== undefined && number.toString().length < minLength) {
        return `${translation.translate('validate.minLength', {
          minLength: minLength,
        })} (${'X'.repeat(minLength)})`
      }
    }
  }
  return (
    <Field
      name={name}
      validate={validate}
      children={({ field, form: { errors, touched } }: FieldProps) => {
        const invalid = name && !!(touched[name] && errors[name])

        return (
          <>
            <Input
              label={label}
              required={required}
              {...field}
              className={classNames(className, { 'is-invalid': invalid })}
              id={refUniqueId.current}
              name={name}
              placeholder={
                placeholder || translation.translate('default.placeholder')
              }
              type="number"
              inputMode="decimal"
              lang={getLocaleShortcode(locale)}
              {...getAppendPrepend(
                currency || nonprofit.currency,
                numberType,
                append,
                prepend
              )}
              helpText={helpText}
              {...rest}
            />
          </>
        )
      }}
    />
  )
}

export default Numeric
