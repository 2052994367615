import { API } from 'api/connector'
import {
  IApiNewSubscription,
  IApiOrganization,
  IApiSubscription,
  IApiSubscriptionDiscount,
  IApiSubscriptionDiscountCategories,
  IApiSubscriptionDiscountCategory,
} from 'api/type'
import { IFilterBag } from 'components/Table/type'

export const createSubscriptionDiscount = async (
  newSubscriptionDiscount: IApiNewSubscription
): Promise<IApiSubscription> => {
  const discountCode = await API.post(
    `/api/v1/subscription_discounts`,
    newSubscriptionDiscount
  )
  return discountCode.data
}

export const getSubscriptionDiscountCategories =
  async (): Promise<IApiSubscriptionDiscountCategories> => {
    const categories = await API.get(`/api/v1/subscription_discount_categories`)
    return categories.data['hydra:member']
  }

export const getSubscriptionDiscounts = async (
  params: IFilterBag
): Promise<{ data: Array<IApiSubscription>; totalItems: number }> => {
  const discounts = await API.get(`/api/v1/subscription_discounts`, { params })

  return {
    data: discounts.data['hydra:member'],
    totalItems: discounts.data['hydra:totalItems'],
  }
}

export const getSubscriptionDiscountCategory = async (
  categoryId: string
): Promise<IApiSubscriptionDiscountCategory> => {
  const category = await API.get(
    `/api/v1/subscription_discount_categories/${categoryId}`
  )
  return category.data
}

export const getSubscriptionDiscount = async (
  subscriptionDiscountId: string
): Promise<IApiSubscription> => {
  const discountCode = await API.get(
    `/api/v1/subscription_discounts/${subscriptionDiscountId}`
  )
  return discountCode.data
}

export const getSubscriptionDiscountUsage = async (
  id: IApiOrganization['@id']
): Promise<IApiSubscriptionDiscount[]> => {
  const discountCode = await API.get(
    `/api/v1/organizations/${id}/subscription_discount_usages`
  )
  return discountCode.data['hydra:member']
}
