import { Icon } from 'components'
import ReactSelect from 'react-select'
import styled from 'styled-components'

export const Select = styled((props) => <ReactSelect {...props} />)`
  .react-select__control {
    background-clip: padding-box;
    border: 1px solid ${(props) => props.theme.lightGrey};
    border-radius: 20px;
    box-shadow: 0 1px 3px 0 ${(props) => props.theme.lightGrey};
    min-height: calc(1.5em + 0.68rem + 2px); // .form-control class height
    font-size: 1.143rem;
  }
  .react-select__value-container {
    padding: 0 20px;
  }
  .react-select__multi-value {
    background-color: ${(props) => props.theme.grey};
    color: ${(props) => props.theme.white};
    border-radius: 3px;

    .react-select__multi-value__label {
      color: ${(props) => props.theme.white};
      padding: 0 3px 0 6px;
      font-size: 0.875rem;
      line-height: 19px;
    }
    .react-select__multi-value__remove {
      color: ${(props) => props.theme.white};
      cursor: pointer;
    }
    .react-select__multi-value__remove:hover {
      background-color: ${(props) => props.theme.darkGrey};
    }
  }
  .react-select__indicator {
    padding: 0 8px;
  }
  .react-select__control--is-focused {
    box-shadow: 0 1px 3px 0 ${(props) => props.theme.lightGrey},
      0 0 0 0.2rem rgba(49, 107, 242, 0.25);
  }
  .react-select__placeholder {
    color: ${(props) => props.theme.grey};
  }
  &.is-invalid {
    .react-select__control {
      border: ${(props) => props.theme.red} solid 1px;
    }
    .react-select__control--is-focused {
      box-shadow: 0 1px 3px 0 ${(props) => props.theme.lightGrey},
        0 0 0 0.2rem rgb(243, 96, 82, 0.25);
    }
  }
  .react-select__control--is-disabled {
    background-color: ${(props) => props.theme.extraLight};
  }
  &:hover {
    .react-select__control {
      border: 1px solid transparent;
    }
    &.is-invalid .react-select__control {
      border: ${(props) => props.theme.red} solid 1px;
    }
    .react-select__indicator-separator {
      background-color: ${(props) => props.theme.darkGrey};
      transition: background-color 150ms;
    }
    .react-select__dropdown-indicator,
    .react-select__placeholder {
      color: ${(props) => props.theme.darkGrey};
      transition: color 150ms;
    }
  }
`

export const OptionChipIcon = styled((props) => <Icon {...props} />)`
  font-size: 7px; // Prevent display problems using integer value
  margin-bottom: 2px;
`

export const OptionChipText = styled(({ color, ...props }) => (
  <span {...props} />
))`
  color: ${({ color, ...props }) => props.theme[color]};
  font-size: 0.9em;
  font-weight: 500;
`
