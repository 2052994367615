import { Button, Icon } from 'components'
import styled from 'styled-components'
import { hexToRGBa } from 'utils'

export const ResponsiveButton = styled(({ color, ...props }) => (
  <Button {...props} />
))`
  color: ${(props) => props.theme.grey};
  border-color: ${(props) => props.theme.grey};
  line-height: 26px;
  padding: 0 0.857rem;

  &:hover,
  &:focus {
    color: ${({ theme, color }) => theme[color]};
    border-color: ${({ theme, color }) => theme[color]};
    background: transparent;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem
      ${({ theme, color }) => hexToRGBa(theme[color], 0.2)} !important;
  }

  &:active {
    color: ${(props) => props.theme.white} !important;
    background-color: ${({ theme, color }) => theme[color]} !important;
    border-color: ${({ theme, color }) => theme[color]} !important;
    box-shadow: none !important;
  }

  &:disabled {
    color: ${(props) => props.theme.lightGrey} !important;
    background-color: ${(props) => props.theme.backgroundColor} !important;
    border-color: ${(props) => props.theme.lightGrey} !important;
  }

  @media (min-width: ${(props) => props.theme['gridBreakpoints-md']}) {
    padding: 0 1.1rem;
  }

  @media (min-width: ${(props) => props.theme['gridBreakpoints-lg']}) {
    width: 30px;
    height: 30px;
    padding: 0;
  }
`

export const ResponsiveIcon = styled(({ color, ...props }) => (
  <Icon {...props} />
))`
  color: inherit !important;
`
