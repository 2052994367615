import styled from 'styled-components'

export const HTMLContentContainer = styled.div`
  & * {
    max-width: 100%;
  }
  & img {
    height: auto !important;
  }
`
