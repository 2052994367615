import { API } from 'api/connector'
import { IApiNonprofit } from 'api/type'

import { IApiThirdParty, EThirdPartyType } from './type'

export const getThirdParty = async (id: string): Promise<IApiThirdParty> => {
  const thirdparty = await API.get(`/api/v1/accounting_third_parties/${id}`)
  return thirdparty.data
}

export const deleteThirdParty = async (
  id: string | null
): Promise<{ status: number }> =>
  API.delete(`/api/v1/accounting_third_parties/${id}`)

export const createThirdParty = async (
  id: IApiNonprofit['id'],
  values?: {
    name: string
    type?: EThirdPartyType
  }
): Promise<IApiThirdParty> => {
  const thirdparty = await API.post(`/api/v1/accounting_third_parties`, {
    ...values,
    nonprofit: `/api/v1/nonprofits/${id}`,
  })
  return thirdparty.data
}

export const updateThirdParty = async (
  id: string,
  values?: {
    name: string
    type?: EThirdPartyType
  }
): Promise<IApiThirdParty> => {
  const thirdparty = await API.put(`/api/v1/accounting_third_parties/${id}`, {
    ...values,
  })
  return thirdparty.data
}
