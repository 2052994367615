import { InputGroup as ReactStrapInputGroup } from 'reactstrap'
import styled, { css } from 'styled-components'

const PrependAppendLine = css`
  content: '';
  position: absolute;
  width: 1px;
  height: 60%;
  background: ${({ theme }) => theme.grey};
`

export const InputGroup = styled(
  ({ additionalElements, disabled, ...props }) => (
    <ReactStrapInputGroup {...props} />
  )
)`
  border-radius: 20px;
  background: ${({ disabled, theme }) => !disabled && theme.white};
  border: 1px solid ${({ theme }) => theme.lightGrey};
  box-shadow: 0 1px 3px 0 ${({ theme }) => theme.lightGrey};
  color: ${({ disabled, theme }) => (disabled ? theme.grey : theme.black)};
  transition: all 0.3s ease-in;

  &.is-invalid {
    border: 1px solid ${({ theme }) => theme.red};
  }

  .btn-clear {
    border-radius: 0;
    color: ${({ theme }) => theme.grey};
    box-shadow: none !important;

    &.btn:not(:disabled):not(.disabled):active:focus:hover {
      background: ${({ theme }) => theme.backgroundColor};
      border-left: 1px solid ${({ theme }) => theme.grey};
      border-right: 1px solid ${({ theme }) => theme.grey};
      color: ${({ theme }) => theme.darkGrey};
    }
    &.btn-clear-last:not(:disabled):not(.disabled):active:focus:hover {
      border-right: none;
    }

    &.btn-clear-last {
      border-right: none;
      border-radius: 0 20px 20px 0;
      padding-right: 20px;
    }
  }

  .input-group-prepend,
  .input-group-append {
    position: relative;
    margin: 0;

    & > span.input-group-text {
      color: ${({ theme }) => theme.grey};
      font-weight: 700;
      padding: 0;

      & > span {
        padding: 0 10px;
      }

      &:after {
        ${PrependAppendLine};
      }
    }
  }
  .input-group-prepend {
    & > span.input-group-text {
      color: ${({ additionalElements, theme }) =>
        additionalElements?.prepend?.color
          ? theme[additionalElements.prepend.color]
          : theme.grey};
      & > span {
        padding-left: 15px;
      }
    }
    & > span:after {
      right: -1px;
    }
  }
  .input-group-append {
    & > span.input-group-text {
      color: ${({ additionalElements, theme }) =>
        additionalElements?.append?.color
          ? theme[additionalElements.append.color]
          : theme.grey};
      & > span {
        padding-right: 15px;
      }
    }
    & > span:after {
      left: -1px;
    }
  }

  input {
    box-shadow: none;
    padding-left: 20px;
    padding-right: 20px;
    background: transparent;
    border: 0 solid transparent;
    color: ${({ disabled, theme }) => (disabled ? theme.grey : theme.black)};

    transition: all 0.3s ease-in, background-position 0s;

    &:active,
    &:focus {
      background: ${({ disabled, theme }) => !disabled && theme.white};
      box-shadow: inset 0 0 3px 0
        ${({ disabled, theme }) => !disabled && theme.blue};
    }
    &.form-control.is-invalid {
      background-image: none;
      padding-right: 20px !important;
    }
  }
`
