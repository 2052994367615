import { EColor } from 'types'

import * as Type from './type'

export const resultsNumberBadge: Type.IResultsNumberBadge = {
  noResults: {
    color: EColor.FLORIDA,
    icon: ['fas', 'exclamation'],
  },
  results: {
    color: EColor.GREEN,
    icon: ['far', 'check'],
  },
}
