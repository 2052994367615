import { Input } from 'components'
import { IInput } from 'components/Form/Input/type'
import { FormikValues, useFormikContext } from 'formik'
import { createIntl, useTranslation } from 'hooks'
import { ChangeEvent, FC } from 'react'

const AmericanEin: FC<IInput> = ({ label, ...rest }) => {
  const intl = createIntl('components_americanein')
  const translation = useTranslation(intl)
  const { setFieldValue } = useFormikContext()

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value
    // Insert - after the 2nd number if it is missing
    if (value.length > 2 && value.indexOf('-') < 0) {
      value = `${value.slice(0, 2)}-${value.slice(2, value.length)}`
    }
    setFieldValue(event.target.name, value)
  }

  const validate = (value: FormikValues): undefined | string => {
    if (!value) {
      return
    }
    const normalizedSiren = value
      .replace(/(.{3})/g, '$1 ')
      .replace(/[^a-z0-9]+/gi, '')
    if (normalizedSiren.match(/^\d{2}-?\d{7}$/) === null) {
      return translation.translate('validate.incorrect')
    }
  }

  return (
    <Input
      validate={validate}
      onChange={handleChange}
      label={label === undefined ? translation.translate('label') : label}
      placeholder={translation.translate('placeholder')}
      {...rest}
    />
  )
}

export default AmericanEin
