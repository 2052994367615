import * as Type from './type'

export const algoliaConfig = {
  [Type.EContactType.THIRD_PARTY]: {
    searchableAttributes: ['name', 'type'],
    attributesToRetrieve: ['name', 'type', 'objectId'],
  },
  [Type.EContactType.CONTACT]: {
    searchableAttributes: ['firstname', 'id', 'lastname', 'mail'],
    attributesToRetrieve: [
      'administrativeArea1',
      'city',
      'country',
      'date_of_birth',
      'entityId',
      'firstname',
      'lastname',
      'mail',
      'postal',
      'street1',
      'street2',
    ],
  },
}

export const renderAttributes = {
  [Type.EContactType.THIRD_PARTY]: ['name'],
  [Type.EContactType.CONTACT]: ['firstname', 'lastname', 'mail'],
}
