import { Checkbox } from 'components/Form/Checkbox/style'
import { IRow, IRowData } from 'components/Table/type'
import { ChangeEvent, FC, useEffect, useState } from 'react'
import { EColor, EOrderBy, ESize } from 'types'
import { uniqId } from 'utils'

import { toggleRow } from '../utils'
import * as Style from './style'
import * as Type from './type'

const Thead: FC<Type.IThead> = ({
  headerGroups,
  setSelectedRows,
  rows,
  sortBy,
  selectedRows,
}) => {
  const [areAllRowsSelected, setAreAllRowsSelected] = useState(false)

  useEffect(() => {
    if (rows.length === 0) {
      return
    }

    const currentPageSelectedRows = rows.filter((row: IRow) =>
      selectedRows.some(
        (selectedRow) => row.original.rowData['@id'] === selectedRow['@id']
      )
    )
    setAreAllRowsSelected(currentPageSelectedRows.length === rows.length)
  }, [selectedRows, rows])

  return (
    <Style.Thead>
      {headerGroups.map((headerGroup) => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column: Type.IHeaderGroup) => {
            if (column.id !== 'selection') {
              if (column.id === 'amount') {
                column.align = 'end'
              }
              return (
                <Style.Th
                  align={column.align}
                  size={column.size}
                  {...column.getHeaderProps()}
                >
                  <div className="content">
                    <span className="text">{column.render('Header')}</span>
                    {column.tooltip && (
                      <Style.Helper
                        tooltip={{
                          text: column.tooltip,
                          position: 'top',
                        }}
                        button={{
                          size: ESize.SM,
                          backgroundColor: EColor.DARK_GREY,
                          textColor: EColor.WHITE,
                        }}
                      />
                    )}
                    {column.sortBy !== undefined && (
                      <div className="sortby">
                        <Style.Icon
                          className="icon"
                          onClick={() =>
                            sortBy(EOrderBy.ASC, column.sortBy ?? '')
                          }
                          icon={['fas', 'chevron-up']}
                        />
                        <Style.Icon
                          className="icon"
                          onClick={() =>
                            sortBy(EOrderBy.DESC, column.sortBy ?? '')
                          }
                          icon={['fas', 'chevron-down']}
                        />
                      </div>
                    )}
                  </div>
                </Style.Th>
              )
            }

            return (
              <Style.Th
                align={column.align}
                size={column.size}
                {...column.getHeaderProps()}
              >
                <Checkbox
                  id={uniqId()}
                  type="checkbox"
                  className="m-0"
                  value="true"
                  checked={areAllRowsSelected}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    const rowsData = rows.map(
                      (row: IRow) => row.original.rowData
                    )

                    // Get none selected rows
                    const rowsUnchecked = rowsData.filter(
                      (row: IRowData) =>
                        !selectedRows.some(
                          (selectedRow: IRowData) =>
                            selectedRow['@id'] === row['@id']
                        )
                    )

                    const newSelection = event.currentTarget.checked
                      ? toggleRow(true, rowsUnchecked, selectedRows)
                      : toggleRow(false, rowsData, selectedRows)

                    setSelectedRows(() => newSelection)
                  }}
                />
              </Style.Th>
            )
          })}
        </tr>
      ))}
    </Style.Thead>
  )
}

export default Thead
