import { API } from 'api/connector'

import { IApiProAccountAccountHolder } from '../holder/type'
import * as Type from './type'

export const getProAccountAccountMember = async (
  proAccountAccountId: IApiProAccountAccountHolder['account']['id'],
  proAccountUserId: string
): Promise<Type.IApiProAccountAccountMember> => {
  const proAccountCheckPayments = await API.get(
    `/api/v1/pro_account_accounts/${proAccountAccountId}/memberships?user=${proAccountUserId}`
  )
  return proAccountCheckPayments.data['hydra:member'][0]
}
