import { BackgroundTitle, Icon } from 'components'
import FlipBackground from 'components/FlipBackground'
import { FC } from 'react'
import { EColor } from 'types'

import * as Style from './style'
import * as Type from './type'

// If you have this ↓         But you want this ↓
//       -----  -----             -----  -----
//       | a |  | b |             | a |  | b |
//       | a |  -----             | a |  |   |
//       -----                    -----  -----
// If you want your cards to be the same size even if they do not have the same content, use the flex position:
// https://www.notion.so/assoconnect/I-create-several-Backgrounds-Cards-of-the-same-height-04c8a42ac5c0446087653c2b351695bd

const Background: FC<Type.IBackground> = ({
  border = 'shadow',
  children,
  color = EColor.WHITE,
  description,
  disabledContent = false,
  flip,
  icon,
  iconColor,
  title,
  hasCTA = false,
  ...rest
}) => (
  <>
    {title && (
      <BackgroundTitle icon={icon} iconColor={iconColor}>
        {title}
      </BackgroundTitle>
    )}
    {description && (
      <Style.Description className="text-muted mt-3 mb-1 font-weight-lighter">
        {description}
      </Style.Description>
    )}
    {flip ? (
      <FlipBackground border={border} color={color} flip={flip} {...rest} />
    ) : (
      <Style.RegularBackground
        border={border}
        color={color}
        disabledContent={disabledContent}
        hasCTA={hasCTA}
        {...rest}
      >
        {children}
        {hasCTA && (
          <Style.CtaDiv className="ctaDiv p-1 pb-2 pl-2">
            <Icon icon={['far', 'long-arrow-right']} className="text-white" />
          </Style.CtaDiv>
        )}
      </Style.RegularBackground>
    )}
  </>
)

export default Background
