import { API } from 'api/connector'
import * as Type from 'api/type'
import { IApiNonprofit, IApiOrganizationAccountingYear } from 'api/type'

export const createOrganizationAccountingYears = async (
  nonProfitId: IApiNonprofit['@id'],
  startsAt: IApiOrganizationAccountingYear['startsAt'],
  endsAt: IApiOrganizationAccountingYear['endsAt']
): Promise<{
  data: Array<Type.IApiOrganizationAccountingYear>
  totalItems: number
}> => {
  const accountingYear = await API.post(`/api/v1/accounting_years`, {
    nonprofit: nonProfitId,
    startsAt,
    endsAt,
  })

  return {
    data: accountingYear.data['hydra:member'],
    totalItems: accountingYear.data['hydra:totalItems'],
  }
}

export const updateAccountingYear = async (
  accountingYearId: IApiOrganizationAccountingYear['id'],
  startsAt: IApiOrganizationAccountingYear['startsAt'],
  endsAt: IApiOrganizationAccountingYear['endsAt']
): Promise<{
  data: Array<Type.IApiOrganizationAccountingYear>
  totalItems: number
}> => {
  const accountingYear = await API.put(
    `/api/v1/accounting_years/${accountingYearId}`,
    {
      startsAt,
      endsAt,
    }
  )

  return {
    data: accountingYear.data['hydra:member'],
    totalItems: accountingYear.data['hydra:totalItems'],
  }
}
