import classNames from 'classnames'
import { Display, Helper, Icon, Svg } from 'components'
import { useTranslation } from 'hooks'
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { EColor, ESize } from 'types'

import * as Style from './style'
import * as Type from './type'

const getIconSize = (buttonCardSize: Type.EButtonCardSize) => {
  switch (buttonCardSize) {
    case Type.EButtonCardSize.BIG:
      return '60px'
    case Type.EButtonCardSize.MEDIUM:
      return '35px'
    case Type.EButtonCardSize.SMALL:
      return '25px'
    default:
      return '60px'
  }
}

const ButtonCard: FC<Type.IButtonCard> = ({
  title,
  translationId,
  size = Type.EButtonCardSize.BIG,
  color = EColor.WHITE,
  svg,
  withArrow = true,
  isActive = false,
  onClick,
}) => {
  const intl = useIntl()
  const translation = useTranslation(intl)

  return (
    <Style.Card
      withArrow={withArrow}
      isActive={isActive}
      size={size}
      color={color}
      className={classNames(
        [
          'BackgroundApplication  d-flex align-items-center text-center flex-grow-1 w-100 my-2',
        ],
        size === Type.EButtonCardSize.BIG ? 'flex-column' : 'flex-row',
        size === Type.EButtonCardSize.SMALL && 'py-3',
        size === Type.EButtonCardSize.MEDIUM && 'py-3'
      )}
      {...(onClick && {
        onClick: onClick,
      })}
    >
      <Style.IconWrapper size={size}>
        <Svg
          src={svg}
          width={getIconSize(size)}
          className={size === Type.EButtonCardSize.BIG ? 'mb-2' : 'mr-2'}
        />
      </Style.IconWrapper>
      {size === Type.EButtonCardSize.BIG && title && (
        <Display type="h2">{translation.translate(title)}</Display>
      )}

      {size === Type.EButtonCardSize.SMALL || title ? (
        <span style={{ color: EColor.BLACK }}>
          {translation.translate(translationId)}
        </span>
      ) : (
        <Display type="h6" color={EColor.BLACK} className="mb-0">
          {translation.translate(translationId)}
        </Display>
      )}
      {!!intl.messages[`${translationId}.tooltip`] && (
        <span className="px-2 ml-2 mb-1">
          <Helper
            tooltip={{
              text: translation.translate(`${translationId}.tooltip`),
              position: 'bottom-end',
            }}
            button={{
              size: ESize.MD,
              backgroundColor: EColor.BACKGROUND_COLOR,
              textColor: EColor.GREY,
            }}
          />
        </span>
      )}
      {withArrow && (
        <>
          <Icon className="arrowIcon" icon={['far', 'long-arrow-right']} />
          <div className="backgroundIcon" />
        </>
      )}
    </Style.Card>
  )
}
export default ButtonCard
