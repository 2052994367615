import { getOrganizationApps } from 'api'
import { useApiError } from 'hooks'
import { OrganizationContext } from 'providers'
import { FC, ReactNode, useContext } from 'react'
import { useAsyncRetry } from 'react-use'

import ApplicationsContext from './ApplicationsContext'

const ApplicationsProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const apiError = useApiError()
  const { organization } = useContext(OrganizationContext)

  const {
    value: applications,
    loading,
    retry,
  } = useAsyncRetry(async () => {
    if (!organization.id) {
      return undefined
    }

    try {
      const applicationsData = await getOrganizationApps(organization.id)

      return {
        list: applicationsData.apps,
        iri: applicationsData['@id'],
      }
    } catch (error: unknown) {
      apiError.handleApiError(error)
      return null
    }
  }, [organization.id])

  if (applications === undefined || applications === null) {
    return <></>
  }

  return (
    <ApplicationsContext.Provider value={{ applications, loading, retry }}>
      {children}
    </ApplicationsContext.Provider>
  )
}

export default ApplicationsProvider
