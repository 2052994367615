export const hasArrayIntersection = (a: string[], b: string[]): boolean => {
  const setA = new Set(a)
  const setB = new Set(b)
  const intersection = new Set([...setA].filter((x) => setB.has(x)))
  return !!Array.from(intersection).length
}

export const getArraySum = (array: number[]): number =>
  array.reduce((a, b) => a + b, 0)

export const shuffleArray = (arrayToShuffle: Array<number>): Array<number> => {
  for (let i = arrayToShuffle.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * i)
    const temp = arrayToShuffle[i]
    arrayToShuffle[i] = arrayToShuffle[j]
    arrayToShuffle[j] = temp
  }
  return arrayToShuffle
}
