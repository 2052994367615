import Icon from 'components/Icon'
import { FC } from 'react'
import { EColor } from 'types'

import * as Style from './style'
import * as Type from './type'

const Title: FC<Type.ITitle> = ({
  children,
  icon,
  iconColor,
  type,
  ...rest
}) => (
  <Style.Title type={type} {...rest}>
    {icon && (
      <Icon icon={icon} className="mr-2" color={iconColor || EColor.BLUE} />
    )}
    {children}
  </Style.Title>
)

export default Title
