import classNames from 'classnames'
import { Icon } from 'components'
import { FC } from 'react'
import { EColor } from 'types'

import * as Style from '../Title/style'
import * as Type from './type'

const Display: FC<Type.IDisplay> = ({
  children,
  className,
  icon,
  iconColor,
  type,
  typeElement = 'div',
  ...rest
}) => (
  <Style.Title
    typeElement={typeElement}
    className={classNames(className, type)}
    type={type}
    {...rest}
  >
    {icon && (
      <Icon icon={icon} className="mr-2" color={iconColor || EColor.BLUE} />
    )}
    {children}
  </Style.Title>
)

export default Display
