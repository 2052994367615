import { EApiCrmPersonRole } from 'api/type'
import { createIntl, usePerson, useSelector, useTranslation } from 'hooks'
import { NonprofitContext } from 'providers'
import { FC, useContext } from 'react'
import { useEffectOnce } from 'react-use'
import {
  selectBrand,
  selectLocale,
  selectOrganization,
  selectPerson,
} from 'state'
import {
  EBrand,
  getBrandDisplayName,
  getLocaleShortcode,
  retrying,
} from 'utils'

const ReactWootric: FC = () => {
  const organization = useSelector(selectOrganization)
  const { nonprofit } = useContext(NonprofitContext)
  const person = useSelector(selectPerson)
  const brand = useSelector(selectBrand)
  const locale = useSelector(selectLocale)

  const { hasRole } = usePerson()
  const intl = createIntl('components_wootric')
  const translation = useTranslation(intl)

  useEffectOnce(() => {
    if (
      organization.subscriptionSpecifications.hasPayingSubscription &&
      [EBrand.ASSOCONNECT, EBrand.SPRINGLY].includes(brand.name) &&
      new Date(organization.createdAt) <=
        new Date(Date.now() - 90 * 24 * 60 * 60 * 1000) && // 3 months
      hasRole(EApiCrmPersonRole.GENERAL_ADMIN) &&
      organization.parent === null
    ) {
      window.wootricSettings = {
        wootric_survey_immediately: true,
        email: person.email,
        created_at: new Date(organization.createdAt).getTime() / 1000,
        product_name: getBrandDisplayName(brand.name),
        wootric_recommend_target: translation.translate('wootricTarget'),
        account_token: String(process.env.REACT_APP_WOOTRIC),
        modal_theme: 'light',
        language: getLocaleShortcode(locale),
        properties: {
          role: 'admin',
          country: nonprofit.country,
          pricing_plan: organization.subscription?.planReference,
          vendor_id: organization.idOld,
          organization_id: organization.id,
        },
      }

      const script1 = document.createElement('script')
      script1.src = 'https://cdn.wootric.com/wootric-sdk.js'
      document.body.appendChild(script1)

      retrying(() => {
        if (window.wootric && window.wootricSettings) {
          window.wootric('run')
          return true
        }
        return false
      })
    }
  })

  return <></>
}

export default ReactWootric
