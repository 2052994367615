import { ECurrency } from 'components/Form/Money/type'
import { createContext } from 'react'
import { ELocale } from 'utils/Intl/type'

import { INonprofitContext } from './type'

const NonprofitContext = createContext<INonprofitContext>({
  // TO DO SIMON: update this default value to set with undefined values + create a type with loaded values for pages
  nonprofit: {
    '@id': '',
    id: '',
    currency: ECurrency.EUR,
    timezone: 'Europe_Paris',
    locale: ELocale.FR_FR,
    country: 'FR',
    name: '',
    frenchSiren: '',
    frenchSiret: '',
    frenchRna: '',
    employerIdentificationNumber: '',
    platform: {
      '@id': '',
      '@type': 'Platform',
      id: '',
    },
    isDemo: false,
    organization: '',
  },
  loading: false,
  retry: () => undefined,
})

export default NonprofitContext
