import styled from 'styled-components'

export const Header = styled.div`
  background-color: ${({ theme }) => theme.green};
  color: ${({ theme }) => theme.white};
  padding: 1rem 1.5rem;
  flex: 0;
`

export const CloseWrapper = styled.div`
  position: absolute;
  top: 27px;
  right: 29px;
  cursor: pointer;
`
