import { IInput } from 'components/Form/Input/type'
import { EBrand } from 'utils'

export interface INumeric extends IInput {
  currency?: string
  max?: number
  min?: number
  numberType?: ENumericType
  brand?: EBrand
  minLength?: number
}

export enum ENumericType {
  NUMBER = 'number',
  CURRENCY = 'currency',
  PERCENTAGE = 'percentage',
}
