import { EColor } from 'types'

export const statusParams = {
  default: {
    iconColor: EColor.BLUE,
    iconSrc: '',
    textColor: EColor.DARK_GREY,
  },
  pending: {
    iconColor: EColor.BLUE,
    iconSrc: 'common/icon/multicolor/circle-pending',
    textColor: EColor.BLUE,
  },
  success: {
    iconColor: EColor.GREEN,
    iconSrc: 'common/icon/unicolor/circle-check',
    textColor: EColor.GREY,
  },
  error: {
    iconColor: EColor.RED,
    iconSrc: 'common/icon/unicolor/circle-error',
    textColor: EColor.DARK_GREY,
  },
}

export const adyenFilesSizesByMimeType = {
  'application/pdf': { min: 1024, max: 4194000 },
  '*': { min: 102400, max: 4194000 },
}
