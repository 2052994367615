import { Input } from 'components'
import { FormikValues, useFormikContext } from 'formik'
import { createIntl, useTranslation } from 'hooks'
import { ChangeEvent, FC } from 'react'
import { isLuhn } from 'utils'

import * as Type from './type'
import * as Utils from './utils'

const FrenchSiret: FC<Type.IFrenchSiret> = ({ label, ...rest }) => {
  const intl = createIntl('components_frenchsiret')
  const translation = useTranslation(intl)
  const { setFieldValue } = useFormikContext()

  const updateSiret = (event: ChangeEvent<HTMLInputElement>) => {
    setFieldValue(
      event.target.name,
      Utils.getFrenchSiretFormatted(event.target.value)
    )
  }

  const validate = (value: FormikValues): undefined | string => {
    if (!value) {
      return
    }
    const normalizedSiret = value
      .replace(/(.{4})/g, '$1 ')
      .replace(/[^a-z0-9]+/gi, '')

    if (normalizedSiret.length < 14) {
      return translation.translate('validate.length.short')
    } else if (normalizedSiret.length > 14) {
      return translation.translate('validate.length.long')
    }

    if (
      normalizedSiret.match(/^[0-9]{14}$/) === null ||
      !isLuhn(normalizedSiret)
    ) {
      return translation.translate('validate.incorrect')
    }
  }

  return (
    <Input
      label={label === undefined ? translation.translate('label') : label}
      onChange={updateSiret}
      placeholder={translation.translate('placeholder')}
      validate={validate}
      {...rest}
    />
  )
}

export default FrenchSiret
