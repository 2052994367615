import { CountryCode } from 'libphonenumber-js'
import { ECountry } from 'utils/Intl/type'

import * as Type from './type'
import { ECaseType } from './type'

export const getNavigatorLanguage = (): string =>
  navigator.language.substring(0, 2) === 'fr'
    ? Type.ELocale.FR_FR
    : Type.ELocale.EN_US

/**
 * Returns the short version of the locale code
 * Example : fr_FR becomes fr
 * @param locale
 */
export const getLocaleShortcode = (locale: string | Type.ELocale): string =>
  locale.length <= 2 ? locale : locale.substring(0, locale.indexOf('_'))

/**
 * Returns the country code of the locale code
 * Example : fr_FR becomes FR
 * @param locale
 */
export const getLocaleCountryCode = (locale: string | Type.ELocale): ECountry =>
  locale.substring(locale.indexOf('_') + 1) as ECountry

export const formatLocale = (locale: string, format: ECaseType): string =>
  format === ECaseType.KEBAB
    ? locale.replace('_', '-')
    : locale.replace('-', '_')

export const getIsVatCountry = (country: CountryCode): boolean =>
  [ECountry.FR, ECountry.BE, ECountry.LU, ECountry.CH].some(
    (vatCountry) => country === vatCountry
  )
