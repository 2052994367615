import { InputProps } from 'reactstrap'

export interface IPerson extends InputProps {
  label?: string
  excludeStructures?: boolean
  name: string
  onSuggestionSelected?: (hit: {
    [key: string]: string | number | null
  }) => void
  onSuggestionCleared?: () => void
  redirect?: string
  contactType?: EContactType
}

export enum EContactType {
  THIRD_PARTY = 'thirdParty',
  CONTACT = 'contact',
}
