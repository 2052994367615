import classNames from 'classnames'
import { FormGroup, FormHelper } from 'components'
import { Field, FieldProps } from 'formik'
import { createIntl, useTranslation } from 'hooks'
import { IUseTranslation } from 'hooks/useTranslation/type'
import { FC, useRef } from 'react'
import { uniqId } from 'utils'

import * as Style from './style'
import * as Type from './type'

export const validateCheckbox = (
  value: Array<string | number>,
  required: boolean,
  translation: IUseTranslation
): undefined | string => {
  // Required
  if (required && !value?.length) {
    return translation.translate('validate.required.multiple')
  }
}

const Checkbox: FC<Type.ICheckbox> = ({
  checked,
  className,
  helpText,
  id,
  label,
  name,
  required = false,
  type = 'checkbox',
  value,
  ...rest
}) => {
  const refUniqueId = useRef(uniqId('checkbox'))
  const intl = createIntl('components_checkbox')
  const translation = useTranslation(intl)

  return (
    <Field
      name={name}
      validate={(checkboxValue: Array<string | number>) =>
        validateCheckbox(checkboxValue, required, translation)
      }
      children={({ field, form: { setFieldValue } }: FieldProps) => (
        <FormGroup>
          <Style.Checkbox
            {...field}
            id={id || refUniqueId.current}
            type={type}
            checked={!!(field.value && field.value.includes(value))}
            label={<span>{label}</span>}
            onChange={() => {
              const set = new Set(field.value)
              if (set.has(value)) {
                set.delete(value)
              } else {
                set.add(value)
              }
              setFieldValue(field.name, Array.from(set))
            }}
            className={classNames('d-flex', className)}
            {...rest}
          />
          <FormHelper fieldName={field.name} helpText={helpText} />
        </FormGroup>
      )}
    />
  )
}

export default Checkbox
