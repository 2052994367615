import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { ReactNode } from 'react'
import { FlattenInterpolation, ThemeProps } from 'styled-components'
import { EColor } from 'types'

export enum ETitleFont {
  POPPINS = 'Poppins',
  ROBOTO = 'Roboto',
}

export interface ITitle {
  children: ReactNode
  className?: string
  color?: EColor
  font?: ETitleFont
  icon?: IconProp
  iconColor?: EColor
  type: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  typeElement?: string // Used in the Display component
  uppercase?: boolean
}

export interface IHn {
  [key: string]: FlattenInterpolation<ThemeProps<string>>
}
