import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IStoreSidePanel } from 'state/type'

export const sidePanelInitialState: IStoreSidePanel = {
  template: '',
  isOpen: false,
  actions: {},
  options: {},
  title: '',
}

const sidePanelSlice = createSlice({
  name: 'sidePanel',
  initialState: sidePanelInitialState,
  reducers: {
    resetSidePanel: () => sidePanelInitialState,
    setSidePanel: (state, action: PayloadAction<IStoreSidePanel>) =>
      action.payload,
  },
})

export const { setSidePanel, resetSidePanel } = sidePanelSlice.actions
export default sidePanelSlice.reducer
