import { API } from 'api/connector'

import * as Type from './type'

export const updateTaxReceiptsConfigSettings = async (
  taxReceiptsSettingsId: string,
  settings: Type.ITaxReceiptsValues
): Promise<Type.ITaxReceiptsConfigSettings> =>
  API.put(`/api/v1/tax_receipt_settings/${taxReceiptsSettingsId}`, {
    ...settings,
  })
