import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getCurrentTimezone } from 'components/Form/Timezone'
import { IStoreI18n } from 'state/type'
import { EBrand, getBrandName } from 'utils'
import { ECountry, ELocale } from 'utils/Intl/type'

export const i18nInitialState: IStoreI18n = {
  isLoaded: false,
  brand: {
    name: EBrand.SPRINGLY,
    publicName: getBrandName(EBrand.SPRINGLY),
  },
  locale: ELocale.EN_US,
  timezone: getCurrentTimezone(),
  country: ECountry.US,
}

const i18nSlice = createSlice({
  name: 'i18n',
  initialState: i18nInitialState,
  reducers: {
    setI18n: (state, action: PayloadAction<IStoreI18n>) =>
      Object.assign({}, state, action.payload),
  },
})

export const { setI18n } = i18nSlice.actions
export default i18nSlice.reducer
