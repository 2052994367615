import { FC } from 'react'
import { HighlightProps } from 'react-instantsearch-core'
import { connectHighlight } from 'react-instantsearch-dom'

const AlgoliaHighlightWidget: FC<HighlightProps> = ({
  attribute,
  highlight,
  hit,
  ...rest
}) => {
  const parsedHit = highlight({
    highlightProperty: '_highlightResult',
    attribute,
    hit,
  })
  return (
    <span {...rest}>
      {parsedHit.map(
        (part: { value: string; isHighlighted: boolean }, index: number) =>
          part.isHighlighted ? (
            <span className="text-primary" key={index}>
              {part.value}
            </span>
          ) : (
            <span key={index}>{part.value}</span>
          )
      )}
    </span>
  )
}

export default connectHighlight(AlgoliaHighlightWidget)
