import { Input } from 'components'
import { IInput } from 'components/Form/Input/type'
import { FormikValues, useFormikContext } from 'formik'
import { createIntl, useTranslation } from 'hooks'
import { FC } from 'react'
import { isValidUrl } from 'utils'

const Url: FC<IInput> = ({ label, name, required = false, ...rest }) => {
  const intl = createIntl('components_url')
  const translation = useTranslation(intl)
  const { setFieldValue } = useFormikContext<FormikValues>()

  const validate = (value: FormikValues) => {
    if (required && !value) {
      return translation.translate('validate.required')
    }

    if (value?.length && !isValidUrl(value.toString())) {
      // Add https protocol if it is missing from user input
      if (
        !String(value).startsWith('http://') &&
        !String(value).startsWith('https://') &&
        name &&
        isValidUrl('https://' + value)
      ) {
        setFieldValue(name, 'https://' + value)
        return ''
      }
      return translation.translate('validate.incorrect')
    }
    return ''
  }

  return (
    <Input
      validate={validate}
      type="url"
      label={label || translation.translate('label')}
      name={name}
      prepend={{ icon: ['far', 'link-horizontal'] }}
      required={required}
      {...rest}
    />
  )
}

export default Url
