import { Button, ResponsiveButton } from 'components'
import styled from 'styled-components'

export const TableButton = styled(({ tag, ...rest }) =>
  tag === 'ResponsiveButton' ? (
    <ResponsiveButton {...rest} />
  ) : (
    <Button {...rest} />
  )
)`
  cursor: default;
`
