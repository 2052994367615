import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'
import styled from 'styled-components'

export const DropdownComponent = styled((props) => <Dropdown {...props} />)`
  padding: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px 0 0 20px;

  & > button {
    background: none !important;
    box-shadow: none !important;
    width: calc(100% + 1px);
    left: -1px;
    top: 0;
    height: 100%;
    border-radius: 20px 0 0 20px;
    padding: 0 20px 0 15px;

    &:hover,
    &:active,
    &:focus {
      border: none;
      outline: 0;

      &:after {
        border-color: ${(props) => props.theme.grey};
      }
    }
  }
  & .disabled {
    cursor: default;
  }

  & img {
    margin-right: 5px;
  }
`

export const DropdownMenuComponent = styled((props) => (
  <DropdownMenu {...props} />
))`
  max-height: 200px;
  overflow-y: auto;
`

export const DropdownToggleComponent = styled((props) => (
  <DropdownToggle {...props} />
))`
  background-color: white;
  border: none;
  padding: 0 10px 0 0;

  & svg {
    width: 16px;
  }

  &:after {
    position: absolute;
    top: 13px;
    border: 0;
    border-left: 2px solid ${(props) => props.theme.lightGrey};
    border-bottom: 2px solid ${(props) => props.theme.lightGrey};
    width: 6px;
    height: 6px;
    transform: rotateZ(-45deg);
    border-radius: 0;
    margin-left: 5px;
  }

  & .PhoneInputInput {
    border: none;
  }

  & .PhoneInputInput:focus {
    border: none;
  }

  & .btn-secondary:hover {
    color: black;
  }
`

export const DropdownItemComponent = styled((props) => (
  <DropdownItem {...props} />
))`
  & svg {
    width: 17px;
  }
`
