import { API } from 'api/connector'
import { ISupportMessage } from 'api/type'
import { getCurrentTimezone } from 'components/Form/Timezone'
import { getCountryForTimezone } from 'countries-and-timezones'
import Dinero from 'dinero.js'
import { useSelector } from 'hooks'
import { NonprofitContext, RouterContext } from 'providers'
import { useContext } from 'react'
import { unstable_batchedUpdates } from 'react-dom'
import { useDispatch } from 'react-redux'
import { selectOrganization, selectStoreI18n, setSupportMessage } from 'state'
import { setI18n } from 'state/I18n/slice'
import { IStoreOrganization } from 'state/type'
import { EBrand, formatLocale, getBrandFromUrl, getBrandName } from 'utils'
import { ECaseType, ELocale } from 'utils/Intl/type'

import * as Type from './type'

const UseStoreI18n = (): Type.II18n => {
  const storeOrganization = useSelector(selectOrganization)
  const { nonprofit } = useContext(NonprofitContext)
  const dispatch = useDispatch()
  const storei18n = useSelector(selectStoreI18n)
  const { urlSearchParameters } = useContext(RouterContext)

  const getLocaleDependingOnBrand = (brand: EBrand) =>
    brand === EBrand.SPRINGLY ? ELocale.EN_US : ELocale.FR_FR

  const initialize = (
    supportMessages?: ISupportMessage | undefined,
    forcedOrganization?: IStoreOrganization
  ) => {
    const organization = forcedOrganization ?? storeOrganization

    // BRAND
    const brand = organization.id
      ? organization.brand
      : getBrandFromUrl(urlSearchParameters?.brand as EBrand)

    // LOCALE
    const locale = organization.id
      ? nonprofit.locale
      : getLocaleDependingOnBrand(brand)
    if (!window.locale) {
      window.locale = locale
    }

    // TIMEZONE
    const timezone = nonprofit.timezone ?? getCurrentTimezone()

    // COUNTRY
    const country = nonprofit.country ?? getCountryForTimezone(timezone)

    // Set dinero parameters
    const localeFormatted = formatLocale(locale, ECaseType.KEBAB)
    Dinero.globalLocale = localeFormatted

    // Set API accepted language in the header
    API.defaults.headers.common['Accept-Language'] = localeFormatted

    const i18nData = {
      isLoaded: true,
      brand: {
        name: brand,
        publicName: getBrandName(brand),
      },
      locale,
      timezone,
      country,
    }

    if (!storei18n.isLoaded) {
      // Set i18n store values
      // TODO: React 18 unstable_batchedUpdates: https://github.com/reactwg/react-18/discussions/21
      unstable_batchedUpdates(() => {
        dispatch(setI18n(i18nData))
        supportMessages &&
          dispatch(
            setSupportMessage({
              ...supportMessages,
              locale: locale,
            })
          )
      })
    }
    return i18nData
  }

  return { initialize }
}

export default UseStoreI18n
