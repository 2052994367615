import { FC } from 'react'
import { EColor } from 'types'

import * as Style from './style'
import * as Type from './type'

const Skeleton: FC<Type.ISkeleton> = ({
  minHeight,
  className = '',
  color = EColor.LIGHT_GREY,
  ...rest
}) => (
  <Style.Skeleton
    className={className}
    color={color}
    minHeight={minHeight}
    {...rest}
  />
)

export default Skeleton
