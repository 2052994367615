import { useSelector } from 'hooks'
import { useRef } from 'react'
import TagManager from 'react-gtm-module'
import {
  selectBrand,
  selectLocale,
  selectOrganization,
  selectPerson,
} from 'state'
import { EBrand, formatLocale, retrying } from 'utils'
import { ECaseType } from 'utils/Intl/type'

import * as Type from './type'

const UseTracking = (): Type.IUseTracking => {
  const person = useSelector(selectPerson)
  const organization = useSelector(selectOrganization)
  const brand = useSelector(selectBrand)
  const storeLocale = useSelector(selectLocale)
  const refIsLoaded = useRef(false)

  const initializeRudderstack = () => {
    if (!process.env.REACT_APP_TRACKING_RUDDERSTACK_KEY) {
      return
    }

    // Initialize
    const rudderStack = document.createElement('script')
    rudderStack.src = `https://cdn.rudderlabs.com/v1/rudder-analytics.min.js`
    document.head.appendChild(rudderStack)
    const rudderStackScript = document.createElement('script')
    rudderStackScript.innerHTML = `rudderanalytics=window.rudderanalytics=[];for(var methods=["load","page","track","identify","alias","group","ready","reset","getAnonymousId","setAnonymousId"],i=0;i<methods.length;i++){var method=methods[i];rudderanalytics[method]=function(a){return function(){rudderanalytics.push([a].concat(Array.prototype.slice.call(arguments)))}}(method)}rudderanalytics.load("${process.env.REACT_APP_TRACKING_RUDDERSTACK_KEY}", "${process.env.REACT_APP_TRACKING_RUDDERSTACK_HOST}", {secureCookie: true});` // NOSONAR eslint-disable-line
    document.head.appendChild(rudderStackScript)

    // Identify
    let traits: Type.IRudderstackTraits = {
      branch: 'master',
      brand: brand.name.toUpperCase(),
    }
    if (organization.idOld !== 0) {
      traits = {
        roles: person.globalRoles?.[organization.id] || {},
        organizationId: organization.id,
        vendorId: organization.idOld,
        subscriptionType: organization.subscription?.type,
        layout: 'react',
      }
    }
    window.rudderanalytics.identify(undefined, traits, { ip: 'hidden' })

    // Page
    window.rudderanalytics.page()
  }

  const initializeGtm = (localeForced?: string) => {
    TagManager.initialize({
      gtmId: String(
        localeForced === 'en-US' || brand.name === EBrand.SPRINGLY
          ? process.env.REACT_APP_TRACKING_GTM_SPRINGLY_ID
          : process.env.REACT_APP_TRACKING_GTM_ASSOCONNECT_ID
      ),
    })
  }

  const initializeAxeptio = (localeForced?: string) => {
    const locale = localeForced ? localeForced : storeLocale
    const cookiesVersion = `internal-${formatLocale(
      locale,
      ECaseType.KEBAB
    ).toLowerCase()}`
    const axeptioScript = document.createElement('script')
    const axeptioSettings = {
      clientId: `${process.env.REACT_APP_TRACKING_AXEPTIO_CLIENT_ID}`,
      cookiesVersion: `${cookiesVersion}`,
      userCookiesSecure: true,
    }
    axeptioScript.innerHTML = `window.axeptioSettings=${JSON.stringify(
      axeptioSettings
    )};(function(d,s) {var t=d.getElementsByTagName(s)[0],e=d.createElement(s);e.async=true;e.src="//static.axept.io/sdk-slim.js";t.parentNode.insertBefore(e,t);})(document,"script");` // NOSONAR eslint-disable-line
    document.head.appendChild(axeptioScript)
  }

  const initialize = (localeForced?: string, gtm = false) => {
    if (refIsLoaded.current) {
      return
    }
    refIsLoaded.current = true

    // RudderStack
    initializeRudderstack()

    // GoogleTagManager and Axeptio
    if (gtm) {
      initializeGtm(localeForced)
      initializeAxeptio(localeForced)
    }
  }

  const pageView = () => {
    retrying(() => {
      const anonymousId = window?.rudderanalytics?.getAnonymousId()
      if (anonymousId) {
        window.rudderanalytics.page()
        return true
      }
      return false
    })
  }

  const track = (
    event: string,
    properties?: Type.ITrackParam,
    options?: Type.ITrackParam,
    callback?: () => void
  ) => {
    retrying(() => {
      const anonymousId = window?.rudderanalytics?.getAnonymousId()
      if (anonymousId) {
        window.rudderanalytics.track(event, properties, options, callback)
        return true
      }
      return false
    })
  }

  return {
    initialize,
    pageView,
    track,
  }
}

export default UseTracking
