import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { ECollectType } from 'api/type'
import { ECollectPageId } from 'state/type'

export const getCollectSalesCountIcon = (
  collectType: ECollectType
): IconProp => {
  switch (collectType) {
    case ECollectType.DONATION:
      return ['fas', 'hand-holding-dollar']
    case ECollectType.EVENT:
      return ['fas', 'ticket']
    case ECollectType.MEMBERSHIP:
      return ['fas', 'users']
    default:
      return ['fas', 'shopping-basket']
  }
}

export const getCollectPageId = (
  collectType?: ECollectType
): ECollectPageId => {
  switch (collectType) {
    case ECollectType.EVENT:
      return ECollectPageId.COLLECT_EVENT
    case ECollectType.MEMBERSHIP:
      return ECollectPageId.COLLECT_MEMBERSHIP
    case ECollectType.PRODUCT:
      return ECollectPageId.COLLECT_PRODUCT
    case ECollectType.DONATION:
      return ECollectPageId.COLLECT_DONATION
    default:
      return ECollectPageId.COLLECT_EVENT
  }
}
