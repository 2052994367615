import { EColor } from 'types'

export enum EButtonCardSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  BIG = 'big',
}

export interface IButtonCard {
  title?: string
  translationId: string
  color?: EColor
  size?: EButtonCardSize
  svg: string
  onClick?: () => void
  withArrow?: boolean
  isActive?: boolean
}
