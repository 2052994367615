import { IApiReceivedCheck } from 'api/type'

export enum EBankStatementStatus {
  NONE = 'NONE',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
}

export interface IApiCreateCheckRemittance {
  organization: string
  bankAccount?: string
  date: string
  receivedChecks: Array<IApiReceivedCheck['@id']>
}
