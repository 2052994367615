import { API } from 'api/connector'

import * as Type from './type'

export const getCurrentPerson = async (
  skipRedirect = false
): Promise<Type.IPerson> => {
  const person = await API.get('/api/v1/crm/person/me', {
    params: { skipRedirect },
  })
  return person.data
}
