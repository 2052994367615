import {
  BasicBackground,
  ListOfBackgroundEffect,
} from 'components/Background/style'
import styled, { css } from 'styled-components'

const FlipCardFace = css`
  backface-visibility: hidden;
  min-width: 100%;
  display: flex;
  margin: 0;
  align-items: center;
`

const FlipBackgroundSideTransition = css`
  transition: opacity 0.2s;
`

export const FlipBackground = styled((props) => <div {...props} />)`
  perspective: 40rem;
  transition: all 0.4s;

  /* Prevent horizontal scrollbar when flipping */
  padding: 10px;
  width: calc(100% + 20px);
  margin-left: -10px;
`
export const FlipBackgroundBody = styled(({ flip, ...props }) => (
  <div {...props} />
))`
  display: flex;
  transform-style: preserve-3d;
  transition: 1s transform;
  ${({ flip }) => flip?.active && `transform: rotateX(-180deg);`}
`
export const FlipBackgroundFront = styled(
  ({ border, color, flip, ...props }) => <div {...props} />
)`
  ${BasicBackground}
  ${FlipCardFace}
z-index: 1;

  background-color: ${({ theme, color }) => theme[color]};
  ${({ border }) => ListOfBackgroundEffect[border]}

  & > div {
    ${FlipBackgroundSideTransition};
    opacity: 1;
    transition-delay: 0.5s;
  }

  ${({ flip }) =>
    flip?.active &&
    `z-index: 0;
    & > div {
      transition-delay: 0s;
      opacity: 0;
    }`}
`
export const FlipBackgroundBack = styled(
  ({ border, color, flip, ...props }) => <div {...props} />
)`
  ${BasicBackground}
  ${FlipCardFace}
z-index: 0;
  background-color: ${({ theme, color }) => theme[color]};
  ${({ border }) => ListOfBackgroundEffect[border]}
  transform: rotateX(180deg) translate(-100%, 0);

  & > div {
    opacity: 0;
    transition-delay: 0s;
    ${FlipBackgroundSideTransition}
  }

  ${({ flip }) =>
    flip?.active &&
    `z-index: 1;
    & > div {
      transition-delay: 0.5s;
      opacity: 1;
    }`}
`
