import { API } from 'api/connector'
import { IApiNetwork, IApiNetworks } from 'api/type'

export const getNetworks = async (): Promise<IApiNetworks> => {
  const networks = await API.get(`/api/v1/networks`)
  return networks.data['hydra:member']
}

export const getNetwork = async (networkId: string): Promise<IApiNetwork> => {
  const network = await API.get(`/api/v1/networks/${networkId}`)
  return network.data
}
