import * as Type from './type'

export const phoneNumberExamples = {
  [Type.ENumberType.FIXED_LINE]: {
    AC: '1234',
    AD: '123456',
    AE: '21234567',
    AF: '20123456',
    AG: '2684601234',
    AI: '2644971234',
    AL: '4123456',
    AM: '10123456',
    AO: '222123456',
    AR: '1112345678',
    AS: '6846331234',
    AT: '11234567',
    AU: '212345678',
    AW: '5201234',
    AX: '18123456',
    AZ: '121234567',
    BA: '33123456',
    BB: '2464261234',
    BD: '21234567',
    BE: '21234567',
    BF: '20123456',
    BG: '21234567',
    BH: '17123456',
    BI: '22123456',
    BJ: '21123456',
    BL: '590271234',
    BM: '4412921234',
    BN: '2123456',
    BO: '21234567',
    BQ: '7171234',
    BR: '1112345678',
    BS: '2423221234',
    BT: '2123456',
    BW: '24123456',
    BY: '171234567',
    BZ: '2221234',
    CA: '4161234567',
    CC: '891621234',
    CD: '121234567',
    CF: '21123456',
    CG: '22123456',
    CH: '441234567',
    CI: '20123456',
    CK: '21234',
    CL: '212345678',
    CM: '21234567',
    CN: '1012345678',
    CO: '11234567',
    CR: '22123456',
    CU: '71234567',
    CV: '21234',
    CW: '91234567',
    CX: '891641234',
    CY: '22123456',
    CZ: '212345678',
    DE: '301234567',
    DJ: '21123456',
    DK: '33123456',
    DM: '7674481234',
    DO: '8091234567',
    DZ: '21123456',
    EC: '21234567',
    EE: '6123456',
    EG: '21234567',
    EH: '528812345',
    ER: '1123456',
    ES: '911234567',
    ET: '111234567',
    FI: '91234567',
    FJ: '3123456',
    FK: '12345',
    FM: '3201234',
    FO: '321123',
    FR: '112345678',
    GA: '1123456',
    GB: '2012345678',
    GD: '4734401234',
    GE: '321234567',
    GF: '594123456',
    GG: '1481123456',
    GH: '301234567',
    GI: '20012345',
    GL: '321234',
    GM: '4371234',
    GN: '30123456',
    GP: '590123456',
    GQ: '333123456',
    GR: '2112345678',
    GT: '21234567',
    GU: '6714721234',
    GW: '3123456',
    GY: '2123456',
    HK: '21234567',
    HN: '21234567',
    HR: '11234567',
    HT: '22123456',
    HU: '11234567',
    ID: '211234567',
    IE: '11234567',
    IL: '31234567',
    IM: '1624123456',
    IN: '1112345678',
    IO: '1234',
    IQ: '11234567',
    IR: '2112345678',
    IS: '5123456',
    IT: '0612345678',
    JE: '1534123456',
    JM: '8769261234',
    JO: '61234567',
    JP: '312345678',
    KE: '20123456',
    KG: '312123456',
    KH: '23123456',
    KI: '21123',
    KM: '7731234',
    KN: '8694651234',
    KP: '21234567',
    KR: '212345678',
    KW: '21234567',
    KY: '3459491234',
    KZ: '7172123456',
    LA: '21123456',
    LB: '1123456',
    LC: '7584521234',
    LI: '2331234',
    LK: '111234567',
    LR: '2123456',
    LS: '22123456',
    LT: '5123456',
    LU: '42123456',
    LV: '61234567',
    LY: '211234567',
    MA: '512345678',
    MC: '99123456',
    MD: '22123456',
    ME: '20123456',
    MF: '59027-1234',
    MG: '201234567',
    MH: '2471234',
    MK: '21234567',
    ML: '20123456',
    MM: '1123456',
    MN: '11123456',
    MO: '28123456',
    MP: '6702345678',
    MQ: '59630-1234',
    MR: '4512345',
    MS: '6644913456',
    MT: '21123456',
    MU: '2011234',
    MV: '3311234',
    MW: '1123456',
    MX: '5512345678',
    MY: '312345678',
    MZ: '21123456',
    NA: '61123456',
    NC: '201234',
    NE: '20123456',
    NF: '322123',
    NG: '11234567',
    NI: '21234567',
    NL: '201234567',
    NO: '21123456',
    NP: '11234567',
    NR: '4441234',
    NU: '1234',
    NZ: '41234567',
    OM: '24123456',
    PA: '2121234',
    PE: '11234567',
    PF: '40123456',
    PG: '3211234',
    PH: '21234567',
    PK: '211234567',
    PL: '221234567',
    PM: '411234',
    PR: '7871234567',
    PS: '21234567',
    PT: '211234567',
    PW: '4881234',
    PY: '21123456',
    QA: '44123456',
    RE: '262123456',
    RO: '211234567',
    RS: '111234567',
    RU: '4951234567',
    RW: '25123456',
    SA: '111234567',
    SB: '221234',
    SC: '4123456',
    SD: '151234567',
    SE: '81234567',
    SG: '61234567',
    SH: '1234',
    SI: '11234567',
    SJ: '79021234',
    SK: '212345678',
    SL: '22123456',
    SM: '0549123456',
    SN: '331234567',
    SO: '1234567',
    SR: '471234',
    SS: '123456789',
    ST: '212345',
    SV: '21123456',
    SX: '7215425678',
    SY: '111234567',
    SZ: '22123456',
    TA: '81234',
    TC: '6499411234',
    TD: '22123456',
    TG: '22123456',
    TH: '21234567',
    TJ: '371234567',
    TK: '3010',
    TL: '2112345',
    TM: '12123456',
    TN: '71123456',
    TO: '20123',
    TR: '2121234567',
    TT: '8681234567',
    TV: '20123',
    TW: '21234567',
    TZ: '221234567',
    UA: '441234567',
    UG: '311234567',
    US: '2121234567',
    UY: '21234567',
    UZ: '711234567',
    VA: '06698',
    VC: '7841234567',
    VE: '2121234567',
    VG: '2841234567',
    VI: '3401234567',
    VN: '41234567',
    VU: '22123',
    WF: '721234',
    WS: '22123',
    XK: '381234567',
    YE: '1234567',
    YT: '269123456',
    ZA: '101234567',
    ZM: '211123456',
    ZW: '4123456',
  },
  [Type.ENumberType.MOBILE]: {
    AC: '2021234567',
    AD: '71234567',
    AE: '261234567',
    AF: '301234567',
    AG: '2684645678',
    AI: '2642355678',
    AL: '421234567',
    AM: '10123456',
    AO: '222123456',
    AR: '1123456789',
    AS: '6847335678',
    AT: '112345678',
    AU: '221234567',
    AW: '5605678',
    AX: '211234567',
    AZ: '412345678',
    BA: '61123456',
    BB: '2462505678',
    BD: '181234567',
    BE: '470567890',
    BF: '70123456',
    BG: '431234567',
    BH: '36015678',
    BI: '79561234',
    BJ: '90015678',
    BL: '690056789',
    BM: '4413705678',
    BN: '7212345',
    BO: '712345678',
    BQ: '3185678',
    BR: '11123456789',
    BS: '2423595678',
    BT: '17115678',
    BW: '71115678',
    BY: '294911922',
    BZ: '6225678',
    CA: '5064567890',
    CC: '412345678',
    CD: '991234567',
    CF: '70015678',
    CG: '061234567',
    CH: '781234567',
    CI: '0123456789',
    CK: '71156',
    CL: '221234567',
    CM: '671234567',
    CN: '10123456789',
    CO: '3211234567',
    CR: '83115678',
    CU: '51215678',
    CV: '9911567',
    CW: '95185678',
    CX: '412345678',
    CY: '96115678',
    CZ: '601156789',
    DE: '15123456789',
    DJ: '77831001',
    DK: '32115678',
    DM: '7672255678',
    DO: '8091567890',
    DZ: '551234567',
    EC: '991234567',
    EE: '51215678',
    EG: '1001567890',
    EH: '650156789',
    ER: '7121567',
    ES: '912345678',
    ET: '911156789',
    FI: '412345678',
    FJ: '7012567',
    FK: '51256',
    FM: '3505678',
    FO: '211156',
    FR: '612345678',
    GA: '06035678',
    GB: '7400567890',
    GD: '4734035678',
    GE: '555567890',
    GF: '694256789',
    GG: '7781125678',
    GH: '231234567',
    GI: '57115678',
    GL: '221156',
    GM: '30123456',
    GN: '601156789',
    GP: '690056789',
    GQ: '222156789',
    GR: '6912567890',
    GT: '51215678',
    GU: '6713567890',
    GW: '955056789',
    GY: '60915678',
    HK: '51215678',
    HN: '91215678',
    HR: '921156789',
    HT: '34115678',
    HU: '201156789',
    ID: '212345678',
    IE: '112345678',
    IL: '252345678',
    IM: '7924125678',
    IN: '1123456789',
    IO: '3801567',
    IQ: '7911567890',
    IR: '9123456789',
    IS: '41115678',
    IT: '3123456789',
    JE: '7797715678',
    JM: '8762105678',
    JO: '790156789',
    JP: '3012345678',
    KE: '712123456',
    KG: '221123456',
    KH: '91215678',
    KI: '72005678',
    KM: '3212567',
    KN: '8697652917',
    KP: '1921567890',
    KR: '2210000000',
    KW: '51125678',
    KY: '3453235678',
    KZ: '7710009998',
    LA: '2023567890',
    LB: '71115678',
    LC: '7582845678',
    LI: '660156789',
    LK: '112345678',
    LR: '770156789',
    LS: '51115678',
    LT: '61215678',
    LU: '628156789',
    LV: '21115678',
    LY: '912156789',
    MA: '650156789',
    MC: '612156789',
    MD: '621156789',
    ME: '67622901',
    MF: '690056789',
    MG: '321256789',
    MH: '2355678',
    MK: '72345678',
    ML: '650156789',
    MM: '912156789',
    MN: '881256789',
    MO: '661256789',
    MP: '670567890',
    MQ: '6962015678',
    MR: '22115678',
    MS: '6644925678',
    MT: '9696125678',
    MU: '525156789',
    MV: '7715678',
    MW: '991156789',
    MX: '12225678901',
    MY: '321456789',
    MZ: '821156789',
    NA: '811156789',
    NC: '7512567',
    NE: '93115678',
    NF: '3812567',
    NG: '8021567890',
    NI: '81215678',
    NL: '612345678',
    NO: '40615678',
    NP: '9841567890',
    NR: '5555678',
    NU: '8884012',
    NZ: '211156789',
    OM: '92115678',
    PA: '61215678',
    PE: '912156789',
    PF: '871256789',
    PG: '701256789',
    PH: '9051567890',
    PK: '3012567890',
    PL: '512156789',
    PM: '5512567',
    PR: '7872567890',
    PS: '599156789',
    PT: '212345678',
    PW: '6201567',
    PY: '961456789',
    QA: '33115678',
    RE: '692156789',
    RO: '712056789',
    RS: '601156789',
    RU: '9123456789',
    RW: '720156789',
    SA: '511156789',
    SB: '7425678',
    SC: '2511567',
    SD: '911231567',
    SE: '811156789',
    SG: '21234567',
    SH: '51256',
    SI: '31156789',
    SJ: '41115678',
    SK: '911123456',
    SL: '25115678',
    SM: '666612567',
    SN: '701156789',
    SO: '71115678',
    SR: '7412567',
    SS: '977156789',
    ST: '9812567',
    SV: '701156789',
    SX: '7215205678',
    SY: '944567890',
    SZ: '76115678',
    TA: '8999',
    TC: '6492315678',
    TD: '63015678',
    TG: '90115678',
    TH: '212345678',
    TJ: '917156789',
    TK: '7290',
    TL: '77215678',
    TM: '661256789',
    TN: '20115678',
    TO: '7715123',
    TR: '5111567890',
    TT: '8682915678',
    TV: '9012567',
    TW: '212345678',
    TZ: '621156789',
    UA: '512156789',
    UG: '712156789',
    US: '2015554567',
    UY: '942156789',
    UZ: '912345678',
    VA: '3111567890',
    VC: '7844305678',
    VE: '4121567890',
    VG: '2843005678',
    VI: '3406425678',
    VN: '912345678',
    VU: '5912567',
    WF: '8212567',
    WS: '7212567',
    XK: '43205678',
    YE: '711156789',
    YT: '6390125678',
    ZA: '711156789',
    ZM: '955156789',
    ZW: '711156789',
  },
}
