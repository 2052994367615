import Loader from 'components/Loader'
import { FC } from 'react'
import { bootstrapColorParser } from 'utils'

import * as Style from './style'
import * as Type from './type'

const Button: FC<Type.IButton> = ({
  color,
  outline,
  type = 'button',
  disabled,
  isLoading,
  children,
  ...rest
}) => (
  <Style.Button
    type={type}
    color={color && bootstrapColorParser(color)}
    outline={outline}
    disabled={disabled ?? isLoading}
    {...rest}
  >
    {isLoading && <Loader isRelative={true} />}
    {children}
  </Style.Button>
)

export default Button
