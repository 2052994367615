import { IButton } from 'components/Button/type'
import { FC } from 'react'
import { EColor } from 'types'

import * as Style from './style'

const Button: FC<IButton> = (props) => (
  <Style.Button color={EColor.PUBLIC_MAIN_COLOR} {...props} />
)

export default Button
