import { Tooltip } from 'components'
import { IFilterBag } from 'components/Table/type'
import { FormikValues } from 'formik'
import { ReactElement } from 'react'
import { EBrand } from 'utils/Misc'

export const normalizeString = (string: string | FormikValues): string =>
  string.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

export const convertToCamelCase = (string: string): string =>
  string
    .toLowerCase()
    .replace('_', ' ')
    .replace(/\s(.)/g, (element) => element.toUpperCase())
    .replace(/\s/g, '')
    .replace(/^(.)/, (element) => element.toLowerCase())

export const capitalize = (string: string): string =>
  string.charAt(0).toUpperCase() + string.slice(1)

export const createEmailUrlExport = (
  url: string,
  params?: IFilterBag,
  fileType = 'xlsx'
): string => {
  const paramsUrl =
    params &&
    Object.entries(params)
      .filter(
        ([key, value]) =>
          key !== 'page' && key !== 'itemsPerPage' && value !== undefined
      )
      .filter(([, value]) => !(Array.isArray(value) && value.length === 0))
      .map(([key]) => `${key}=${encodeURIComponent(params[key])}`)
      .join('&')

  return `${url}.${fileType}?${paramsUrl}&pagination=false`
}

export const formatBrandName = (brand: EBrand): string => {
  if (brand === 'assoconnect') {
    return 'AssoConnect'
  }

  if (brand === 'springly') {
    return 'Springly'
  }

  return brand
}

export const getShortenedText = (text: string, limit: number): string => {
  if (text.length < limit) {
    return text
  }

  const substr = text.substring(0, limit)
  // Last word is complete
  if (text.substring(limit, 1) === ' ') {
    return substr
  }

  // Last word is incomplete
  return `${substr.split(' ').slice(0, -1).join(' ')}...`
}

export const getShortenedTextWithTooltip = (
  text: string,
  uniqId: string,
  lengthCut = 50
): string | ReactElement => {
  // id can't start with digit or special character (querySelector limitation)
  const cleanedUniqId = uniqId.replace(/[^a-zA-Z0-9]/g, '')

  if (text.length > lengthCut) {
    return (
      <>
        {text.substring(0, lengthCut)}
        <span id={cleanedUniqId}>...</span>
        <Tooltip target={cleanedUniqId} position="top">
          {text}
        </Tooltip>
      </>
    )
  }

  return text
}

export const getTextColorFromColorHex = (color: string): string =>
  getColorBrightnessFromHex(color) > 100 ? '#000000' : '#ffffff'

const getColorBrightnessFromHex = (color: string): number => {
  const hexToRgb = (hex: string) => {
    hex = hex.replace(/^#/, '')

    const bigint = parseInt(hex, 16)
    return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255]
  }

  const [r, g, b] = hexToRgb(color)
  return (r * 299 + g * 587 + b * 114) / 1000
}

export const isValidUrl = (url: string) => {
  const pattern =
    /^(https?:\/\/)?([a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5})(:[0-9]{1,5})?(\/.*)?$/i
  if (!pattern.test(url)) {
    return false
  }

  try {
    new URL(url)
    return true
  } catch (error: unknown) {
    return false
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const alphabeticalSort = (a: any, b: any) => a.name.localeCompare(b.name)

export const stripHtmlTags = (str: string) => str.replace(/<[^>]*>?/gm, '')
