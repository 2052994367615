import { Svg } from 'components'
import styled from 'styled-components'

export const StepperContainer = styled((props) => <div {...props} />)`
  display: flex;
  flex-direction: column;

  @media (min-width: ${(props) => props.theme['gridBreakpoints-lg']}) {
    flex-direction: row;
    padding: 0;
    min-height: calc(100vh - 67px);
    position: unset;
    left: 0;
  }
`

export const Content = styled(({ ...props }) => <div {...props} />)`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 1050px;
  padding: 2rem;
  margin-bottom: 2rem;

  @media (min-width: ${(props) => props.theme['gridBreakpoints-lg']}) {
    align-items: start;
    padding: 5rem;
  }
`

export const StepperForm = styled(({ isRegularStep, ...props }) => (
  <div {...props} />
))`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  p {
    text-align: center;
  }

  &.isRegularStep {
    align-items: start;
    p {
      text-align: left;
    }
  }

  @media (min-width: ${(props) => props.theme['gridBreakpoints-lg']}) {
    align-items: start;
    min-height: ${({ isRegularStep }) => isRegularStep && '30vh'};

    p {
      text-align: left;
    }
  }

  & .row.w-100 {
    width: calc(100% + 12.5px) !important;
    & > .col {
      padding-right: 0;
    }
  }
`

export const Yado = styled((props) => <Svg {...props} />)`
  width: 30rem;
`
