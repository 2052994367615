import { Tooltip } from 'components'
import { createIntl } from 'hooks'
import { FC, MouseEvent, useRef } from 'react'
import { RawIntlProvider } from 'react-intl'
import { EColor } from 'types'
import { uniqId } from 'utils'

import HelperButton from './components/Button'
import * as Style from './style'
import * as Type from './type'

const Helper: FC<Type.IHelper> = ({
  button = {
    backgroundColor: EColor.DARK_GREY,
    size: 'md',
    textColor: EColor.WHITE,
  },
  link,
  onClick,
  tooltip,
  withText,
  isSample,
  ...rest
}) => {
  const refUniqueId = useRef(uniqId('tooltipButton'))
  const intl = createIntl('components_helper')

  const buttonProps = {
    size: button.size,
    backgroundColor: button.backgroundColor,
    className: 'p-0',
    textColor: button.textColor,
    id: refUniqueId.current,
    onClick: (event: MouseEvent<HTMLElement>) => {
      onClick && onClick(event)
    },
    hasHover: !!link || !!onClick,
    withText,
    ...rest,
  }

  return (
    <RawIntlProvider value={intl}>
      {link ? (
        <Style.HelperLink
          className="ml-md-1 d-inline-flex justify-content-center align-items-center align-middle"
          href={link.href}
          target={link.target || '_blank'}
        >
          <HelperButton {...buttonProps} />
        </Style.HelperLink>
      ) : (
        <HelperButton isSample={isSample} {...buttonProps} />
      )}
      {tooltip && (
        <Tooltip
          target={refUniqueId.current}
          position={tooltip.position || 'auto'}
        >
          {tooltip.text}
        </Tooltip>
      )}
    </RawIntlProvider>
  )
}

export default Helper
