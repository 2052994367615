import { RadioBackgrounds as ReactRadioBackgrounds } from 'components'
import { IRadioBackgrounds } from 'components/Form/RadioBackgrounds/type'
import { FC } from 'react'
import { EColor } from 'types'

const RadioBackgrounds: FC<IRadioBackgrounds> = (props) => (
  <ReactRadioBackgrounds color={EColor.PUBLIC_MAIN_COLOR} {...props} />
)

export default RadioBackgrounds
