import classNames from 'classnames'
import {
  Col,
  DropdownItem,
  Icon,
  Link,
  Loader,
  Row,
  Svg,
  Tooltip,
} from 'components'
import { FC, useEffect, useRef, useState } from 'react'
import { EColor } from 'types'
import { uniqId } from 'utils'

import * as Style from './style'
import * as Type from './type'

const ResultsCard: FC<Type.IResultsCard> = ({
  badges,
  blocks,
  directOptions = [],
  footer,
  handleClick,
  image,
  menuOptions = [],
  optionsTooltip,
  statuses,
  subtitle,
  title,
  tooltip,
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const [divHeight, setDivHeight] = useState(0)
  const refTooltipUniqueId = useRef(uniqId('resultCardTooltip'))
  const refOptionsUniqueId = useRef(uniqId('resultCardOptions'))
  const refBadgeUniqueId = useRef(uniqId('resultCardBadge'))
  const [areOptionsOpened, setAreOptionsOpened] = useState(false)

  const handleResize = () => {
    if (ref.current) {
      setDivHeight(ref.current.clientWidth * 0.5)
    }
  }
  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()
  }, [])

  return (
    <Style.ResultsCard
      className="text-left"
      onClick={() => {
        handleClick && handleClick()
        setAreOptionsOpened(!areOptionsOpened)
      }}
      hasCTA={handleClick !== undefined}
    >
      <Row className="flex-column justify-between h-100">
        <Col xs="auto" className="flex-fill">
          {!!(
            statuses?.length ||
            tooltip ||
            directOptions?.length ||
            menuOptions?.length
          ) && (
            <Row
              className={classNames(
                'align-items-center',
                statuses ? 'justify-content-between' : 'justify-content-end'
              )}
            >
              {(statuses || tooltip) && (
                <Col>
                  <Row className="px-2">
                    {statuses && (
                      <>
                        {statuses.map((status, key) => (
                          <Col xs="auto" className="pl-0 pr-1 pb-1" key={key}>
                            <Style.Button
                              color={EColor.TRANSPARENT}
                              outline={true}
                              className="px-2"
                              {...(status.onClick && {
                                onClick: status.onClick,
                              })}
                            >
                              {status.icon && (
                                <Style.Icon
                                  icon={status.icon.icon}
                                  color={status.icon.color}
                                />
                              )}
                              <small
                                className={classNames(status.icon && 'pl-2')}
                              >
                                {status.text}
                              </small>
                            </Style.Button>
                          </Col>
                        ))}
                      </>
                    )}
                    {tooltip && (
                      <Col className="pl-2">
                        <Icon
                          icon={tooltip.icon}
                          color={tooltip.color}
                          id={refTooltipUniqueId.current}
                        />
                        <Tooltip
                          placement="top"
                          target={refTooltipUniqueId.current}
                        >
                          {tooltip.text}
                        </Tooltip>
                      </Col>
                    )}
                  </Row>
                </Col>
              )}
              <Col xs="auto">
                <Row>
                  {directOptions.map((directOption, key) => (
                    <Col className="d-none d-md-block pr-0 pl-1" key={key}>
                      <Link
                        href={directOption.href}
                        target={directOption.target || '_self'}
                      >
                        <Style.DirectButton
                          color={EColor.TRANSPARENT}
                          className="p-0"
                          id={`${refOptionsUniqueId.current}-${key}`}
                        >
                          <Style.HeaderIcon
                            icon={directOption.icon}
                            color={EColor.GREY}
                          />
                        </Style.DirectButton>
                      </Link>
                      <Tooltip target={`${refOptionsUniqueId.current}-${key}`}>
                        {directOption.text}
                      </Tooltip>
                    </Col>
                  ))}
                  {!!menuOptions?.length && (
                    <Col className="pl-1">
                      <Style.Dropdown
                        button={{
                          color: EColor.TRANSPARENT,
                          children: (
                            <Style.HeaderIcon
                              icon="ellipsis-v"
                              color={EColor.GREY}
                            />
                          ),
                          isRound: true,
                        }}
                        id={refOptionsUniqueId.current}
                        right={true}
                      >
                        {menuOptions.map(
                          (
                            { text, href, hasDivider, mobileOnly, onClick },
                            index
                          ) => {
                            const linkProps: {
                              [key: string]: string | (() => void)
                            } = {}
                            if (href) {
                              linkProps.href = href
                            }
                            if (onClick) {
                              linkProps.onClick = onClick
                            }
                            return (
                              <Style.DropwdownItemWrapper
                                key={index}
                                className={mobileOnly && 'd-md-none'}
                              >
                                {href || onClick ? (
                                  <Link {...linkProps}>
                                    <DropdownItem>{text}</DropdownItem>
                                  </Link>
                                ) : (
                                  <DropdownItem>
                                    <span>{text}</span>
                                  </DropdownItem>
                                )}
                                {hasDivider && <DropdownItem divider={true} />}
                              </Style.DropwdownItemWrapper>
                            )
                          }
                        )}
                      </Style.Dropdown>
                      {optionsTooltip && (
                        <Tooltip target={refOptionsUniqueId.current}>
                          {optionsTooltip}
                        </Tooltip>
                      )}
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          )}

          {title && (
            <div className="my-2">
              <Row>
                <Style.CardTitleCol>
                  <Style.CardTitle
                    type="h5"
                    color={EColor.BLACK}
                    className={classNames(subtitle ? 'mb-1' : 'mb-0')}
                  >
                    {title.icon && (
                      <Icon
                        icon={title.icon.icon}
                        color={title.icon.color}
                        className="mr-2"
                      />
                    )}
                    {title.text}
                  </Style.CardTitle>
                  {subtitle && <p className="mb-2">{subtitle}</p>}
                </Style.CardTitleCol>
              </Row>
            </div>
          )}

          <div>
            {typeof image === 'string' && (
              <Style.MediaWrapper>
                <div ref={ref} style={{ height: divHeight }}>
                  {image.length ? (
                    <Style.ImageDiv className="h-100" src={`${image}`} />
                  ) : (
                    <Style.SvgDiv className="d-flex justify-content-center align-items-center h-100">
                      <Svg
                        src={`common/icon/multicolor/circle-heart-house-1`}
                      />
                    </Style.SvgDiv>
                  )}
                </div>
              </Style.MediaWrapper>
            )}

            {blocks?.map((blockItem, blockIndex) => (
              <div
                className={classNames(
                  'mb-2 pt-2',
                  0 !== blockIndex && 'border-top pt-3'
                )}
                key={blockIndex}
              >
                <div className="px-0">
                  {blockItem.map(
                    ({ blockTitle, content, icon, list }, itemIndex) => (
                      <Row key={itemIndex}>
                        {icon && (
                          <Col xs="auto" className="pr-0">
                            <Icon icon={icon.icon} color={icon.color} />
                          </Col>
                        )}
                        <Col className={classNames(icon && 'pl-2')}>
                          {blockTitle && (
                            <p className="mb-0 font-weight-bold pb-1">
                              {blockTitle}
                            </p>
                          )}
                          {content && <p className="mb-0">{content}</p>}
                          {list && (
                            <Style.Ul>
                              {list.map(({ listContent }, ulIndex) => (
                                <Style.ListItem key={ulIndex}>
                                  {listContent}
                                </Style.ListItem>
                              ))}
                            </Style.Ul>
                          )}
                        </Col>
                      </Row>
                    )
                  )}
                </div>
              </div>
            ))}
          </div>
        </Col>

        <Col xs="auto">
          {badges && (
            <div className="mt-3 mt-md-0">
              {badges.map((badge, key) => (
                <div className="d-inline-block" key={key}>
                  <Style.ButtonBadge
                    id={`${refBadgeUniqueId.current}-${key}`}
                    color={EColor.TRANSPARENT}
                    outline={true}
                    className="px-3 py-1 mt-2 mr-3"
                  >
                    {badge.isLoading ? (
                      <Loader color={EColor.LIGHT_GREY} isRelative={true} />
                    ) : (
                      <>
                        <Icon
                          icon={badge.icon}
                          color={EColor.LIGHT_GREY}
                          className="mr-2"
                        />
                        {badge.text}
                      </>
                    )}
                  </Style.ButtonBadge>
                  {!badge.isLoading && (
                    <Tooltip target={`${refBadgeUniqueId.current}-${key}`}>
                      {badge.tooltip}
                    </Tooltip>
                  )}
                </div>
              ))}
            </div>
          )}

          {footer && (
            <p className="text-right mb-0 mt-3">
              <small className="pr-0 d-inline-block">{footer}</small>
            </p>
          )}
        </Col>
      </Row>
    </Style.ResultsCard>
  )
}
export default ResultsCard
