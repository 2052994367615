import { createElement } from 'react'
import styled from 'styled-components'

export const OptionAttribute = styled(({ children, tag, ...rest }) =>
  createElement(tag, rest, children)
)`
  &:not(:first-child) {
    margin-left: 4px;
  }
`
