import { API } from 'api/connector'

import * as Type from './type'

export const getInvoice = async (id: string): Promise<Type.IApiInvoice> => {
  const invoice = await API.get(`/api/v1/invoices/${id}`)
  return invoice.data
}

export const createInvoiceAccountingEntry = async (
  id: string
): Promise<{ success: boolean }> => {
  const accountingEntryExport = await API.post(
    `/api/v1/invoices/${id}/export`,
    {}
  )
  return accountingEntryExport.data
}
