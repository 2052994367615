import { createContext } from 'react'

import { IProAccountContext } from './type'

const ProAccountContext = createContext<IProAccountContext>({
  proAccountHolder: {
    '@id': '',
    '@type': '',
    '@context': '',
    swanId: '',
    account: {
      '@context': '',
      '@id': '',
      '@type': '',
      id: '',
      merchantProfileId: '',
      bankAccount: '',
      accountHolder: '',
      swanId: '',
    },
    organization: '',
    onboardingId: '',
  },
  proAccountMember: {
    '@context': '',
    '@id': '',
    '@type': '',
    swanId: '',
  },
  loading: false,
  retry: () => undefined,
})

export default ProAccountContext
