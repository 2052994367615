import { FC } from 'react'

import * as Style from './style'
import * as Type from './type'

const MenuToggle: FC<Type.IMenuToggle> = ({
  handleClick,
  color,
  menuOpened,
  href,
  title,
  isMenuHidden = false,
}) => (
  <Style.MenuToggle
    color={color}
    onClick={() => !isMenuHidden && handleClick()}
  >
    {href && <Style.MenuToggleBackLink href={href} />}
    <Style.MenuToggleTitle>{title}</Style.MenuToggleTitle>
    {!isMenuHidden && (
      <Style.MenuToggleButtonWrapper>
        <Style.MenuToggleButton menuOpened={menuOpened}>
          <Style.MenuToggleButtonLineMenuStart menuOpened={menuOpened} />
          <Style.MenuToggleButtonLineMenu />
          <Style.MenuToggleButtonLineMenuEnd menuOpened={menuOpened} />
        </Style.MenuToggleButton>
      </Style.MenuToggleButtonWrapper>
    )}
  </Style.MenuToggle>
)

export default MenuToggle
