import { FormGroup, FormHelper, Label } from 'components'
import { Field, FieldProps, getIn, useFormikContext } from 'formik'
import { FC } from 'react'
import { EColor } from 'types'

import * as Style from './style'
import * as Type from './type'

const RadioBackgrounds: FC<Type.IRadioBackgrounds> = ({
  radios,
  className,
  color = EColor.BLUE,
  disabled = false,
  handleChange,
  helpText,
  label,
  name,
  onChange,
  required = false,
  ...rest
}) => {
  const { errors, touched } = useFormikContext()
  const invalid = getIn(errors, name) && getIn(touched, name)
  return (
    <Field
      name={name}
      children={({ field, form: { setFieldValue } }: FieldProps) => (
        <FormGroup className={className}>
          {label && (
            <Label isRequired={required} className="d-block">
              {label}
            </Label>
          )}
          {radios.map((radio) => (
            <div key={radio.id}>
              <Style.RadioBackground
                color={color}
                className="p-3 mb-2"
                onClick={() => {
                  if (!disabled) {
                    setFieldValue(field.name, radio.value)
                    handleChange && handleChange(radio.value)
                  }
                }}
                active={field.value === radio.value}
                isInvalid={invalid}
                disabled={disabled}
                {...rest}
              >
                <div className={radio.children ? 'text-left' : 'text-center'}>
                  {radio.label && <strong>{radio.label}</strong>}
                  {radio.children && (
                    <div className="mb-2">{radio.children}</div>
                  )}
                  {radio?.description && (
                    <Style.Description className="mb-0">
                      {radio.description}
                    </Style.Description>
                  )}
                </div>
              </Style.RadioBackground>

              {helpText && field.value === radio.value && (
                <FormHelper
                  fieldName={field.name}
                  helpText={helpText}
                  color={color}
                />
              )}
            </div>
          ))}
          <input {...field} type="hidden" />
        </FormGroup>
      )}
    />
  )
}

export default RadioBackgrounds
