import {
  Background,
  Button as ReactButton,
  Col,
  Col as ReactCol,
  Dropdown as ReactDropdown,
  Icon as ReactIcon,
  ListItem as ReactListItem,
  Title,
} from 'components'
import { lighten } from 'polished'
import styled from 'styled-components'

export const ResultsCard = styled(({ ...props }) => <Background {...props} />)`
  display: flex;
  flex-direction: column;
  justify-content: start;

  ${({ hasCTA }) =>
    hasCTA &&
    `&:hover {
  cursor: pointer;
  }`}
`

export const Button = styled(({ width, ...props }) => (
  <ReactButton {...props} />
))`
  border-color: ${({ theme }) => theme.lightGrey};
  line-height: 1.286rem;
  width: ${(props) => props.width};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  & > svg {
    float: left;
    margin-top: 2px;
  }
`

export const DirectButton = styled(({ ...props }) => (
  <ReactButton {...props} />
))`
  width: 32px;
  height: 32px;
  border: 1px solid ${({ theme }) => theme.lightGrey};

  &:hover {
    border-color: ${({ theme }) => theme.blue};
    svg {
      color: ${({ theme }) => theme.blue};
    }
  }
`

export const Ul = styled((props) => <ul {...props} />)`
  padding-left: 0;
  margin-bottom: 0;
`
export const FooterCol = styled((props) => <ReactCol {...props} />)`
  padding-right: 0;
  text-align: right;

  & > small {
    line-height: 0.929rem;
  }
`
export const Icon = styled(({ ...props }) => <ReactIcon {...props} />)`
  width: 0.5rem !important;
`

export const MediaWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0 1px 3px 0 ${(props) => props.theme.lightGrey};
`

export const ImageDiv = styled(({ src, ...props }) => <div {...props} />)`
  background-image: url('${({ src }) => src}');
  background-size: cover;
  background-position: center;
`

export const SvgDiv = styled(({ ...props }) => <div {...props} />)`
  background-color: #e6edfd;
  overflow: hidden;
`

export const ListItem = styled(({ children, ...props }) => (
  <ReactListItem {...props}>{children}</ReactListItem>
))`
  margin-top: 0 !important;
`

export const CardTitleCol = styled((props) => <Col {...props} />)`
  display: flex;
  align-items: center;
  min-height: 42px;
`

export const CardTitle = styled(({ children, type, ...props }) => (
  <Title type={type} {...props}>
    {children}
  </Title>
))`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 42px;
`

export const DropwdownItemWrapper = styled(({ ...props }) => (
  <div {...props} />
))`
  &,
  & > a,
  & .dropdown-item {
    color: ${({ theme }) => theme.darkGrey};
    text-decoration: none;
  }
  &:hover > a {
    color: ${({ theme }) => theme.black};
  }
  &:hover .dropdown-item {
    background-color: ${({ theme }) => lighten(0.5, theme.darkGrey)};
    color: ${({ theme }) => theme.black};
  }
`

export const Dropdown = styled(({ ...props }) => <ReactDropdown {...props} />)`
  &:hover > button {
    border-color: ${({ theme }) => theme.blue};
    svg {
      color: ${({ theme }) => theme.blue};
    }
  }
`

export const HeaderIcon = styled(({ ...props }) => <ReactIcon {...props} />)`
  & {
    transition: 0.3s;
  }
  &:hover {
    color: ${({ theme }) => theme.blue};
  }
`

export const ButtonBadge = styled(({ ...props }) => <Button {...props} />)`
  color: ${({ theme }) => theme.grey} !important;
  cursor: default !important;
`
