import { Input } from 'components'
import { FormikValues } from 'formik'
import { createIntl, useTranslation } from 'hooks'
import postalCodes from 'postal-codes-js'
import { FC } from 'react'

import * as Type from './type'

const Postal: FC<Type.IPostal> = ({
  required = false,
  country,
  label,
  ...rest
}) => {
  const intl = createIntl('components_postal')
  const translation = useTranslation(intl)
  const validate = (value: string | FormikValues): undefined | string => {
    // Country
    if (required && !country) {
      return translation.translate('validate.country')
    }
    // Postal-codes-js exceptions
    if (
      // Prevent postal-codes-js from returning "Missing postal code" when field is empty but not required
      !value ||
      // Exception for Argentina: Handle "C1070" format (e.g.: "C1070" instead of "1070")
      (country === 'AR' &&
        value.substring(0, 1).match(/[a-z]/i) &&
        postalCodes.validate('AR', value.substring(1)) === true) ||
      // Exception for Argentina: Handle "C1070AAM" format (e.g.: "C1070AAM" instead of "1070")
      (country === 'AR' &&
        value.length === 8 &&
        !!(value.substring(0, 1) + value.substring(5, 8)).match(/^[a-z]+$/i) &&
        postalCodes.validate('AR', value.substring(1, 5)) === true) ||
      // Exception for Belgium: Handle "B-" prefix format (e.g.: "B-1234" instead of "1234")
      (country === 'BE' &&
        value.substring(0, 2) === 'B-' &&
        postalCodes.validate('BE', value.substring(2)) === true) ||
      // Exception for Brazil: Handle 5-digit format (e.g.: "12345" instead of "12345-123")
      (country === 'BR' &&
        value.length === 5 &&
        postalCodes.validate('BR', (value += '123')) === true) ||
      // Exception for Great Britain: Handle 3 letters format (e.g.: "P01" instead of "P011AA")
      (country === 'GB' &&
        value.length === 3 &&
        postalCodes.validate('GB', (value += '1AA')) === true) ||
      // Exception for Luxembourg: Handle "L-" prefix format (e.g.: "L-1234" instead of "1234")
      (country === 'LU' &&
        value.substring(0, 2) === 'L-' &&
        postalCodes.validate('LU', value.substring(2)) === true)
    ) {
      return
    }
    // Postal-codes-js
    if (postalCodes.validate(country, value) !== true) {
      return translation.translate('validate.postalCode')
    }
  }

  return (
    <Input
      label={label === undefined ? translation.translate('label') : label}
      placeholder={translation.translate('placeholder')}
      required={required}
      validate={validate}
      {...rest}
    />
  )
}

export default Postal
