import { Form as FormikForm, useFormikContext } from 'formik'
import { useTracking } from 'hooks'
import { FC, useEffect, useRef } from 'react'

import * as Type from './type'

const Form: FC<Type.IForm> = ({ preventErrorTracking = false, ...props }) => {
  const tracking = useTracking()
  // eslint-disable-next-line
  const { values, errors, touched } = useFormikContext<any>() // Any because we can not know the content of all forms
  const timer = useRef<ReturnType<typeof setTimeout>>()

  useEffect(() => {
    if (!preventErrorTracking && Object.keys(errors).length) {
      timer.current = setTimeout(() => {
        Object.keys(errors).forEach((key) => {
          if (touched?.[key]) {
            tracking.track('Field error', {
              field: key,
              value: values?.[key],
              error: errors[key],
              url: window.location.href,
            })
          }
        })
      }, 1000)
    }
    return () => timer.current && window.clearTimeout(timer.current)
  }, [values, errors, touched, preventErrorTracking, tracking])

  // NOSONAR to ignore the TypeScript blocking of the Form component of Formik:
  // src/types/formik/index.d.ts
  return <FormikForm {...props} /> // NOSONAR
}

export default Form
