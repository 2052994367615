import * as flags from 'country-flag-icons/string/3x2'
import { CountryCode } from 'libphonenumber-js'
import { FC, useState } from 'react'
import { getCountries, getCountryCallingCode } from 'react-phone-number-input'
import DropdownItem from 'reactstrap/lib/DropdownItem'
import { ECountry } from 'utils/Intl/type'

import * as Style from './style'
import * as Type from './type'

const DropDownItemComponent: FC<Type.ICountrySelectElt> = ({
  countries,
  onChange,
  labels,
}) => (
  <>
    {countries.map((country: CountryCode) => (
      <Style.DropdownItemComponent
        key={country}
        onClick={() => onChange(country as ECountry)}
      >
        <span dangerouslySetInnerHTML={{ __html: flags[country] }} />{' '}
        {labels[country]} +{getCountryCallingCode(country)}
      </Style.DropdownItemComponent>
    ))}
  </>
)

const CountrySelect: FC<Type.ICountrySelect> = ({
  onChange,
  labels,
  preferredCountries,
  selectedCountry,
  disabled,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)

  return (
    <span className="p-0">
      <Style.DropdownComponent
        disabled={disabled}
        isOpen={dropdownOpen}
        toggle={() => setDropdownOpen(!dropdownOpen)}
      >
        <Style.DropdownToggleComponent
          className={disabled && 'disabled'}
          caret={true}
        >
          <span dangerouslySetInnerHTML={{ __html: flags[selectedCountry] }} />
        </Style.DropdownToggleComponent>
        <Style.DropdownMenuComponent>
          {preferredCountries && (
            <>
              <DropDownItemComponent
                countries={preferredCountries}
                onChange={(country) => {
                  onChange(country)
                }}
                labels={labels}
              />

              <DropdownItem divider={true} />
            </>
          )}

          <DropDownItemComponent
            countries={getCountries() as Array<ECountry>}
            onChange={(country) => {
              onChange(country)
            }}
            labels={labels}
          />
        </Style.DropdownMenuComponent>
      </Style.DropdownComponent>
    </span>
  )
}

export default CountrySelect
