import { API } from 'api/connector'
import { IApiOperation } from 'api/type'
import { IFilterBag } from 'components/Table/type'
import { FormikValues } from 'formik'

import * as Type from './type'

export const getPspSubWalletOperations = async (
  pspSubWalletId: string,
  params: IFilterBag
): Promise<{ data: Array<IApiOperation>; totalItems: number }> => {
  const operations = await API.get(
    `/api/v1/psp_sub_wallets/${pspSubWalletId}/operations`,
    { params }
  )
  return {
    data: operations.data['hydra:member'],
    totalItems: operations.data['hydra:totalItems'],
  }
}

export const getPspSubWalletStatement = async (
  pspSubWalletId: string,
  params: FormikValues
): Promise<{
  data: Array<Type.IApiSubwalletStatement>
  totalItems: number
}> => {
  const operations = await API.get(
    `/api/v1/psp_sub_wallets/${pspSubWalletId}/psp_sub_wallet_statements`,
    { params }
  )
  return {
    data: operations.data['hydra:member'],
    totalItems: operations.data['hydra:totalItems'],
  }
}

export const sendOperationsExport = async (
  pspSubWalletId: string,
  filters: FormikValues
): Promise<string> =>
  API.post(
    `/api/v1/psp_sub_wallets/${pspSubWalletId}/operations/send-extract`,
    [],
    { params: filters }
  )

export const getPspSubWalletBalances = async (
  pspSubWalletId: string
): Promise<Type.IApiPspSubWalletBalances> => {
  const balances = await API.get(
    `/api/v1/psp_sub_wallets/${pspSubWalletId}/balances`
  )
  return balances.data
}
