import classNames from 'classnames'
import { Icon } from 'components'
import { Formik } from 'formik'
import { useTranslation } from 'hooks'
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { EColor } from 'types'

import * as Style from './style'
import * as Type from './type'

const getPaginationNumbers = (
  currentPage: number,
  pagesNumber: number
): Array<number> => {
  if (pagesNumber < 6) {
    return Array(pagesNumber)
      .fill('')
      .map((item, index) => index + 1)
  }

  // More than 5 pages
  const numbers = []

  // Current page + closest numbers
  if (currentPage !== 1) {
    numbers.push(currentPage - 1)
  }
  numbers.push(currentPage)
  if (currentPage !== pagesNumber) {
    numbers.push(currentPage + 1)
  }

  // Before current page
  if (currentPage > 2) {
    if (currentPage > 3) {
      numbers.unshift(NaN) // Equals "…"
    }
    numbers.unshift(1)
  }

  // After current page
  if (pagesNumber - currentPage > 1) {
    if (pagesNumber - currentPage > 2) {
      numbers.push(NaN) // Equals "…"
    }
    numbers.push(pagesNumber)
  }

  return numbers
}

const TablePagination: FC<Type.ITablePagination> = ({
  currentPage,
  resultsNumber,
  resultsPerPage,
  updatePageNumber,
  updateResultsPerPage,
  ...rest
}) => {
  const intl = useIntl()
  const translation = useTranslation(intl)

  const pagesNumber = Math.ceil(resultsNumber / resultsPerPage)
  const pages = getPaginationNumbers(currentPage, pagesNumber)
  return (
    <>
      {resultsNumber > 25 && (
        <div
          className="d-flex flex-column flex-lg-row-reverse flex-xl-row justify-content-between align-items-center"
          {...rest}
        >
          <Formik
            onSubmit={() => {
              // This is intentional to prevent eslint warning
            }}
            initialValues={{ resultsPerPage: 25 }}
            children={
              <>
                <div className="d-none d-xl-flex align-items-center mb-3 mb-lg-0">
                  <label htmlFor="resultsPerPage" className="my-0 mr-3 d-flex">
                    {translation.translate('select.label')}
                  </label>
                  <Style.Select
                    className="m-0"
                    name="resultsPerPage"
                    options={[
                      { label: 25, value: 25 },
                      { label: 50, value: 50 },
                      { label: 100, value: 100 },
                    ]}
                    onChangeCallback={updateResultsPerPage}
                  />
                </div>
                {pagesNumber > 1 && (
                  <Style.PageNumbersWrapper>
                    <Style.PageNumbersBackground className="w-auto p-0">
                      <Style.Ul
                        className={classNames(
                          'list-inline list-unstyled m-0 d-flex align-items-center',
                          {
                            'px-2': pagesNumber <= 5,
                          }
                        )}
                      >
                        <li
                          className={classNames('list-inline-item m-0 mr-1', {
                            'd-xl-inline-block': pagesNumber > 5,
                            'd-xl-none': pagesNumber <= 5,
                          })}
                        >
                          <Style.PreviousButton
                            className="bg-transparent"
                            disabled={currentPage === 1}
                            onClick={() => {
                              if (currentPage !== 1) {
                                updatePageNumber(currentPage - 1)
                              }
                            }}
                          >
                            <Icon
                              icon={['far', 'chevron-left']}
                              color={EColor.GREY}
                            />
                          </Style.PreviousButton>
                        </li>
                        {pages.map((pageNumber, index) => (
                          <li
                            key={index}
                            className="list-inline-item my-0 mx-1 d-none d-xl-block"
                          >
                            {Number.isNaN(pageNumber) ? (
                              <span>…</span>
                            ) : (
                              <Style.PageButton
                                active={currentPage === pageNumber}
                                onClick={() => {
                                  updatePageNumber(pageNumber)
                                }}
                              >
                                {pageNumber}
                              </Style.PageButton>
                            )}
                          </li>
                        ))}
                        <Style.PageNumberDisplay className="d-block d-xl-none">
                          <strong className="text-primary">
                            {currentPage}
                          </strong>
                          /{pagesNumber}
                        </Style.PageNumberDisplay>

                        <li
                          className={classNames('list-inline-item m-0 ml-1', {
                            'd-xl-inline-block': pagesNumber > 5,
                            'd-xl-none': pagesNumber <= 5,
                          })}
                        >
                          <Style.NextButton
                            className="bg-transparent"
                            disabled={currentPage === pagesNumber}
                            onClick={() => {
                              if (currentPage !== pagesNumber) {
                                updatePageNumber(currentPage + 1)
                              }
                            }}
                          >
                            <Icon
                              icon={['far', 'chevron-right']}
                              color={EColor.GREY}
                            />
                          </Style.NextButton>
                        </li>
                      </Style.Ul>
                    </Style.PageNumbersBackground>
                  </Style.PageNumbersWrapper>
                )}
              </>
            }
          />
        </div>
      )}
    </>
  )
}

export default TablePagination
