import styled, { keyframes } from 'styled-components'

const loading = keyframes`
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
`

export const LoaderWrapper = styled((props) => <div {...props} />)`
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.white};
  font-size: 1.143rem;
  flex-direction: column;
`

export const Loader = styled(({ color, ...props }) => <div {...props} />)`
  position: relative;
  width: ${(props) => (props.size ? `${props.size}px` : '50px')};
  height: ${(props) => (props.size ? `${props.size}px` : '50px')};
  top: ${(props) => props.size < 20 && '0.2rem'};
  transform: rotateZ(0deg);
  animation: ${loading} 2s infinite;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    width: 50%;
    height: 100%;
    border-top-left-radius: calc(50px + 4px);
    border-bottom-left-radius: calc(50px + 4px);
    border: ${(props) => (props.size < 20 ? '3px' : '4px')} solid
      ${({ color, theme }) => theme[color]};
    border-right: 0;
  }

  &:after {
    content: '';
    position: absolute;
    right: 0;
    width: 50%;
    height: 100%;
    border-top-right-radius: calc(50px + 4px);
    border-bottom-right-radius: calc(50px + 4px);
    border: ${(props) => (props.size < 20 ? '3px' : '4px')} solid
      ${(props) => props.theme.white};
    border-left: 0;
  }
`
