import { Button, Display, Link, Svg } from 'components'
import { FC } from 'react'
import { EColor } from 'types'

import * as Type from './type'

const EmptySpace: FC<Type.IEmptySpace> = ({
  button,
  message,
  title,
  yado,
  children,
  ...rest
}) => (
  <div className="text-center mt-5" {...rest}>
    <Display type="h3">{title}</Display>
    <div>
      <Svg src={`common/yado/${yado}`} width="350px" />
    </div>
    {message ?? children}
    {button && (
      <div className="pt-3">
        <Link
          href={button.href}
          onClick={button.onClick}
          preventQueryParameter={button?.preventQueryParameter || false}
        >
          <Button
            color={button.color ? button.color : EColor.BLUE}
            {...(button.isDisabled && { disabled: true })}
          >
            {button.text}
          </Button>
        </Link>
      </div>
    )}
  </div>
)

export default EmptySpace
