import { getOrganizationNonprofit } from 'api'
import { useApiError } from 'hooks'
import { OrganizationContext } from 'providers'
import { FC, ReactNode, useContext } from 'react'
import { useAsyncRetry } from 'react-use'

import NonprofitContext from './NonprofitContext'

const NonprofitProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const apiError = useApiError()
  const { organization } = useContext(OrganizationContext)

  const {
    value: nonprofit,
    loading,
    retry,
  } = useAsyncRetry(async () => {
    if (!organization.id) {
      return undefined
    }

    try {
      return await getOrganizationNonprofit(organization.id)
    } catch (error: unknown) {
      apiError.handleApiError(error)
      return null
    }
  }, [organization.id])

  if (nonprofit === undefined || nonprofit === null) {
    return <></>
  }

  return (
    <NonprofitContext.Provider value={{ nonprofit, loading, retry }}>
      {children}
    </NonprofitContext.Provider>
  )
}

export default NonprofitProvider
