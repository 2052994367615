import * as Sentry from '@sentry/react'
import { combineReducers, createStore } from 'redux'
import i18n, { i18nInitialState } from 'state/I18n/slice'
import loader, { loaderInitialState } from 'state/Loader/slice'
import menu, { menuInitialState } from 'state/Menu/slice'
import notifications, {
  notificationsInitialState,
} from 'state/Notifications/slice'
import organization, {
  organizationInitialState,
} from 'state/Organization/slice'
import page, { pageInitialState } from 'state/Page/slice'
import person, { personInitialState } from 'state/Person/slice'
import settings, { settingsInitialState } from 'state/Settings/slice'
import sidePanel, { sidePanelInitialState } from 'state/SidePanel/slice'
import specifications, {
  specificationsInitialState,
} from 'state/Specifications/slice'
import supportMessage, {
  supportMessageInitialState,
} from 'state/SupportMessage/slice'

export const initialState = {
  i18n: i18nInitialState,
  loader: loaderInitialState,
  notifications: notificationsInitialState,
  organization: organizationInitialState,
  page: pageInitialState,
  settings: settingsInitialState,
  sidePanel: sidePanelInitialState,
  specifications: specificationsInitialState,
  person: personInitialState,
  supportMessage: supportMessageInitialState,
  menu: menuInitialState,
}

const reducer = combineReducers({
  i18n,
  loader,
  notifications,
  organization,
  page,
  person,
  settings,
  sidePanel,
  specifications,
  supportMessage,
  menu,
})

const sentryReduxEnhancer = Sentry.createReduxEnhancer()

export default createStore(reducer, sentryReduxEnhancer)
