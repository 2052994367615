import { importMessages } from 'hooks/createIntl'
import { createIntl, createIntlCache, IntlShape } from 'react-intl'
import { formatLocale, getNavigatorLanguage } from 'utils'
import { ECaseType, ELocale } from 'utils/Intl/type'

/**
 * Create an instance of react intl and import the translation file
 * Do not use the createIntl hook because Sentry does not allow to use a hook
 * @param file - File to use from ./src/translations folder
 */
const CreateIntlWithNavigator = (file: string): IntlShape => {
  const locale =
    getNavigatorLanguage().substring(0, 2) === 'fr' ? 'fr_FR' : 'en_US'

  return createIntl(
    {
      locale: formatLocale(locale, ECaseType.KEBAB),
      messages: importMessages(file, locale as ELocale),
    },
    createIntlCache()
  )
}

export default CreateIntlWithNavigator
