import { Title } from 'components'
import { FC } from 'react'
import { EColor } from 'types'

import * as Type from './type'

const BackgroundTitle: FC<Type.IBackgroundTitle> = ({
  children,
  icon,
  iconColor,
}) => (
  <Title
    type="h2"
    color={EColor.BLACK}
    icon={icon}
    iconColor={iconColor}
    uppercase={true}
    className="d-flex m-0"
  >
    {children}
  </Title>
)

export default BackgroundTitle
