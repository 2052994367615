import { useSelector } from 'hooks'
import { FC } from 'react'
import { Helmet } from 'react-helmet'
import { IntlShape, RawIntlProvider } from 'react-intl'
import { selectLocale, selectPage } from 'state'
import { getLocaleShortcode } from 'utils'

import * as Type from './type'

const LayoutIntlProvider: FC<Type.IPageIntlProvider> = ({
  children,
  value = {} as IntlShape,
}) => {
  const page = useSelector(selectPage)
  const locale = useSelector(selectLocale)

  return (
    <RawIntlProvider value={value}>
      <Helmet
        htmlAttributes={{
          lang: getLocaleShortcode(locale),
        }}
      >
        <title>{page.title}</title>
      </Helmet>
      {children}
    </RawIntlProvider>
  )
}

export default LayoutIntlProvider
