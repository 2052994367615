import { Input } from 'components'
import { useFormikContext } from 'formik'
import { createIntl, useTranslation } from 'hooks'
import { ChangeEvent, FC, useState } from 'react'
import { normalizeString } from 'utils'

import * as Type from './type'
import * as Utils from './utils'

const Email: FC<Type.IEmail> = ({
  required = false,
  name,
  label,
  disabled = false,
  ...rest
}) => {
  const { setFieldValue } = useFormikContext()
  const intl = createIntl('components_email')
  const translation = useTranslation(intl)

  const [isDomainValid, setIsDomainValid] = useState<undefined | boolean>(
    undefined
  )

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsDomainValid(undefined)
    const value = normalizeString(event.target.value)
      .toLowerCase()
      .replace(/,|;/g, '.')
    setFieldValue(event.target.name, value)
  }

  const handleBlur = async (event: ChangeEvent<HTMLInputElement>) => {
    // checkEmailDomain
    const check = await Utils.checkEmailDomain(event.target.value)
    setIsDomainValid(check)
  }

  return (
    <Input
      disabled={disabled}
      prepend={{
        icon: ['far', 'at'],
      }}
      type="email"
      label={label === undefined ? translation.translate('label') : label}
      placeholder={translation.translate('placeholder')}
      required={required}
      validate={(value) =>
        Utils.handleValidateEmail(
          String(value),
          required,
          translation,
          isDomainValid
        )
      }
      onBlur={handleBlur}
      onChange={handleChange}
      name={name}
      {...rest}
    />
  )
}

export default Email
