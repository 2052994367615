import { API } from 'api/connector'
import { IApiOrganization } from 'api/type'

export const getToucanTocoToken = async (
  organizationId: IApiOrganization['id']
): Promise<{
  token: string
}> => {
  const toucanToken = await API.get(
    `/api/v1/organizations/${organizationId}/toucan/token`
  )
  return toucanToken.data.token
}
