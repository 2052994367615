import classNames from 'classnames'
import { FC } from 'react'
import { Label as ReactStrapLabel } from 'reactstrap'

import * as Type from './type'

const Label: FC<Type.ILabel> = ({
  children,
  className,
  isBold = true,
  isRequired = false,
  ...rest
}) => (
  <ReactStrapLabel
    className={classNames({ 'font-weight-bold': isBold }, className)}
    {...rest}
  >
    {children}
    {isRequired && <sup className="text-danger">*</sup>}
  </ReactStrapLabel>
)

export default Label
