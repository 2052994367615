import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { ReactNode } from 'react'
import { EColor, EYado } from 'types'

export type IStepper = {
  steps: Array<IStep & IStepIntroduction & IStepConclusion>
}

export interface IStep {
  content?: ReactNode
  type: EStepType
  title?: string
  isNextDisabled?: boolean
  onValidate?: () => Promise<boolean>
  actions?: { [key: string]: IActionButton }
}

export enum ENavigation {
  NEXT = 'next',
  PREVIOUS = 'previous',
  VALIDATE = 'validate',
}

export type IActionButton = {
  navigate?: ENavigation
  disabled?: boolean
  onClick?: () => boolean | void | Promise<void | boolean>
  validate?: () => Promise<boolean>
  text?: string
  tooltip?: string
  color?: EColor
  outline?: boolean
  icon?: IconProp
}

export interface IStepIntroduction extends IStep {
  yado?: EYado
}

export interface IStepConclusion extends IStep {
  yado?: EYado
}

export enum EStepType {
  INTRODUCTION = 'INTRODUCTION',
  STEP = 'STEP',
  CONCLUSION = 'CONCLUSION',
}
