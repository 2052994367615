import { browserHistory } from 'index'
import LegacyRedirect from 'pages/LegacyRedirect'
import { FC, lazy, Suspense } from 'react'
import { Router as ReactRouter, Route, Switch } from 'react-router-dom'

const AccountingRouter = lazy(() => import('./AccountingRouter'))
const ApplicationsRouter = lazy(() => import('./ApplicationsRouter'))
const CollectsRouter = lazy(() => import('./CollectsRouter'))
const DashboardRouter = lazy(() => import('./DashboardRouter'))
const ExportCenterRouter = lazy(() => import('./ExportCenterRouter'))
const DevRouter = lazy(() => import('./DevRouter'))
const DocumentsRouter = lazy(() => import('./DocumentsRouter'))
const NetworkRouter = lazy(() => import('./NetworkRouter'))
const OnboardingRouter = lazy(() => import('./OnboardingRouter'))
const EmailingRouter = lazy(() => import('./EmailingRouter'))
const HomeRouter = lazy(() => import('./HomeRouter'))
const OrderRouter = lazy(() => import('./OrderRouter'))
const PaymentRouter = lazy(() => import('./PaymentRouter'))
const PlatformRouter = lazy(() => import('./PlatformRouter'))
const ProAccountRouter = lazy(() => import('./ProAccountRouter'))
const SettingsRouter = lazy(() => import('./SettingsRouter'))
const SubscriptionRouter = lazy(() => import('./SubscriptionRouter'))
const TaxReceiptsRouter = lazy(() => import('./TaxReceiptsRouter'))
const UpgradeRouter = lazy(() => import('./UpgradeRouter'))
const WalletRouter = lazy(() => import('./WalletRouter'))

const AdminRouter: FC = () => (
  <ReactRouter history={browserHistory}>
    <Suspense fallback="">
      <Switch>
        <Route path="/organization/:organizationId/accounting*">
          <AccountingRouter />
        </Route>
        <Route path="/organization/:organizationId/applications*">
          <ApplicationsRouter />
        </Route>
        <Route path="/organization/:organizationId/collects*">
          <CollectsRouter />
        </Route>
        <Route path="/organization/:organizationId/export-center*">
          <ExportCenterRouter />
        </Route>
        <Route path="/organization/:organizationId/dev*">
          <DevRouter />
        </Route>
        <Route path="/organization/:organizationId/documents*">
          <DocumentsRouter />
        </Route>
        <Route path="/organization/:organizationId/network*">
          <NetworkRouter />
        </Route>
        <Route path="/organization/:organizationId/emailing*">
          <EmailingRouter />
        </Route>
        <Route path="/organization/:organizationId/home*">
          <HomeRouter />
        </Route>
        <Route path="/organization/:organizationId/onboarding*">
          <OnboardingRouter />
        </Route>
        <Route path="/organization/:organizationId/dashboard*">
          <DashboardRouter />
        </Route>
        <Route path="/organization/:organizationId/order*">
          <OrderRouter />
        </Route>
        <Route path="/organization/:organizationId/payment*">
          <PaymentRouter />
        </Route>
        <Route path="/organization/:organizationId/platform*">
          <PlatformRouter />
        </Route>
        <Route path="/organization/:organizationId/pro-account*">
          <ProAccountRouter />
        </Route>
        <Route path="/organization/:organizationId/settings*">
          <SettingsRouter />
        </Route>
        <Route path="/organization/:organizationId/subscription*">
          <SubscriptionRouter />
        </Route>
        <Route path="/organization/:organizationId/tax-receipts*">
          <TaxReceiptsRouter />
        </Route>
        <Route path="/organization/:organizationId/upgrade*">
          <UpgradeRouter />
        </Route>
        <Route path="/organization/:organizationId/wallet*">
          <WalletRouter />
        </Route>
        <Route path="*" component={LegacyRedirect} />
      </Switch>
    </Suspense>
  </ReactRouter>
)

export default AdminRouter
