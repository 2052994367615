export const domainsWhitelist = [
  'aol.com',
  'bbox.fr',
  'facebook.com',
  'free.fr',
  'gmail.com',
  'gmx.fr',
  'googlemail.com',
  'hotmail.es',
  'hotmail.fr',
  'hotmail.it',
  'hotmail.com',
  'hotmail.co.uk',
  'icloud.com',
  'comcast.net',
  'laposte.net',
  'live.ca',
  'live.fr',
  'live.co.uk',
  'live.com',
  'mac.com',
  'mail.com',
  'me.com',
  'msn.com',
  'neuf.fr',
  'numericable.fr',
  'orange.fr',
  'outlook.fr',
  'outlook.com',
  'rediffmail.com',
  'rocketmail.com',
  'sfr.fr',
  'sky.com',
  'uol.com.br',
  'voila.fr',
  'verizon.net',
  'wanadoo.fr',
  'web.de',
  'yahoo.co.jp',
  'yahoo.de',
  'yahoo.es',
  'yahoo.com',
  'yahoo.fr',
  'yahoo.co.uk',
  'yahoo.com.br',
  'yahoo.co.in',
  'yandex.ru',
  'yopmail.com',
  'ymail.com',
]
