import { API } from 'api/connector'
import * as Type from 'api/type'

export const createPaymentSettings = async (
  id: string,
  retributionMethod: Type.ERetributionType
): Promise<Type.IApiPayment> => {
  const payment = await API.post(`/api/v1/payment_settings`, {
    retributionMethod,
    organization: `/api/v1/organizations/${id}`,
  })
  return payment.data
}

export const updatePaymentSettings = async (
  id: string,
  retributionMethod: Type.ERetributionType
): Promise<Type.IApiPayment> => {
  const payment = await API.put(`/api/v1/payment_settings/${id}`, {
    retributionMethod,
  })
  return payment.data
}
