import classNames from 'classnames'
import { FC, useEffect } from 'react'

import * as Style from './style'
import * as Type from './type'

const Video: FC<Type.IVideo> = ({
  videoId,
  startsAt = '0',
  autoplay = false,
  popover = false,
  handleOnBlur,
}) => {
  useEffect(() => {
    // Make sure to only embed wistia's script once
    const hasGlobalWistiaScript = !!document.querySelector('#global-wistia')
    if (!hasGlobalWistiaScript) {
      appendWistiaVideoScript({
        src: 'https://fast.wistia.com/assets/external/E-v1.js',
        id: 'global-wistia',
      })
    }
  }, [])

  useEffect(() => {
    appendWistiaVideoScript({
      src: `https://fast.wistia.com/embed/medias/${videoId}.jsonp`,
    })
  }, [videoId])

  const appendWistiaVideoScript = (params: { src: string; id?: string }) => {
    const script = document.createElement('script')
    script.src = params.src
    script.async = true
    if (params.id) {
      script.id = params.id
    }
    document.body.appendChild(script)
  }

  return (
    <Style.Padding
      className="wistia_responsive_padding"
      onBlur={() => handleOnBlur}
    >
      <Style.Wrapper className="wistia_responsive_wrapper">
        <Style.Video
          className={classNames(
            'wistia_embed',
            `wistia_async_${videoId}`,
            `popover=${popover}`,
            `time=${startsAt}`,
            'popoverAnimateThumbnail=true',
            'videoFoam=true',
            `autoPlay=${autoplay ? 'true' : 'false'}`
          )}
        />
      </Style.Wrapper>
    </Style.Padding>
  )
}

export default Video
