import { FC, useEffect, useState } from 'react'

import PopoverFooter from './Footer'
import PopoverHeader from './Header'
import * as Style from './style'
import * as Type from './type'

const Popover: FC<Type.IPopover> = ({
  children,
  title,
  ctas,
  target,
  ...rest
}) => {
  const [targetElement, setTargetElement] = useState<null | Element>(null)

  useEffect(() => {
    if (target) {
      setTargetElement(document.querySelector('#' + target))
    }
  }, [target])

  return (
    <>
      {targetElement && (
        <Style.Popover target={target} trigger="legacy" {...rest}>
          <PopoverHeader title={title} />
          <Style.PopoverBody>{children}</Style.PopoverBody>
          <PopoverFooter ctas={ctas} />
        </Style.Popover>
      )}
    </>
  )
}

export default Popover
