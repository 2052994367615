import { Temporal } from '@js-temporal/polyfill'

export interface IDateFnsLocales {
  [key: string]: Locale
}

export interface IFormatOptions {
  locale?: Locale
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6
  firstWeekContainsDate?: number
  useAdditionalWeekYearTokens?: boolean
  useAdditionalDayOfYearTokens?: boolean
}

export interface IFormatDistanceOptions {
  includeSeconds?: boolean
  addSuffix?: boolean
  locale?: Locale
}

export interface IFormatDistanceStrictOptions {
  addSuffix?: boolean
  unit?: 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year'
  roundingMethod?: 'floor' | 'ceil' | 'round'
  locale?: Locale
}

export interface IFormatRelativeOptions {
  locale?: Locale
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6
}

export type IFormalAllOptions =
  | IFormatOptions
  | IFormatDistanceOptions
  | IFormatDistanceStrictOptions
  | IFormatRelativeOptions
  | undefined

export enum EDateFormat {
  YMD = 'ymd',
  LONG = 'long',
}

export type TemporalDate = Temporal.PlainDate | Temporal.PlainDateTime
