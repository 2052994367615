import { API } from 'api/connector'
import { IApiNonprofit } from 'api/type'
import { IFilterBag } from 'components/Table/type'

import { IApiAccountingAccount, IApiChartOfAccounts } from './type'

export const getAccountingChartOfAccounts = async (
  id: IApiNonprofit['id'],
  params: IFilterBag
): Promise<IApiChartOfAccounts> => {
  const entries = await API.get(`/api/v1/nonprofits/${id}/chart_of_accounts`, {
    params,
  })
  return entries.data
}

export const getUnifiedAccountingChartOfAccounts = async (
  apiUrl: string
): Promise<Array<IApiAccountingAccount>> => {
  const entries = await API.get(apiUrl)
  return entries.data.accounts
}

export const createCustomAccount = async (
  nonProfitId: IApiNonprofit['id'],
  accountNumber?: IApiAccountingAccount['accountNumber'],
  customName?: IApiAccountingAccount['shortCustomName'] | null
): Promise<IApiAccountingAccount> => {
  const entries = await API.post(`/api/v1/custom_accounts`, {
    nonprofit: nonProfitId,
    accountNumber: accountNumber ?? null,
    name: customName,
  })
  return entries.data
}

export const updateCustomAccount = async (
  accountId: string,
  accountNumber?: IApiAccountingAccount['accountNumber'],
  customName?: IApiAccountingAccount['shortCustomName'] | null
): Promise<IApiAccountingAccount> => {
  const entries = await API.put(accountId, {
    accountNumber,
    name: customName,
  })
  return entries.data
}

export const deleteCustomAccount = async (
  accountId: string
): Promise<IApiAccountingAccount> => {
  const entries = await API.delete(accountId)
  return entries.data
}
