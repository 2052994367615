import { IApiWorkflowAction } from 'api/type'
import {
  EAccountingAccountAction,
  EAccountingAccountType,
} from 'pages/Accounting/ChartOfAccounts/type'

export interface IApiOrganizationAccountingAccounts {
  '@id': string
  '@type': string
  accountNumber: number
  displayName: string
  officialName: string
  type: string
}

export interface IApiChartOfAccounts {
  '@context': string
  '@id': string
  '@type': string
  identifier: string
  accounts: Array<IApiAccountingAccount>
  isNumbered: boolean // US accounts are not numbered
  allAccounts: Array<IApiAccountingAccount>
  unifiedChartOfAccounts: string
}

export interface IApiUnifiedChartOfAccounts {
  data: Array<ISortedAccount>
  taxonNames: Array<string>
}

export interface IApiAccountingAccount {
  '@context': string
  '@id': string
  '@type': string
  displayName: string
  officialName: string
  shortCustomName: string
  type: EAccountingAccountType
  accountNumber: number
  taxon: string
  subTaxon: string
  archivedAt: string
  status: EChartOfAccountsStatus
  root?: number
  customAccount: {
    '@id': string
    '@context': string
    '@type': string
    ['workflow:actions']: IApiWorkflowAction<EAccountingAccountAction>[]
    isArchived: boolean
    isDeletable: boolean
    isUsed: boolean
    isEditable: boolean
    name: string
  }
}

export enum EChartOfAccountsStatus {
  DEFAULT = 'DEFAULT',
  ARCHIVED = 'ARCHIVED',
  CUSTOM = 'CUSTOM',
}

export interface ISortedAccount {
  officialName: IApiAccountingAccount['officialName']
  items: Array<IAccountSubTaxon>
}

export interface IAccountSubTaxon {
  officialName: IApiAccountingAccount['officialName']
  accountNumber?: IApiAccountingAccount['accountNumber']
  items: Array<Partial<IApiAccountingAccount>>
}

export interface IAccountError {
  message: string
  propertyPath: string
}
