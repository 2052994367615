import * as Sentry from '@sentry/react'
import { isAxiosError } from 'api/connector'
import { AxiosError } from 'axios'
import { Link } from 'components'
import { FormikErrors, FormikValues } from 'formik'
import { createIntl, useSelector, useTranslation } from 'hooks'
import { IUseTranslation } from 'hooks/useTranslation/type'
import { useDispatch } from 'react-redux'
import { addNotification, selectOrganization, selectPerson } from 'state'
import { IStoreOrganization, IStorePerson } from 'state/type'
import { EColor } from 'types'
import { getHelpCenterTicketForm } from 'utils'

import * as Type from './type'

const getErrorText = (
  apiError: Type.IApiError,
  translation: IUseTranslation,
  error: AxiosError,
  organization: IStoreOrganization,
  person: IStorePerson
) =>
  apiError?.['hydra:description'] ||
  translation.translate(
    error.response?.status === 404 ? 'error.404' : 'error',
    {
      a: (str: string) => (
        <Link
          href={getHelpCenterTicketForm(organization, person)}
          role="button"
        >
          <u>{str}</u>
        </Link>
      ),
    }
  )

const UseApiError = (): Type.IUseApiError => {
  const intl = createIntl('hooks_useapierror')
  const translation = useTranslation(intl)
  const dispatch = useDispatch()
  const organization = useSelector(selectOrganization)
  const person = useSelector(selectPerson)

  /**
   * Documentation: https://docs.google.com/presentation/d/1vsfV7UBKPqqVeEipEp8DbvaJsObAmoqKeUjJtov41Q4/edit?usp=sharing
   * @param error
   * @param params
   * @returns
   */
  const handleApiError = (
    error: unknown,
    params?: {
      allowedErrorCodes?: number[]
      formPrefix?: string
      setErrors?: (errors: FormikErrors<FormikValues>) => void
    }
  ): void => {
    // Unknown error
    if (!isAxiosError(error)) {
      Sentry.captureException(error)
      return
    }

    // Authorized error
    if (params?.allowedErrorCodes?.includes(error?.response?.status || 0)) {
      return
    }

    // Error 403
    if (error.response?.status === 403) {
      window.location.href = '/errors/403'
      return
    }

    if (error.response?.status === 422 && params?.setErrors) {
      const apiFormError = error.response.data as Type.IApiError422

      if (apiFormError.violations) {
        for (const { propertyPath, message } of apiFormError.violations) {
          const prefix = params?.formPrefix
          const fieldName = `${prefix ? `${prefix}_` : ''}${propertyPath}`
          // Handle violation
          params.setErrors({
            [fieldName]: message,
          })
        }
        return
      }
    }

    // Other errors
    const apiError = error.response?.data as Type.IApiError

    Sentry.captureException(error)
    dispatch(
      addNotification({
        color: EColor.RED,
        text: getErrorText(apiError, translation, error, organization, person),
      })
    )
  }

  return { handleApiError }
}

export default UseApiError
