import { FC } from 'react'

import * as Style from './style'
import * as Type from './type'

const Container: FC<Type.IContainer> = ({
  children,
  fullWidthStyle = false,
  tag = 'div',
  isMenuHidden = false,
  ...rest
}) => (
  <Style.Container
    tag={tag}
    fullWidthStyle={fullWidthStyle}
    isMenuHidden={isMenuHidden}
    {...rest}
  >
    <Style.ContainerInner fullWidthStyle={fullWidthStyle}>
      {children}
    </Style.ContainerInner>
  </Style.Container>
)

export default Container
