import { Container, Title } from 'components'
import styled from 'styled-components'

export const PageTitle = styled((props) => <Title type="h1" {...props} />)`
  flex: 1;
  & > small {
    font-size: 65%;
  }
`

export const Header = styled(({ isMenuHidden, fullWidthStyle, ...props }) => (
  <Container
    isMenuHidden={isMenuHidden}
    fullWidthStyle={fullWidthStyle}
    {...props}
  />
))`
  background-color: ${({ theme }) => theme.white};
  width: ${({ isMenuHidden }) =>
    isMenuHidden ? '100%' : 'calc(100vw - 220px)'};
  padding-left: ${({ fullWidthStyle }) => fullWidthStyle && '2rem'};
  padding-right: ${({ fullWidthStyle }) => fullWidthStyle && '2rem'};
`
