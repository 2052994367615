import classNames from 'classnames'
import { FC } from 'react'
import { EColor } from 'types'

import HelperIcon from '../Icon'
import * as Style from './style'
import * as Type from './type'

const HelperButton: FC<Type.IHelperButton> = ({
  backgroundColor = EColor.GREEN,
  className,
  hasHover = false,
  size = 'lg',
  tag,
  textColor = EColor.WHITE,
  withText,
  isSample,
  ...rest
}) => (
  <Style.HelpButton
    size={size}
    tag={tag}
    className={classNames(className, 'mx-1 mb-1')}
    hasHover={hasHover && !isSample}
    isSample={isSample}
    backgroundColor={backgroundColor}
    {...rest}
  >
    <HelperIcon
      withText={withText}
      size={size}
      textColor={textColor}
      backgroundColor={backgroundColor}
    />
  </Style.HelpButton>
)

export default HelperButton
