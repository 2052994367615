import { Icon as ComponentIcon } from 'components'
import styled from 'styled-components'

import * as Data from './data'
import { IBadgeSize } from './type'

const getPixel = (size: IBadgeSize) =>
  Data.BadgeSizes[size] ? Data.BadgeSizes[size] : Data.BadgeSizes.sm

export const Badge = styled(({ color, size, ...props }) => <div {...props} />)`
  width: ${({ size }) => getPixel(size).badge};
  min-width: ${({ size }) => getPixel(size).badge};
  height: ${({ size }) => getPixel(size).badge};
  border-radius: ${({ size }) => (size === 'auto' ? '10px' : '50%')};
  font-size: 1.142rem;
  background-color: ${({ theme, color }) => theme[color]};
`

export const BadgeText = styled(({ color, hasIcon, ...props }) => (
  <span {...props} />
))`
  color: ${({ color, hasIcon, theme }) =>
    hasIcon ? theme[color] : theme.white};
  ${({ color, hasIcon, theme }) =>
    !hasIcon && `background-color: ${theme[color]};`};
`
export const Icon = styled(({ size, ...props }) => (
  <ComponentIcon {...props} />
))`
  font-size: ${({ size }) => getPixel(size).icon};
`
