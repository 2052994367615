import classNames from 'classnames'
import {
  Background,
  Badge,
  Button,
  Col,
  Contact,
  Display,
  DownloadButton,
  DropdownItem,
  Icon,
  Input,
  Row,
  Select,
  SelectBankAccounts,
  Skeleton,
  Svg,
  Tooltip,
} from 'components'
import { EContactType } from 'components/Form/Algolia/Contact/type'
import { Checkbox } from 'components/Form/Checkbox/style'
import Dinero from 'dinero.js'
import { Formik, FormikValues } from 'formik'
import { createIntl, useTableData, useTranslation } from 'hooks'
import { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from 'react'
import { RawIntlProvider } from 'react-intl'
import { CellValue, useTable } from 'react-table'
import { useAsync } from 'react-use'
import { EColor, ESize, EYado } from 'types'
import { formatDate, getFormattedAmount, uniqId } from 'utils'

import TableButton from './Button'
import TableFilter from './Filter'
import TablePagination from './Pagination'
import Thead from './Thead'
import { IHeaderGroup } from './Thead/type'
import * as Style from './style'
import { StatusButton } from './style'
import * as Type from './type'
import { getBatchType, toggleRow } from './utils'

const FiltersSelect = (select: Type.IFilterSelect) => (
  <Select
    className="w-100 m-0"
    name={select.name}
    isSearchable={true}
    isClearable={true}
    isMulti={select.isMulti}
    placeholder={select.placeholder}
    onChangeCallback={select.onChangeCallback}
    options={select.options}
  />
)

const FilterSearch = (
  input: Type.IFilterSearch,
  resetPageNumber: () => void
) => (
  <Input
    name={input.name}
    placeholder={input.placeholder}
    onChange={(e: ChangeEvent<HTMLInputElement>) => {
      input.onChange && input.onChange(e)
      resetPageNumber()
    }}
    className="mb-0"
  />
)

const FilterSearchContact = (input: Type.IFilterSearch) => (
  <Contact
    contactType={input.contactType ?? EContactType.CONTACT}
    className="mb-0"
    placeholder={input.placeholder}
    name={input.name}
    showHelpText={false}
    onSuggestionSelected={async (suggestion) => {
      input.onSelect?.(suggestion)
    }}
  />
)

const FilterSelectBankAccount = (select: Type.IFilterSelectBankAccount) => (
  <SelectBankAccounts
    className="mb-0"
    name="bankAccount"
    placeholder={select.placeholder}
    bankAccounts={select.bankAccounts || []}
    displayKycStatus={true}
    disabled={!select.bankAccounts}
    onChangeCallback={() => select.onChangeCallback()}
  />
)

const Table: FC<Type.ITable> = ({
  data,
  columns,
  batch,
  emptyDataText,
  filterForm,
  filterLogic,
  filterMatch,
  filterSearch,
  filterSearchContact,
  filterSelectBankAccount,
  filterSelect,
  exportActions,
  headerAction,
  initialValues = {},
  displayMoreFilters = true,
  pagination = true,
  displayRowsCount = true,
  title,
}) => {
  const intl = createIntl('components_table')
  const translation = useTranslation(intl)
  const [haveFilter, setHaveFilter] = useState(false)
  const [loading, setLoading] = useState(true)
  const [tableData, setTableData] = useState<Array<Type.IRowData> | undefined>(
    undefined
  )
  const [resultsNumber, setResultsNumber] = useState<number>(0)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [itemsPerPage, setItemsPerPage] = useState<number>(25)
  const [selectedRows, setSelectedRows] = useState<Array<Type.IRowData>>([])
  const [sortBy, setSortBy] = useState<undefined | Type.ISortBy>(undefined)
  const tableRef = useRef<HTMLDivElement>()

  const { isLoading: isLoadingTableData } = useTableData()

  useEffect(() => {
    if (isLoadingTableData) {
      setLoading(true)
    }
  }, [isLoadingTableData])

  const currentDataTable = useMemo(() => tableData || [], [tableData])

  if (batch && columns[0].accessor !== 'selection') {
    columns.unshift({
      Header: 'selection',
      accessor: 'selection',
      type: 'selection',
      size: 40,
    })
  }

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable({
      data: currentDataTable,
      columns,
      autoResetSelectedRows: false,
      autoResetSelectedCell: false,
      autoResetSelectedColumn: false,
    })

  useAsync(async () => {
    await handleSubmit({})
  }, [])

  const handleSubmit = async (values: FormikValues) => {
    setLoading(true)
    {
      const filterResult = getFilterResult(values)

      sortBy &&
        Object.assign(filterResult, {
          [`order[${sortBy.columnName}]`]: sortBy.type,
        })

      const dataFetched = await data(
        filterLogic ? filterLogic(filterResult) : {},
        () => handleSubmit(values) // Refresh the table results with the current filters
      )
      if (!dataFetched) {
        setTableData([])
        setLoading(true)
        return
      }
      setResultsNumber(dataFetched.totalItems)
      setTableData(dataFetched.data)
      setLoading(false)
    }
  }

  const getTableStatus = () => {
    if (loading) {
      return 'loading'
    }
    if (currentDataTable.length) {
      return 'ready'
    }
    if (haveFilter) {
      return 'emptySearch'
    }
    return 'emptyData'
  }

  const getRows = () => {
    const status = getTableStatus()

    // Skeleton
    if (status === 'loading') {
      return Array(5)
        .fill(null)
        .map((_, index) => (
          <tr key={index}>
            {headerGroups[0].headers.map((column: IHeaderGroup) => (
              <Style.TableCell
                align={column.align}
                size={column.size}
                {...column.getHeaderProps()}
              >
                <Skeleton className="m-0" minHeight={15} />
              </Style.TableCell>
            ))}
          </tr>
        ))
    }

    // Emptyspace
    if (['emptyData', 'emptySearch'].includes(status)) {
      return (
        <tr>
          <td colSpan={headerGroups[0].headers.length}>
            <Svg
              className="mt-4"
              src={`common/yado/${
                status === 'emptySearch' ? EYado.EMPTY_SEARCH : EYado.EMPTY_DATA
              }`}
              width="350px"
            />
            <div>
              {status === 'emptySearch' ? (
                <>
                  <strong>
                    {translation.translate(`table.${status}.strong`)}
                  </strong>
                  <br />
                  {translation.translate(`table.${status}.text`)}
                </>
              ) : (
                <div className="mt-3">{emptyDataText}</div>
              )}
            </div>
          </td>
        </tr>
      )
    }

    // Rows
    return rows.map((row: Type.IRow) => {
      prepareRow(row)
      return (
        <Style.TableRow {...row.getRowProps()}>
          {row.cells.map((cell: Type.ICell) => (
            <Style.TableCell
              align={cell.column.align}
              size={cell.column.size}
              type={cell.column.type}
              {...cell.getCellProps()}
            >
              {getCellContent(
                cell.value,
                cell.column.type || '',
                row.original.rowData
              )}
            </Style.TableCell>
          ))}
        </Style.TableRow>
      )
    })
  }

  const getRowDisplayed = () => {
    if (loading || !tableData?.length) {
      return <span>&nbsp;</span>
    }

    if (!pagination) {
      return translation.translate('table.withoutPagination.rowsDisplayed', {
        start: 1,
        end: resultsNumber,
      })
    }

    return translation.translate('table.rowsDisplayed', {
      start: (currentPage - 1) * itemsPerPage + 1,
      end:
        currentPage * itemsPerPage + 1 > resultsNumber
          ? resultsNumber
          : currentPage * itemsPerPage,
      total: resultsNumber,
    })
  }

  const getCellText = (value: CellValue) => {
    if (!value) {
      return null
    }
    if (value.secondaryText) {
      return (
        <div>
          <strong className="font-weight-bold">{value.text}</strong> <br />
          {value.secondaryText}
        </div>
      )
    } else {
      if (value.background) {
        return (
          <Style.TableCellBackground background={value.background}>
            <Style.TableCellColor color={value.color}>
              {value.text}
            </Style.TableCellColor>
          </Style.TableCellBackground>
        )
      } else {
        return value.isBold ? (
          <strong className="font-weight-bold">
            <Style.TableCellColor color={value.color}>
              {value.text}
            </Style.TableCellColor>
          </strong>
        ) : (
          <Style.TableCellColor color={value.color}>
            {value.text}
          </Style.TableCellColor>
        )
      }
    }
  }

  const getCellContent = (
    value: CellValue,
    type: string,
    rowData: Type.IRowData
  ) => {
    if (typeof value === 'string' && !value.length) {
      return '-'
    }

    switch (type) {
      case 'amount': {
        return <div className="text-right">{getCellAmount(value)}</div>
      }
      case 'date': {
        return formatDate(value)
      }
      case 'icon': {
        const refUniqueId = uniqId('icon-tooltip')
        return (
          <span id={refUniqueId}>
            <Icon {...value} />
            {value?.tooltip && refUniqueId && (
              <Tooltip
                placement="top"
                target={refUniqueId}
                className="d-none d-lg-block"
              >
                {value.tooltip}
              </Tooltip>
            )}
          </span>
        )
      }
      case 'badge': {
        const refUniqueId = uniqId('badge-tooltip')
        return (
          <span id={refUniqueId}>
            <Badge {...value} size={value.size ?? ESize.XS} />
            {value?.tooltip && refUniqueId && (
              <Tooltip
                placement="top"
                target={refUniqueId}
                className="d-none d-lg-block"
              >
                {value.tooltip}
              </Tooltip>
            )}
          </span>
        )
      }
      case 'text': {
        return getCellText(value)
      }
      case 'status': {
        return <StatusButton color={value.color}>{value.text}</StatusButton>
      }
      case 'button': {
        return (
          <TableButton tag="Button" value={value} selectedRows={selectedRows} />
        )
      }
      case 'buttons': {
        return (
          <div className="d-flex justify-content-end">
            {value.map((val: CellValue, index: number) => (
              <div key={index} className="d-flex">
                <TableButton
                  tag="ResponsiveButton"
                  value={val}
                  selectedRows={selectedRows}
                  className="m-1"
                />
              </div>
            ))}
          </div>
        )
      }
      case 'selection': {
        return (
          <Checkbox
            id={uniqId()}
            type="checkbox"
            checked={selectedRows.some((row) => row['@id'] === rowData['@id'])}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              if (event.currentTarget.checked) {
                setSelectedRows((prevSelection) =>
                  toggleRow(true, [rowData], prevSelection)
                )
              } else {
                setSelectedRows((prevSelection) =>
                  toggleRow(false, [rowData], prevSelection)
                )
              }
            }}
          />
        )
      }
      default:
        return value
    }
  }

  const isDinero = (item: CellValue) => typeof item.getAmount !== 'undefined'

  const getCellAmount = (value: CellValue) => {
    if (!value) {
      return '-'
    }

    // Check if value is already a Dinero object. Else cast it as a dinero object
    const amount = isDinero(value) ? value : Dinero(value)
    return getFormattedAmount(amount)
  }

  const getFilterResult = (values: FormikValues) => {
    const filterResult = pagination
      ? {
          page: currentPage,
          itemsPerPage,
        }
      : {}
    for (const key in filterMatch) {
      if (values[key]) {
        Object.assign(filterResult, {
          [filterMatch[key]]: values[key],
        })
      }
    }

    return filterResult
  }

  const getExportActions = (
    actions: Array<Type.IExportAction>,
    values: {
      [key: string]: string | string[]
    },
    isDisabled: boolean
  ) => {
    if (actions === undefined) {
      return null
    }

    const filterResult = getFilterResult(values)

    if (actions.length > 1) {
      return (
        <Style.Dropdown
          isDisabled={isDisabled}
          className="ml-3"
          button={{
            color: EColor.BLUE,
            children: translation.translate('action.exportMultiple'),
          }}
        >
          {actions.map((action) => (
            <DropdownItem
              key={action.text}
              onClick={() => action.onClick(filterResult)}
            >
              {action.text}
            </DropdownItem>
          ))}
        </Style.Dropdown>
      )
    }

    return (
      <DownloadButton
        className="mr-2"
        disabled={!!selectedRows.length}
        onClick={() => actions[0].onClick(filterResult)}
        label={translation.translate('action.export')}
      />
    )
  }

  return (
    <RawIntlProvider value={intl}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        children={({ setFieldValue, submitForm, values }) => {
          const filterSelectInput =
            filterSelect && filterSelect(submitForm, setFieldValue)
          const filterSearchInput =
            filterSearch && filterSearch(submitForm, setFieldValue)
          const filterSearchContactInput =
            filterSearchContact &&
            filterSearchContact(submitForm, setFieldValue)

          const filterSelectBankAccountInput =
            filterSelectBankAccount &&
            filterSelectBankAccount(submitForm, setFieldValue)
          return (
            <>
              {(filterForm || filterSelect) && (
                <TableFilter
                  loading={loading}
                  resultsNumber={resultsNumber}
                  resetPageNumber={() => setCurrentPage(1)}
                  onSubmitFilter={submitForm}
                  setHaveFilter={setHaveFilter}
                  mainFilterField={
                    <>
                      {filterSelectInput && FiltersSelect(filterSelectInput)}
                      {filterSearchInput &&
                        FilterSearch(filterSearchInput, () =>
                          setCurrentPage(1)
                        )}
                      {filterSearchContactInput &&
                        FilterSearchContact(filterSearchContactInput)}
                      {filterSelectBankAccountInput &&
                        FilterSelectBankAccount(filterSelectBankAccountInput)}
                    </>
                  }
                  displayMoreFilters={displayMoreFilters}
                >
                  {filterForm?.(setFieldValue, submitForm, values)}
                </TableFilter>
              )}

              <div
                ref={(element: HTMLInputElement) =>
                  (tableRef.current = element)
                }
              >
                <Row className="align-items-baseline mb-2 justify-content-between">
                  {title !== undefined && (
                    <Col
                      xs={
                        (headerAction || exportActions) && displayRowsCount
                          ? 12
                          : 'auto'
                      }
                    >
                      <Display
                        type="h3"
                        className={classNames(
                          'mb-3',
                          !(
                            (headerAction || exportActions) &&
                            displayRowsCount
                          ) && 'mb-md-0'
                        )}
                      >
                        {title.icon && (
                          <Icon
                            size="lg"
                            icon={title.icon}
                            className="mr-2"
                            color={EColor.BLUE}
                          />
                        )}
                        {title.text}
                      </Display>
                    </Col>
                  )}

                  {(headerAction || exportActions) && (
                    <Col className="d-flex align-items-center" xs="auto">
                      {headerAction && (
                        <Button
                          color={EColor.BLUE}
                          disabled={headerAction.disabled}
                          onClick={() => headerAction.onClick(submitForm)}
                        >
                          {headerAction.text}
                        </Button>
                      )}
                      {exportActions &&
                        resultsNumber > 0 &&
                        getExportActions(
                          exportActions,
                          values,
                          !!headerAction?.disabled
                        )}
                    </Col>
                  )}
                  {displayRowsCount && (
                    <Col xs="auto">
                      <p
                        data-id="paginationDisplay"
                        className="text-right m-0 mr-2"
                      >
                        {getRowDisplayed()}
                      </p>
                    </Col>
                  )}
                </Row>
                <Background className="p-2 mt-0 mb-3 text-center">
                  <Style.Table
                    className="mb-0 table-striped"
                    {...getTableProps()}
                  >
                    <Thead
                      headerGroups={headerGroups}
                      setSelectedRows={setSelectedRows}
                      rows={rows}
                      sortBy={async (type, columnName) => {
                        setSortBy(() => ({ type, columnName }))
                        await handleSubmit(values)
                      }}
                      selectedRows={selectedRows}
                    />
                    <tbody {...getTableBodyProps()}>{getRows()}</tbody>
                  </Style.Table>
                  <Style.BatchButton
                    className={classNames({ 'd-none': !selectedRows.length })}
                    color={EColor.BLUE}
                    onClick={() =>
                      batch &&
                      batch.onClick(selectedRows, async () => {
                        await handleSubmit(values)
                        setSelectedRows([])
                      })
                    }
                  >
                    {translation.translate(`table.batch.button`, {
                      text: batch?.text ? `${batch.text} ` : '',
                      type: getBatchType(batch, selectedRows, translation),
                      number: selectedRows.length,
                    })}
                  </Style.BatchButton>
                </Background>
                {pagination && (
                  <TablePagination
                    currentPage={currentPage}
                    resultsNumber={resultsNumber}
                    resultsPerPage={itemsPerPage}
                    updatePageNumber={async (number: number) => {
                      setCurrentPage(number)
                      await submitForm()
                    }}
                    updateResultsPerPage={async (number: number) => {
                      setCurrentPage(1)
                      setItemsPerPage(number)
                      await submitForm()
                    }}
                  />
                )}
              </div>
            </>
          )
        }}
      />
    </RawIntlProvider>
  )
}

export default Table
