import { Col, FormGroup, FormHelper, Row, Svg } from 'components'
import { Radio } from 'components/Form/Radio/style'
import { Field, FieldProps } from 'formik'
import { createIntl, useTranslation } from 'hooks'
import { FC, useRef } from 'react'
import { uniqId } from 'utils'

import * as Style from './style'
import * as Type from './type'

const RadioCard: FC<Type.IInputCard> = ({
  checked,
  className,
  children,
  disabled,
  handleChange,
  id,
  isBold = false,
  label,
  name,
  type,
  svg,
  value,
  ...rest
}) => {
  const refUniqueId = useRef(uniqId('radioCard'))
  const intl = createIntl('components_radio_card')
  const translation = useTranslation(intl)
  return (
    <Field
      name={name}
      validate={(radioValue: string | number) =>
        radioValue ? '' : translation.translate('validate.required')
      }
      children={({ field, form: { setFieldValue } }: FieldProps) => (
        <FormGroup>
          <Style.RadioCard
            className="m-0 p-3"
            hoverShadow={true}
            onClick={async () => {
              if (!disabled) {
                await setFieldValue(field.name, value)
              }
              handleChange && handleChange()
            }}
            checked={field.value === value}
          >
            <Row className="align-items-center">
              <Col xs="auto">
                <Svg src={`common/icon/multicolor/${svg}`} width="40px" />
              </Col>
              <Col>
                {label && (
                  <Style.Label className="mb-0 text-muted" isBold={isBold}>
                    {label}
                  </Style.Label>
                )}
                {children && <div className="mt-1">{children}</div>}
              </Col>
              <Col xs="auto">
                <Radio
                  {...field}
                  className="w-100"
                  disabled={disabled}
                  id={id || refUniqueId.current}
                  label="&nbsp;"
                  type="radio"
                  checked={field.value === value}
                  value={value}
                  {...rest}
                />
              </Col>
            </Row>
            <FormHelper fieldName={field.name} />
          </Style.RadioCard>
        </FormGroup>
      )}
    />
  )
}

export default RadioCard
