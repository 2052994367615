import { EApiPspWalletKycStatus } from 'api/type'

export type TKycError = null | {
  code: number
  message: string
}

export interface IApiPspWalletAdyenKycSummary {
  [key: string]: {
    status: EApiPspWalletKycStatus
    error: TKycError
  }
}

export interface ISendPspWalletAdyenBankAccountDocument {
  pspWalletId: string
  bankAccountId: string
  documentId: string
}

export interface ISendPspWalletAdyenShareholderDocument {
  pspWalletId: string
  idCardFrontId: string
  idCardBackId: string
  passportId: string
  drivingLicenseFrontId: string
  drivingLicenseBackId: string
}

export interface ISendPspWalletAdyenOrganizationDocument {
  pspWalletId: string
  documentId?: string
}

export interface ITriggerUnsuspendPspWalletAdyen {
  pspWalletId: string
}

export enum EPspWalletAdyenJobTitle {
  PRESIDENT = 'PRESIDENT',
  VICE_PRESIDENT = 'VICE_PRESIDENT',
  TREASURER = 'TREASURER',
  GENERAL_SECRETARY = 'GENERAL_SECRETARY',
  ADMINISTRATIVE_EMPLOYEE = 'ADMINISTRATIVE_EMPLOYEE',
}

export interface IPspWalletAdyenPciLink {
  redirectUrl: string
}
