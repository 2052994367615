import { Link } from 'components'
import styled from 'styled-components'

export const Tabs = styled.div`
  position: relative;
  box-shadow: 0 8px 6px -6px ${(props) => props.theme.lightGrey};
  margin-bottom: 20px;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background-color: ${(props) => props.theme.blue};
    opacity: 0.5;
  }
`

export const Scroll = styled.div`
  position: relative;
  overflow-y: hidden;
  overflow-x: auto;
  white-space: nowrap;
  ::-webkit-scrollbar {
    display: none;
  }
`

export const Tab = styled(({ active, disabled, ...props }) => (
  <Link {...props} />
))`
  cursor: pointer;
  color: ${(props) => props.theme.blue} !important;
  opacity: 0.4;
  font-size: 17px;
  font-weight: 700;
  text-transform: uppercase;
  margin-left: 2.5rem;

  &:hover {
    text-decoration: none;
    ${({ disabled }) => disabled && `cursor: not-allowed; `}
    ${({ disabled }) => !disabled && `opacity: 1; `}
  }

  ${({ active }) => active && `opacity: 1; `}
`
export const Line = styled.div`
  background-color: ${(props) => props.theme.blue};
  position: absolute;
  bottom: 0;
  height: 4px;
  transition: 0.3s ease;
`
