import { Col, FormGroup, FormHelper, Row, Svg } from 'components'
import { validateCheckbox } from 'components/Form/Checkbox'
import { Field, FieldProps } from 'formik'
import { createIntl, useTranslation } from 'hooks'
import { FC, useRef } from 'react'
import { uniqId } from 'utils'

import * as Style from './style'
import * as Type from './type'

const CheckboxCard: FC<Type.IInputCard> = ({
  checked,
  className,
  children,
  disabled,
  handleChange,
  id,
  label,
  name,
  required = false,
  svg,
  value,
  ...rest
}) => {
  const refUniqueId = useRef(uniqId('checkboxCard'))
  const intl = createIntl('components_checkbox_card')
  const translation = useTranslation(intl)
  return (
    <Field
      name={name}
      validate={(checkboxValue: Array<string | number>) =>
        validateCheckbox(checkboxValue, required, translation)
      }
      children={({ field, form: { setFieldValue } }: FieldProps) => (
        <FormGroup>
          <Style.CheckboxCard
            className="m-0 p-3"
            hoverShadow={true}
            onClick={() => {
              if (disabled) {
                return
              }
              const set = new Set(field.value)
              if (set.has(value)) {
                set.delete(value)
              } else {
                set.add(value)
              }
              setFieldValue(field.name, Array.from(set))
              handleChange && handleChange(value as string)
            }}
            checked={!!(field.value && field.value.includes(value))}
          >
            <Row className="align-items-center">
              <Col xs="auto">
                <Svg src={`common/icon/multicolor/${svg}`} width="40px" />
              </Col>
              <Col>
                <Style.Label className="mb-0 text-muted" isBold={false}>
                  {label}
                </Style.Label>
              </Col>
              <Col xs="auto">
                <Style.Checkbox
                  {...field}
                  onChange={() => {
                    // Overwrite onChange to prevent double check
                  }}
                  disabled={disabled}
                  id={id || refUniqueId.current}
                  type="checkbox"
                  checked={!!(field.value && field.value.includes(value))}
                  label="&nbsp;"
                  {...rest}
                />
              </Col>
            </Row>
            <FormHelper fieldName={field.name} />
          </Style.CheckboxCard>
        </FormGroup>
      )}
    />
  )
}

export default CheckboxCard
