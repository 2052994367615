import { createElement } from 'react'
import styled from 'styled-components'

export const Container = styled(
  ({ children, tag, fullWidthStyle, isMenuHidden, ...rest }) =>
    createElement(tag, rest, children)
)`
  ${({ fullWidthStyle }) => fullWidthStyle && 'margin-bottom: 0 !important;'};
  @media (min-width: ${(props) => props.theme['gridBreakpoints-md']}) {
    margin-left: ${({ isMenuHidden }) => (isMenuHidden ? '0px' : '220px')};
  }

  transition: margin-right 0.3s ease;
`

export const ContainerInner = styled(({ fullWidthStyle, ...props }) => (
  <div {...props} />
))`
  max-width: ${({ fullWidthStyle }) => (fullWidthStyle ? 'none' : '1140px')};
  margin: auto;
  padding: ${({ fullWidthStyle }) => (fullWidthStyle ? '0' : '0 10px')};

  @media (min-width: ${(props) => props.theme['gridBreakpoints-md']}) {
    padding: ${({ fullWidthStyle, ...props }) =>
      fullWidthStyle ? `0` : `0 ${props.theme.gutterWidth}`};
  }
`
