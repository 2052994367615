import { Loader as LoaderComponent } from 'components'
import { Button as ReactStrapButton } from 'reactstrap'
import styled from 'styled-components'

export const Button = styled(({ lowercase, ...props }) => (
  <ReactStrapButton {...props} />
))`
  background-color: ${({ color, theme }) => theme[color]};
  &:hover {
    box-shadow: none;
  }

  // Special style because white outlined buttons are not in basic bootstrap configuration
  &.btn-outline-white {
    background: transparent;
    border-color: ${({ color, theme }) => theme[color]};
    color: ${({ color, theme }) => theme[color]};

    &:hover {
      background: ${({ color, theme }) => theme[color]};
      color: ${({ theme }) => theme.black};
    }
  }

  &.btn-white {
    color: ${({ theme }) => theme.blue};
    border-color: ${({ theme }) => theme.blue};

    &:hover {
      background: ${({ theme }) => theme.blue};
      color: ${({ theme }) => theme.white};

      &:disabled {
        background: ${({ color, theme }) => theme[color]};
        color: ${({ theme }) => theme.blue};
      }
    }
  }

  &.text-xl {
    font-size: 1.286rem;
    line-height: 1.2;
  }
`

export const Loader = styled(({ ...props }) => <LoaderComponent {...props} />)`
  background-color: ${({ color, theme }) => theme[color]};
  &:hover {
    box-shadow: none;
  }

  &.text-xl {
    font-size: 1.286rem;
    line-height: 1.2;
  }
`
