import styled, { css, keyframes } from 'styled-components'

export const SwitchWrapper = styled.div`
  & label {
    font-weight: 500;
  }
`

export const SwitchStyled = styled(({ checked, ...props }) => (
  <div {...props} />
))`
  position: relative;
  width: 3.4rem;
  height: 1.7rem;
  background: ${({ checked, theme }) => theme[checked ? 'green' : 'lightGrey']};
  box-shadow: inset 0 1px 3px 0 ${({ theme }) => theme.grey};
  border-radius: 20px;
  cursor: pointer;
  ${({ disabled }) => disabled && 'cursor: default;'}

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    ${({ disabled }) => disabled && 'background: rgba(255, 255, 255, 0.5);'}
    border-radius: 20px;
  }
`

const loader = keyframes`
  from {
    transform: rotateZ(0deg);
  }

  to {
    transform: rotateZ(360deg);
  }
`

const SwitchSliderLoading = css`
  animation: ${loader} 2s infinite;
`

export const SwitchSlider = styled(
  ({ checked, disabled, loading, ...props }) => <label {...props} />
)`
  height: 1.3rem;
  width: 1.3rem;
  transition: left 0.3s;
  left: 0;
  background: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.darkGrey};
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotateZ(0deg);
  ${({ loading }) => loading && SwitchSliderLoading}
  cursor: pointer;
  ${({ checked }) => checked && SwitchSliderChecked}
  ${({ disabled }) => disabled && 'cursor: default;'}
`
const SwitchSliderChecked = css`
  left: 1.7rem;
`
