import { Input } from 'components'
import { FormikValues } from 'formik'
import { createIntl, useTranslation } from 'hooks'
import { FC } from 'react'

import * as Type from './type'

const FrenchRna: FC<Type.IFrenchRna> = ({ label, ...rest }) => {
  const intl = createIntl('components_frenchrna')
  const translation = useTranslation(intl)

  const validate = (value: FormikValues): undefined | string => {
    // French RNA format
    // alphanumeric capital letters - length between 10 and 14
    if (!value) {
      return
    }

    if (
      value.match(
        /(^W\d[\dABGMCRSTJ]\d{7}$)|(^\d[\dABGMRT]\d[PS]((02[BA])|(\d{3}))\d{7}$)/
      ) === null
    ) {
      return translation.translate('validate.incorrect')
    }
  }

  return (
    <Input
      label={label === undefined ? translation.translate('label') : label}
      placeholder={translation.translate('placeholder')}
      validate={validate}
      {...rest}
    />
  )
}

export default FrenchRna
