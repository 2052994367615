import classNames from 'classnames'
import { CloseButton, Display } from 'components'
import { FC } from 'react'
import { EColor, ESize } from 'types'

import * as Style from './style'
import * as Type from './type'

const SidePanelHeader: FC<Type.ISidePanelHeader> = ({
  handleClose,
  sidePanel,
}) => (
  <Style.Header>
    <Display
      type="h4"
      color={EColor.WHITE}
      className={classNames(sidePanel.subtitle ? 'mb-2' : 'my-3')}
    >
      {sidePanel.title}
    </Display>
    {sidePanel.subtitle}
    <Style.CloseWrapper>
      <CloseButton size={ESize.XL} onClick={handleClose} color={EColor.WHITE} />
    </Style.CloseWrapper>
  </Style.Header>
)

export default SidePanelHeader
