import {
  PopoverBody as ReactPopoverBody,
  UncontrolledPopover,
} from 'reactstrap'
import styled from 'styled-components'

export const Popover = styled(UncontrolledPopover)`
  .popover-inner {
    overflow: hidden;
    border-radius: inherit;
  }
`
export const PopoverBody = styled(ReactPopoverBody)`
  margin: 20px;
`
