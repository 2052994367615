import { Button } from 'components'
import styled, { css } from 'styled-components'

export const StepperFooter = styled((props) => <div {...props} />)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  & .row.w-100 {
    width: calc(100% + 12.5px) !important;
    & > .col {
      padding-right: 0;
    }
  }
`

export const StepperActions = styled((props) => <div {...props} />)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .btn {
    margin: 0.5rem 0;
  }
`

export const StepsActions = styled((props) => <div {...props} />)`
  position: fixed;
  display: flex;
  justify-content: space-between;
  background-color: white;
  width: 100vw;
  left: 0;
  height: 3.5rem;
  bottom: 0;
  align-items: center;
  padding: 0 1rem;

  @media (min-width: ${(props) => props.theme['gridBreakpoints-md']}) {
    position: inherit;
    background-color: transparent;
    justify-content: space-between;
    width: 100%;
  }

  .btn {
    height: 2.2rem;
  }
`

const SecondaryStyle = css`
  border: none;
  color: ${({ theme }) => theme.blue};

  &:hover {
    color: ${({ theme }) => theme.darkBlue};
    background-color: transparent;
    border: none;
  }
`

export const DesktopActionButton = styled(({ type, ...props }) => (
  <Button {...props} />
))`
  ${({ authorized }) =>
    authorized === 'false' && 'cursor: default !important;'};
  ${({ type }) => type === 'secondary' && SecondaryStyle}
  ${({ disabled }) => disabled && `pointer-events: none;`}
`
