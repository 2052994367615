import { Input } from 'components'
import { Field, FieldProps, FormikValues, useFormikContext } from 'formik'
import { createIntl, useSelector, useTranslation } from 'hooks'
import { ChangeEvent, FC, useState } from 'react'
import en from 'react-phone-number-input/locale/en.json'
import fr from 'react-phone-number-input/locale/fr.json'
import 'react-phone-number-input/style.css'
import { selectOrganization } from 'state'
import { getLocaleCountryCode } from 'utils'
import { ECountry, ELocale } from 'utils/Intl/type'

import CountrySelect from './CountrySelect'
import * as Type from './type'
import * as Utils from './utils'
import { getCountryFromPhoneNumber } from './utils'

const Phone: FC<Type.IPhone> = ({
  className,
  countryCode,
  disabled = false,
  label,
  name,
  required = false,
  numberType,
  ...rest
}) => {
  const { nonprofit } = useSelector(selectOrganization)
  const intl = createIntl('components_phone')
  const translation = useTranslation(intl)

  const { setFieldTouched, setFieldValue, values } =
    useFormikContext<FormikValues>()
  const fieldValue = values[name]

  const [selectedCountry, setSelectedCountry] = useState<ECountry>(
    Utils.getInitialCountry(fieldValue)
  )
  const [inputValue, setInputValue] = useState(
    Utils.getFormattedPhoneNumber(
      Utils.getParsedPhoneNumber(fieldValue, selectedCountry)
    )
  )

  const validate = (value: string | undefined): undefined | string => {
    // Required
    if (!value?.length) {
      return required ? translation.translate('validate.required') : ''
    }
    // Check phone
    if (!Utils.isPhoneNumberValid(value, selectedCountry)) {
      return translation.translate('validate.incorrect.format')
    }

    // Check if format matches numberType
    if (
      numberType !== undefined &&
      !Utils.isPhoneNumberMatchingType(value, selectedCountry, numberType)
    ) {
      return translation.translate('validate.incorrect.type')
    }
    return undefined
  }

  const handleBlur = (forcedValue?: string) => {
    // Set component country
    const detectedCountry = getCountryFromPhoneNumber(forcedValue || inputValue)
    detectedCountry && setSelectedCountry(detectedCountry)

    // Get parsed value
    const parsedPhone = Utils.getParsedPhoneNumber(
      forcedValue || inputValue,
      detectedCountry || selectedCountry
    )

    // Set field value
    void setFieldValue(name, parsedPhone)
    void setFieldTouched(name, true)
    setInputValue(Utils.getFormattedPhoneNumber(parsedPhone))
  }

  return (
    <Field
      name={name}
      validate={validate}
      children={({ field }: FieldProps) => (
        <Input
          fieldName={name}
          label={label}
          {...field}
          disabled={disabled}
          value={inputValue}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            event.preventDefault()
            setInputValue(event.target.value)

            // Detect change through autofill & force blur with given changed value
            if (
              Utils.isPhoneNumberValid(event.target.value, selectedCountry) &&
              field.value.length === 0
            ) {
              handleBlur(event.target.value)
            }
          }}
          onKeyDown={(event: KeyboardEvent) => {
            if (event.key === 'Enter') {
              handleBlur()
            }
          }}
          onBlur={() => handleBlur()}
          required={required}
          prepend={{
            children: (
              <CountrySelect
                onChange={(countrySelected) => {
                  setSelectedCountry(countrySelected)
                  void setFieldValue(field.name, '')
                }}
                selectedCountry={selectedCountry}
                labels={nonprofit.locale === ELocale.FR_FR ? fr : en}
                preferredCountries={Utils.getPhonePreferredCountries(
                  countryCode || getLocaleCountryCode(nonprofit.locale)
                )}
                disabled={disabled}
              />
            ),
          }}
          placeholder={Utils.getPhonePlaceholder(
            selectedCountry,
            translation,
            numberType
          )}
          className="w-100"
          type="tel"
          {...rest}
        />
      )}
    />
  )
}

export default Phone
